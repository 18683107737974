/*
 *
    - Dependencies :
        - Include intersection observer polyfill before 
          in order to work in browser with no support 
            
            `yarn add -D intersection-observer`

    - Instanciate :
        const options:AnimationObserverOptions = {
            listeners: [
                AnimationObserver.DEFAULT_OPTIONS.defaultListener, // Default options
                {
                    selector: `.rich-text > *`,
                },
                {
                    selector: `[data-animation-observer="callout"]`,
                    once: false,
                    intersectionOptions: {
                        rootMargin: `-160px 0px -50% 0px`,
                        threshold: 0
                    },
                    onIntersect: (entry, observer) => {
                        if (entry.isIntersecting) document.documentElement.classList.add('has-callout');
                        else  document.documentElement.classList.remove('has-callout');
                    }
                }
            ]
        };
        
        const animationObserver = new AnimationObserver(options);
  
    - Add listeners
        animationObserver.observe([{
            selector: `[data-animation-observer="callout"]`,
            once: false,
            intersectionOptions: {
                rootMargin: `-160px 0px -50% 0px`,
                threshold: 0
            },
            onIntersect: (entry, observer) => {
                if (entry.isIntersecting) document.documentElement.classList.add('has-callout');
                else  document.documentElement.classList.remove('has-callout');
            }
        }])

    - Add listeners using singleton instance
        AnimationObserver.instance.observe([{
            selector: '.carousel__slide'
        }], this._element.parentElement)
 *
*/

import { autobind } from 'vendors/frontools/src/decorators/Autobind';

const defaultOptions: AnimationObserverOptions = {
    defaultListener: {
        selector: '[data-animation-observer]',
        naturalDelay: {
            left: 0.5,
            top: 0
        },
        once: true,
        intersectionOptions: {
            rootMargin: '-120px 0px 0px 0px', 
            threshold: 0,
        }
    }
};

export default class AnimationObserver {

    public static DEFAULT_OPTIONS: AnimationObserverOptions = defaultOptions;
    private static _instance: AnimationObserver;
    private _intersectingAttribute : string = 'data-animation-observer';

    constructor(private _options: AnimationObserverOptions) {
        if (AnimationObserver._instance) throw new Error('Error: Use AnimationObserver.getInstance() instead of new.');
        AnimationObserver._instance = this;

        this._options = {...AnimationObserver.DEFAULT_OPTIONS, ..._options};
        this.observe();
    }

    public observe(listeners: Array<IntersectionListener> = this._options.listeners, targetElement:any=document) {
        for (let i = 0, l = listeners.length; i < l; i++) {
            const listener = {...this._options.defaultListener, ...listeners[i]};
            const intersectionOptions = {...listeners[0].intersectionOptions, ...listener.intersectionOptions}
            const intersectionObserver = new IntersectionObserver(
                (entries, observer) =>  { this._onIntersect(entries, observer, listener) },
                intersectionOptions
            );
            
            const nodes = (typeof listener.selector === 'string') ? targetElement.querySelectorAll(listener.selector) as NodeListOf<HTMLElement> : listener.selector;
            for (let i = 0, l = nodes.length; i < l; i++) {
                intersectionObserver.observe(nodes[i]); 
            }
        }
    };

    @autobind
    private _onIntersect(entries: Array<any>, observer:IntersectionObserver, listener:IntersectionListener) {
        for (let i = 0, l = entries.length; i < l; i++) {
            const entry = entries[i];
            const element = entry.target;
            const isIntersecting = entry.isIntersecting;
            const animateOnce = listener.once;

            if (listener.naturalDelay) {
                const elementRect = element.getBoundingClientRect();
                const delay = Math.abs(elementRect.left*listener.naturalDelay.left+ elementRect.top*listener.naturalDelay.top);
                entry.target.style.transitionDelay = `${delay/1000}s`;
            }

            if (typeof listener.onIntersect === 'function') {
                listener.onIntersect(entry, observer);
            }

            // console.log(isIntersecting)

            if (isIntersecting === true) this._markAsDone(element);
            else if (!animateOnce && isIntersecting === false) this._unmarkAsDone(element);

            if (isIntersecting && animateOnce) {
                observer.unobserve(element);
            }
        }
    }

    private _markAsDone(element) { 
        let attribute = element.getAttribute(this._intersectingAttribute);

        if (attribute && attribute.indexOf('-intersecting') > -1) return;

        attribute = (attribute) ? `${attribute}-intersecting` : `-intersecting`;
        element.setAttribute(this._intersectingAttribute, attribute);
    }

    private _unmarkAsDone(element) {
        let attribute = element.getAttribute(this._intersectingAttribute);

        attribute = attribute ? attribute.replace('-intersecting', '') : '';
        element.setAttribute(this._intersectingAttribute, attribute);
    }

    public static get instance():AnimationObserver{
        return AnimationObserver._instance;
    }
}

export interface AnimationObserverOptions { 
    defaultListener?: IntersectionListener;
    listeners?: Array<IntersectionListener>;
}

export interface IntersectionListener {
    selector:string|NodeListOf<HTMLElement>;
    once?:boolean;
    naturalDelay?:{left:number, top: number}; //Undefined if no delay
    onIntersect?:Function;
    intersectionOptions?: IntersectionObserverInit;
}