import Module from '../Abstract/Module';
import Breakpoint from '../vendors/frontools/src/core/Breakpoint';
import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import AnimationObserver from '../Factories/AnimationObserver';
import Context from '../vendors/frontools/src/core/Context';

declare const Swiper;
declare const TweenMax;

export class Carousel extends Module {

    private _swiperInstance;
    private _activeSlide;
    private _config;
    private _nested;

    constructor(protected _element: HTMLElement) {
        super(_element);

  }

  private _onInit() {
    let slides;
    if (this._nested) {
        slides = this._element.querySelectorAll('.carousel__slide') as NodeListOf<HTMLElement>;
    } else {
        slides = this._element.parentElement.querySelectorAll('.carousel:not(.key--numbers) > .carousel__wrapper > .carousel__slide') as NodeListOf<HTMLElement>;
    }
    // const slides = wrapper.querySelectorAll('.carousel__slide') as NodeListOf<HTMLElement>; 
    AnimationObserver.instance.observe([{
        selector: slides,
        naturalDelay: { 
            left: 0.2,
            top: 0
        },
        intersectionOptions: {
            root: this._element.parentElement    
        }
    }], this._element.parentElement)
  }

  private _init() {
    this._config = {
        ...configs['base'],
        on: {   
            init: function() { 
                this._activeSlide = this.slides[this.snapIndex];    
                if (this._activeSlide) {
                    this._activeSlide.classList.add("is-active");
                }
            },
            slideChangeTransitionEnd: function() {
                if (this._activeSlide) {
                    this._activeSlide.classList.remove("is-active");
                }
                this._activeSlide = this.slides[this.snapIndex];
                this._activeSlide.classList.add("is-active");
            }
        }
    }

    

    let breakPoints = {
        [Breakpoint.TABLET_VALUE]: {
            spaceBetween: 40,
        },
        [Breakpoint.MOBILE_VALUE]: {
            spaceBetween: 30,
        }
    };

    this._config = {...this._config, breakpoints: breakPoints };

    const configSlug = this._element.getAttribute('data-carousel-config');
    if (configSlug) {
        const customConfig = configs[configSlug];
        if (customConfig) {
            this._config = {...this._config, ...customConfig};
        }
    }

    //Use only full mode in callout sections
    if(configSlug == "full") {
        this._element.parentElement.parentElement.classList.remove('wrap');
    }

    
    if (Context.getInstance().isEditorMode()) {
        this._config.allowTouchMove = false;
    }
    
    this._nested = this._element.classList.contains('key--numbers');
    if (this._nested) {
        this._config = {
            ...this._config, 
            nested: true,
            pagination: {
                ...configs['base'].pagination, 
                el:'[data-carousel-pagination-nested]' } 
            };
        let slides = this._element.querySelectorAll('.carousel__slide');
        if (slides.length > 3) {
            this._element.classList.add('has-3-slides')
        }
    } 

    this._swiperInstance = new Swiper(this._element, this._config);

    this._swiperInstance.on('init', () => {
        this._onInit();
    })

    const delay = (this._nested) ? .3 : 0;

    TweenMax.delayedCall(delay, ()=> {
        this._swiperInstance.init();
    });
  }

  private _onSlideChangeTransitionEnd() {
      
  }

  public hasSomeParentTheClass(element:HTMLElement, classname) {
    if (element.classList.contains(classname)) return true;
    if(element != document.documentElement) return this.hasSomeParentTheClass((element.parentNode as HTMLElement), classname);
    else return false;
 }

  @autobind
    protected _onIntersection(entries) {
        if (entries[0].isIntersecting) {
            this._intersectionObserver.unobserve(this._element);
            this._init();
        } 
    }

}

const configs = {
    'base' : {
        wrapperClass: "carousel__wrapper",
        slideClass: "carousel__slide",
        slidesPerView: "auto",
        init: false,
        breakpoints: {},
        allowTouchMove: true,
        nested: false,
        effect: 'slide',

        spaceBetween: 60,
        watchOverflow: true,
        speed: 400,
        watchVisibility: true,
        pagination: {   
            el: '[data-carousel-pagination]',
            clickable: true,
            modifierClass: "pagination--",
            bulletClass: "pagination__bullet",
            bulletActiveClass: "is-active"
        }
    },
    'text-3' : {
        slidesPerView: 3,
        slidesPerGroup: 3,  
        breakpoints : {
            [Breakpoint.MOBILE_VALUE]: {
                centeredSlides: true,
                slidesPerView: 1.2,
                slidesPerGroup: 1,
                spaceBetween: 30,
            }
        }
    },
    'text-2' : {
        slidesPerView: 2,
        slidesPerGroup: 2,
        breakpoints : {
            [Breakpoint.MOBILE_VALUE]: {
                centeredSlides: true,
                slidesPerView: 1.2,
                slidesPerGroup: 1,
                spaceBetween: 30,
            }
        }
    },
    'employee' : {
        slidesPerView: 3,
        slidesPerGroup: 3,
        breakpoints : {
            [Breakpoint.TABLET_VALUE]: {
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            [Breakpoint.MOBILE_VALUE]: {
                slidesPerGroup: 1,
                slidesPerView: 1,
            }
        }
    },
    'offices' : {
        slidesPerView:1,
        slidesPerGroup: 1,
        centeredSlides: true,
        breakpoints : {
            [Breakpoint.TABLET_VALUE]: {
                centeredSlides: false,
                spaceBetween: 40,
                slidesPerGroup:2,
                slidesPerView: 2,
            },
            [Breakpoint.MOBILE_VALUE]: {
                centeredSlides: true,
                slidesPerGroup: 1,
                slidesPerView: 1,
            }
        }
    },
    'services' : {
        slideToClickedSlide: true,  
        centeredSlides: true,   
        slidesPerView: 1.2,
    },
    'full' : {
        slideToClickedSlide: true,
        spaceBetween: 0,
        centeredSlides: true,
        slidesPerView: 1,
        [Breakpoint.TABLET_VALUE]: {
            slidesPerGroup:1,
            slidesPerView: 1,
        },
    },
    'images' : {
        centeredSlides: true,
        slidesPerView: 1,
    },
    '3-2-1' : {
        slidesPerView: 3,
        breakpoints : {
            [Breakpoint.TABLET_VALUE]: {
                slidesPerView: 2,
            },
            [Breakpoint.MOBILE_VALUE]: {
                slidesPerView: 1,
            }
        }
    },
    '3-2-1.2' : {
        slidesPerView: 3,
        slidesPerGroup: 3,  
        // centeredSlides: true,
        breakpoints : {
            // [Breakpoint.TABLET_VALUE]: {
            //     slidesPerView: 2,
            //     slidesPerGroup: 1,  
            // },
            [Breakpoint.MOBILE_VALUE]: {
                centeredSlides: true,
                slidesPerView: 1.2,
                slidesPerGroup: 1,
                spaceBetween: 30,
            }
        }
    }
    

}
