import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import Module from '../Abstract/Module';
import VanillaUtils from '../vendors/frontools/src/utils/VanillaUtils';
import NumberUtils from '../vendors/frontools/src/utils/NumberUtils';
import ArrayUtils from '../vendors/frontools/src/utils/ArrayUtils';
import Context from '../vendors/frontools/src/core/Context';
import { EventBus } from '../Events/EventBus';
import { Tracker } from '../Events/Tracker';
import { TrackingEvents } from './Tracking';

declare const TweenMax; 
declare const Linear; 
declare const Back; 
declare const Expo; 

export class Cta extends Module {

    private _svgWrapper : HTMLElement;    
    private _isAnimating : boolean = false; 
    private _isReady : boolean = false; 
    private _config : any; 
    private _ctaType : string;      
    private _context: Context = Context.getInstance();    

    constructor(_element) {
        super(_element);
        if (this._context.isEditorMode()) {
            this._element.classList.add('is-loaded');
            return;
        }

        this._ctaType = this._element.getAttribute('data-cta-type');

        this._config = configs[this._ctaType];
        if (!this._config)  {   
            this._element.classList.add('is-loaded');
            return;
        }

        setTimeout(function () {
            this._appendSvg();
        }.bind(this), 150);

        this._element.addEventListener('click', this._onClick);
    }

    @autobind
    private _onClick() {
        EventBus.dispatch({ type: TrackingEvents.CTA_CLICKED, el: this._element });
    }

    @autobind
    private _appendSvg() {
        if (!this._element.querySelector('svg')) {
            this._svgWrapper = document.createElement('div');
            this._svgWrapper.innerHTML = this._config.template;
            const svg = this._svgWrapper.querySelector('svg');
            this._element.appendChild(svg);
        }

        this._isReady = true; 
    }

    @autobind
    protected _onIntersection(entries) {
        if (!entries[0].isIntersecting || !this._config || this._isAnimating) return;

        setTimeout(() => {
            this._element.classList.add('is-loaded');
        }, 400);
        this._intersectionObserver.unobserve(this._element);
    }
}

const configs = {
    'circle': {
        template: `
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 115 115" >
                <style type="text/css">
                    .st0{fill:none;stroke:#EDEEEE;stroke-width:2;}
                </style>
                <g>
                    <circle class="st0" cx="57" cy="58" r="55" />
                </g>
                <g>
                    <circle class="st0" cx="57.5" cy="57.8" r="38.9" />
                </g>
                <g>
                    <circle class="st0" cx="57.4" cy="57.3" r="23.6" />
                </g>
            </svg>
        `
    },  
    'dots': {
        template: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.1 240.3">
                <g>
                    <circle cx="120.1" cy="67.6" r="4.6"/>
                    <circle cx="157.4" cy="82.9" r="4.6"/>
                    <circle cx="172.7" cy="120.1" r="4.6"/>
                    <circle cx="157.5" cy="157.3" r="4.6"/>
                    <circle cx="120.2" cy="172.7" r="4.6"/>
                    <circle cx="83" cy="157.3" r="4.6"/>
                    <circle cx="67.6" cy="120.1" r="4.6"/>
                    <circle cx="82.8" cy="82.8" r="4.6"/>
                </g>
                <g>
                    <circle cx="136.3" cy="39.3" r="5.8"/>
                    <circle cx="166" cy="51.7" r="5.8"/>
                    <circle cx="188.7" cy="74.4" r="5.8"/>
                    <circle cx="201" cy="104" r="5.8"/>
                    <circle cx="200.9" cy="136.2" r="5.8"/>
                    <circle cx="188.7" cy="165.9" r="5.8"/>
                    <circle cx="165.9" cy="188.6" r="5.8"/>
                    <circle cx="136.2" cy="200.9" r="5.8"/>
                    <circle cx="104.2" cy="200.9" r="5.8"/>
                    <circle cx="74.4" cy="188.7" r="5.8"/>
                    <circle cx="51.5" cy="166" r="5.8"/>
                    <circle cx="39.4" cy="136.4" r="5.8"/>
                    <circle cx="39.3" cy="104" r="5.8"/>
                    <circle cx="51.7" cy="74.5" r="5.8"/>
                    <circle cx="74.3" cy="51.7" r="5.8"/>
                    <circle cx="104" cy="39.3" r="5.8"/>
                </g>
                <g>
                    <circle cx="120.1" cy="8.1" r="8.1"/>
                    <circle cx="162.9" cy="16.6" r="8.1"/>
                    <circle cx="199.5" cy="40.8" r="8.1"/>
                    <circle cx="223.7" cy="77.6" r="8.1"/>
                    <circle cx="232" cy="120.3" r="8.1"/>
                    <circle cx="223.8" cy="162.9" r="8.1"/>
                    <circle cx="199.5" cy="199.4" r="8.1"/>
                    <circle cx="163" cy="223.8" r="8.1"/>
                    <circle cx="120.1" cy="232.3" r="8.1"/>
                    <circle cx="77.3" cy="223.6" r="8.1"/>
                    <circle cx="40.9" cy="199.4" r="8.1"/>
                    <circle cx="16.6" cy="163" r="8.1"/>
                    <circle cx="8.1" cy="120.1" r="8.1"/>
                    <circle cx="16.6" cy="77.4" r="8.1"/>
                    <circle cx="40.9" cy="41" r="8.1"/>
                    <circle cx="77" cy="16.5" r="8.1"/>
                </g>
            </svg>
        `
    }
        
}
