import AbstractMain from "./vendors/frontools/src/abstract/AbstractMain";
import { ModuleFactory } from "./Factories/ModuleFactory";
import Breakpoint from "./vendors/frontools/src/core/Breakpoint";
import AnimationObserver, { AnimationObserverOptions } from './Factories/AnimationObserver';
import { SplashScreen } from './Modules/SplashScreen';
import { autobind } from "./vendors/frontools/src/decorators/Autobind";
import ScrollUtils from './vendors/frontools/src/utils/ScrollUtils';
import Context from "./vendors/frontools/src/core/Context";
import { Tracking } from "./Modules/Tracking";
import { ClosestPolyfill } from "./vendors/polyfills/ClosestPolyfill";
import { HeightUtils } from "./utilities/HeightUtils";
import { Overlay } from "./Modules/Overlay";
import { AnchorScroll } from "./Modules/AnchorScroll";

declare const objectFitImages;

export class Main extends AbstractMain {

    constructor(context: Object = {}) {   
        super(context);         

        new SplashScreen(document.querySelector('[data-module="nav"]'));
        this.init();
    } 

    @autobind
    private init() {
        objectFitImages(document.querySelectorAll('img.object-fit-cover'));
        new ClosestPolyfill();

        const options:AnimationObserverOptions = {
            listeners: [
                AnimationObserver.DEFAULT_OPTIONS.defaultListener,
                {
                    selector: `.rich-text > *, .page-list`,
                },
                {
                    selector: `[data-animation-observer="ventures-nav-fixed"]`,
                    intersectionOptions: {
                        rootMargin: `-40% 0px -19% 0px`,
                    },
                    once: false,
                    onIntersect: (entry, observer) => {
                        if (!entry.isIntersecting) document.documentElement.classList.add('ventures-nav-fixed');
                        else  document.documentElement.classList.remove('ventures-nav-fixed');
                    }
                },
                {
                    selector: `[data-animation-observer="callout"]`,
                    once: false,
                    intersectionOptions: {
                        rootMargin: `-45% 0px -45% 0px`,
                    },
                    onIntersect: (entry, observer) => {
                        if (entry.isIntersecting) document.documentElement.classList.add('has-callout');
                        else  document.documentElement.classList.remove('has-callout');
                    }
                }
            ]
        };
        
        // if # in URL
        console.log(this.context.language);
        let contactLinks = [];
        if (this.context.language == 'fr') {
            contactLinks = [
                {hash: '#contact', isNextStep:false},
                {hash: '#faire-affaire-avec-bcf', isNextStep: 'work-with'},
                {hash: '#candidature-spontanee', isNextStep: 'work-for'}
            ];
        } else  {
            contactLinks = [
                {hash: '#contact', isNextStep:false},
                {hash: '#do-buisiness-with-bcf', isNextStep: 'work-with'},
                {hash: '#spontaneous-application', isNextStep: 'work-for'}
            ]
        }

        console.log(contactLinks)
        if(window.location.hash) {
            contactLinks.map(this._openUrlOverlay)
        }
        // end if # in URL

        new AnimationObserver(options);
        new HeightUtils();

        new ModuleFactory();
        new AnchorScroll();

        if (this._context.isIE11andLower()) {
            document.documentElement.classList.add('is-ie11');
        }

        
    }
    private _openUrlOverlay(item) {
        if(item.hash === window.location.hash) {
            if(item.isNextStep) {
                let link = document.createElement("a");
                link.href=item.hash;
                link.setAttribute('data-overlay-id', 'contact');
                new Overlay(link, item.isNextStep);
                link.click();

            } else {
                let link = document.createElement("a");
                link.href=item.hash;
                link.setAttribute('data-overlay-id', 'contact');
                new Overlay(link);
                link.click();
            }
            
        }
    }

    private initClickDown() {
        const button = document.querySelector('[data-scroll-button]');
        button.addEventListener('click',this._scrollTo);
    }

    private _scrollTo(e) {
        const button = e.currentTarget;
        const target= "#" + button.getAttribute('data-scroll-button');

        ScrollUtils.scrollTo({
            target: document.querySelector(target),
            speed: 1,
            offset: 0,
        })
    }
    /**
     * initializer of Breakpoint statement
     */
    protected initBreakpoint(): void {
        this._breakpoint = new Breakpoint({
            debug: true,
            breakpoints: [
                Breakpoint.MOBILES = 'mobiles',
                Breakpoint.MOBILE = 'mobile',
                Breakpoint.TABLETP = 'tabletp',
                Breakpoint.TABLET = 'tablet',
                Breakpoint.DESKTOP = 'desktop',
                Breakpoint.LARGE = 'large',
                Breakpoint.HD = 'hd'
            ],
            groups: {
                'group_mobile': [
                    Breakpoint.MOBILES,
                    Breakpoint.MOBILE,
                    Breakpoint.TABLETP,
                    Breakpoint.TABLET
                ],
                'group_desktop': [
                    Breakpoint.DESKTOP,
                    Breakpoint.LARGE,
                    Breakpoint.HD
                ],
            }
        });
    }

    protected initGTM() {
        // overrides AbstractMain initGTM, this is all managed in tracking now
        new Tracking();
    }
}
