
import Module from '../Abstract/Module';
import { autobind } from 'vendors/frontools/src/decorators/Autobind';
import ScrollUtils from 'vendors/frontools/src/utils/ScrollUtils';
import EventType from 'vendors/frontools/src/events/EventType';
import Request from 'vendors/frontools/src/data/Request';
import { RequestConfig, RequestEvent } from 'vendors/frontools/src/data/Request';

declare const ScrollMagic;
declare const Linear;
declare const TimelineMax;
declare const TweenMax;
declare const objectFitImages;

export class Employee extends Module {
    
    private _loadMoreButton : HTMLElement;
    private _employeesWrapper : HTMLElement;
    private _id : string;
    private _timeline;

    constructor(_element : HTMLElement) {   
        super(_element);
        

        this._loadMoreButton = this._element.querySelector('[data-employee-load-more]');
        this._employeesWrapper = this._element;

        if (this._loadMoreButton) this._loadMoreButton.addEventListener('click', this._onLoadMoreClick);
    } 

    @autobind
    private _onLoadMoreClick(e) {
        e.preventDefault();

        let requestConfig = new RequestConfig();
        requestConfig.url = e.currentTarget.href;

        const request = new Request(requestConfig);
        request.addListener(RequestEvent.LOAD, this._onRequestLoaded);
    }

    @autobind
    private _onRequestLoaded(e) {
        const div = document.createElement('div');
        div.innerHTML = e.response;
        const employee = div.querySelector('[data-employee-wrapper]');
        const employeeItems = employee.querySelectorAll('[data-employee-element]');
        
        let newElements = [];
        for (let i = 0, l = employeeItems.length; i < l; i++) {
            let clone = employeeItems[i].cloneNode(true) as HTMLElement;
            TweenMax.set(clone, {opacity: 0});
            newElements.push(clone);
            this._employeesWrapper.insertBefore(clone, this._loadMoreButton.parentNode);
            objectFitImages(clone.querySelector('img.object-fit-cover'));
        }

        this._loadMoreButton.removeEventListener('click', this._onLoadMoreClick);
        TweenMax.to(this._loadMoreButton, 0.4, {opacity: 0, onComplete: () => { TweenMax.set(this._loadMoreButton, {visibility: 'hidden'})} });

        TweenMax.staggerTo(newElements, 0.6, {opacity: 1,y:0}, 0.2);

    }

}
