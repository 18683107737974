/**
 * GoogleConnect
 * (c) lg2fabrique 2017

    this._googleObj = new GoogleConnect({
        appId: 'your-app-id',
        button:  HTMLElement or NodelistOf<HTMLElement>
    },{
        onConnected: function(googleUser) {
            // onConnected treatment, example of getters:
            var id = googleUser.getBasicProfile().getId()
            var name = googleUser.getBasicProfile().getName()
            var email = googleUser.getBasicProfile().getEmail()
        }.bind(this),
        onError: function(error) {
            // onError treatment
        }.bind(this)
    });

});
 */

declare var gapi;

export default class GoogleConnect {
    
    private static _instance        : GoogleConnect;

    private _appid                  : string;
    private _button                 : HTMLElement | NodeListOf<HTMLElement>;
    private _auth2                  : any;

    private _onConnected            : Function;
    private _onError                : Function;

    /**
     * Create new instance of Google Connection
     * @param {string} Application ID
     * @param {HTMLElement} or {NodeListOf<HTMLElement>} Button of NodeListOf buttons to attach event to
     * @param {object} Callbacks functions actions
     *   var googleConnect = new GoogleConnect({
            appId      : 'your-app-id',
            button      : button,
         });
     */
    constructor(option:any, callbacks?:any){
        //singleton validation
        if(GoogleConnect._instance) throw new Error('Error: Use GoogleConnect.getInstance() instead of new.');
        GoogleConnect._instance = this;

        this._appid = option.appId;
        this._button = option.button;

        if (callbacks) {
            if(typeof(callbacks.onConnected) === 'function') this._onConnected = callbacks.onConnected;
            if(typeof(callbacks.onError) === 'function') this._onError = callbacks.onError;
        }

        (window as any).googleAsyncInit = function() {
            console.info('GOOGLE CONNECT: ', this._appid);
            gapi.load('auth2', function(){
                this._auth2 = gapi.auth2.init({
                    client_id: this._appid,
                    cookiepolicy: 'single_host_origin',
                });
                if (this._button.length) {      // If Node List of HTMLElement, attach to all buttons
                    for (var buttonId in this._button) {
                        this._attachSignInGoogle(this._button[buttonId]);
                    }
                } else this._attachSignInGoogle(this._button);
            }.bind(this));
        }.bind(this);

        // sdk
        (function() { var po = document.createElement('script');po.type = 'text/javascript';po.async = true;po.src = '//apis.google.com/js/api:client.js?onload=googleAsyncInit';var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(po, s); })();
    }

    /**
     * Attaching click on google connect button
     */
    private _attachSignInGoogle(element) {
        this._auth2.attachClickHandler(element, {},
            function(googleUser) {  
                if (this._onConnected) this._onConnected(googleUser);
            }.bind(this),  
            function(error) { 
                if (this._onError) this._onError(error);    
            }.bind(this)
        );
    }

    /**
     * get singleton instance
     * @returns {GoogleConnect}  instance's GoogleConnect
     */
    public static getInstance(): GoogleConnect {
        return GoogleConnect._instance;
    }
}