import ScrollUtils from "../vendors/frontools/src/utils/ScrollUtils";
import { autobind } from "../vendors/frontools/src/decorators/Autobind";
import Module from '../Abstract/Module';

declare const TweenMax;
declare const Expo;
declare const Quad;
declare const ScrollMagic;

export class Background extends Module {

    private _attribute: string = "data-background-notifier";

    private _backgroundElement: HTMLElement;

    private _controller : any;
    private _scene: any;
    private _currentNotified: string;

    constructor(element) {
        super(element);

        // console.log(element.getAttribute(this._attribute))
        // this._notifierElements = element.querySelectorAll("[" + this._attribute + "]");
        this._currentNotified = element.getAttribute(this._attribute);
        this._backgroundElement = document.querySelector("[data-background-id="+this._currentNotified+"]");

        if(config[this._currentNotified].tweenSet) {
            TweenMax.set(this._backgroundElement,config[this._currentNotified].tweenSet)
        }

        this._initScrollMagic()
    }

    private _initScrollMagic() {
        this._controller =new ScrollMagic.Controller();
        var t = TweenMax.to(this._backgroundElement,1,config[this._currentNotified].tweenTo);
        
        this._scene = new ScrollMagic.Scene({
            triggerElement: this._element,
            offset:config[this._currentNotified].scene.offset(this._element),
            triggerHook: config[this._currentNotified].scene.triggerHook,
            duration: config[this._currentNotified].scene.duration(this._element)
        })
        .setTween(t) // trigger a TweenMax.to tween
        // .addIndicators({name: "Pin - " + this._currentNotified}) // add indicators (requires plugin)
        .addTo(this._controller);

    }

    public destroy() {
        
    }

}

const config = {
    "intro": {
        "tweenTo": {
            alpha: 0,
            ease: Quad.easeOut
        },
        "scene": {
            triggerHook: 0,
            offset: (element) => {
                return 0
            },
            duration: (element) => {
                return element.clientHeight*3;
            }
        }
    },
    "outro": {
        "tweenTo": {
            alpha: 1,
            ease: Quad.easeIn
        },
        "tweenSet": {
            alpha:0
        },
        "scene": {
            triggerHook: 1,
            offset: (element) => {
                return -element.clientHeight;
            },
            duration: (element) => {
                return element.clientHeight*2;
            }
        }
        
    }
}
