/**
 * Decorator - Add logging to function, parameters, class and properties
 */
export function log(...args : any[]) {

    function method(target, key, descriptor) {
        if(descriptor === undefined) descriptor = Object.getOwnPropertyDescriptor(target, key);

        var original = descriptor.value;

        var metadataKey = `__log_${key}_parameters`;
        var indices = target[metadataKey];

        function logParameters( ...args: any[]){
            console.group('arguments');
            
            for (var i = 0; i < args[0].length; i++) { 
                if (indices.indexOf(i) !== -1) { 
                    var arg = args[0][i];
                    console.log(`${i}:`, arg);
                }
            }
            console.groupEnd();
        }

        function logResult(result){
            console.group('return');
            console.log(result);
            console.groupEnd();
        }

        descriptor.value = function (...args: any[]) {
            var result = original.apply(this, args);

            if(!indices && !result){
                console.log(`${key}`);
            } else {
                console.group(`${key}`);
                if (indices) logParameters(args);
                if (result) logResult(result);
                console.groupEnd();
            }
            
            return result;
        }

        return descriptor;
    }

    function parameter(target: any, key : string, index : number) {
        var metadataKey = `__log_${key}_parameters`;
        if (Array.isArray(target[metadataKey])) {
            target[metadataKey].push(index);
        }else {
            target[metadataKey] = [index];
        }
    }

    function property(target: any, propertyKey: string) {
        return {
            set(value) {
                console.log(`${propertyKey} = ${value}`);
            }
        };
    }

    function instance(target: any) {
        
        function construct(constructor, args) {
            var c : any = function () {
                console.log(`${target.name}`, args);
                return constructor.apply(this, args);
            }
            c.prototype = constructor.prototype;
            return new c();
        }
        
        var f : any = function (...args) {
            return construct(target, args);
        }

        for(var i in target) f[i] = target[i];
        f.prototype = target.prototype;
        
        return f;
    }

    switch(args.length) {
        case 1: return instance.apply(this, args);
        case 2: return property.apply(this, args);
        case 3:
            if(args[2] === undefined) {
                return property.apply(this, args);
            }else if(typeof args[2] === 'number') {
                return parameter.apply(this, args);
            }
            return method.apply(this, args);
        default: throw new Error();
    }

}