import AbstractDispatcher from '../../abstract/AbstractDispatcher';
import { autobind } from '../../decorators/Autobind';
/**
 * Vimeo
 * (c) lg2fabrique 2016
 *
 */

/// <reference path="../../definitions/medias/Vimeo.d.ts" />


export default class Vimeo extends AbstractDispatcher {

    //players counter
    private static _playerCount: number                  = 0;
    private static _hasBinded: boolean                   = false;
    private static _hasBindedReady: boolean              = false;

    private _index: number                               = null;
    private _player: HTMLIFrameElement                   = null;
    private _url: string                                 = null;
    private _options = {
        api: true,
        id: null,
        target: null,
        width: 200,
        height: 200,
        namespace: 'player',
        aspect_ratio: '16:9', // give your aspect ratio for the video
        responsive: false,
        responsiveWrapperClass: 'player_wrapper',
        player: {},
        iframe: {},
        callback: {
            onReady: function() {},
            onProgress: function(data?) {},
            onPause: function(time?) {},
            onComplete: function() {}
        }
    };


    /**
     * Create a Vimeo player
     * @param {Object} Configuration
     */
    constructor(obj:any = {}) {
        super();

        // Player count + set index;
        Vimeo._playerCount++;
        this._index = Vimeo._playerCount;
        
        // Extends options
        for (var i in obj) {
            this._options[i] = obj[i];
        }
        
        // Id validation
        if (this._options.id === null) throw new Error('Property "id" must be specified');
        if (this._options.target === null) throw new Error('Property "target" must be specified');
        
        //create and add iframe to DOM
        this._createFrame();
        
        
        //load/init API Frame
        if(this._options.api) this._createAPI();
    };


    /**
     * Create iframe element from options
     */
    private _createFrame(): void {
        //set source url
        var src = '//player.vimeo.com/video/' + this._options.id + '?api=' + this._options.api + '&player_id=' + (this._options.namespace + this._index);
        for (var j in this._options.player) {
            src += '&' + j + '=' + this._options.player[j];
        }
        
        //set iframe element
        this._player = document.createElement('iframe');
        
        this._player.setAttribute('src', src);
        this._player.setAttribute('id', this._options.namespace + this._index);
        this._player.setAttribute('frameborder', String(0));
        this._player.setAttribute('webkitallowfullscreen', '');
        this._player.setAttribute('mozallowfullscreen', '');
        this._player.setAttribute('allowfullscreen', '');
        
        for (var k in this._options.iframe) {
            this._player.setAttribute(k, this._options.iframe[k]);
        }
        
        //append to DOM
        if(this._options.responsive) {
            this._createResponsiveWrapper();
        } else {
            this._player.setAttribute('width', String(this._options.width));
            this._player.setAttribute('height', String(this._options.height));
            this._options.target.appendChild(this._player);
        }

        this._url = window.location.protocol + src.split('?')[0];
    };


    /*
        For responsive video only
        Create a wrapper for resizing purpose.
        @param child : iframe
    */
    private _createResponsiveWrapper() {
        if (!this._options.aspect_ratio) {
            throw new Error('You need an aspect ratio for the responsive player to work')
        }

        var ratioParts: string[] = this._options.aspect_ratio.split(':'),
            ratio = Number(ratioParts[1]) / Number(ratioParts[0]);
        
        var wrapper = document.createElement('div');
        wrapper.setAttribute('class', this._options.responsiveWrapperClass);
        wrapper.setAttribute('style', 'padding-top: '+ ratio * 100 +'%;');
        
        wrapper.appendChild(this._player);
        this._options.target.appendChild(wrapper);
    }


    /**
     * Handle messages received from the player
    */
    @autobind
    private _onMessage(e) {
        var data = JSON.parse(e.data);

        switch (data.event) {
            case 'ready':
                if(!Vimeo._hasBindedReady){
                    Vimeo._hasBindedReady = true;
                    this._api('addEventListener', 'pause');
                    this._api('addEventListener', 'finish');
                    this._api('addEventListener', 'playProgress');
                }
                console.info('VIMEO: Ready', this._options.id);
                if (this._options.callback.onReady) this._options.callback.onReady();
                break;
            case 'playProgress':
                if (this._options.callback.onProgress) this._options.callback.onProgress(data.data);
                break;
            case 'pause':
                if (this._options.callback.onPause) this._options.callback.onPause();
                break;
            case 'finish':
                if (this._options.callback.onComplete) this._options.callback.onComplete();
                break;
        }
    };
    
    
    /**
     * Create API if api == true
    */
    private _createAPI() {
        // Listen for messages from the player
        if (!Vimeo._hasBinded) {
            window.addEventListener('message', this._onMessage, false);                
            Vimeo._hasBinded = true;
        }
    };


    // Helper function for sending a message to the player
    private _api(action, value) {
        var data = {
            method: action
        };

        if (value != undefined) {
            data['value'] = value;
        }

        var message = JSON.stringify(data);
        this._player.contentWindow.postMessage(data, this._url);
    };


    /**
     * Options's Getter
     * @return {Object} Options
    */
    public getOptions() {
        return this._options;
    };
    
    /**
     * Player object Getter (iframe) 
     * @return {*} Player
    */
    public getPlayer() {
        return this._player;
    };
    
    /**
     * Player index Getter
     * @return {Number} Index
    */
    public getIndex() {
        return this._index;
    };

}