/**
 * Google Map
 * (c) lg2fabrique 2016
 *
 */

/// <reference path="../definitions/map/GoogleMap.d.ts" />

import AbstractDispatcher from '../abstract/AbstractDispatcher';
import ArrayUtils from '../utils/ArrayUtils';
import ObjectUtils from "../utils/ObjectUtils";
import UrlUtils from "../utils/UrlUtils";
import HtmlMarker from './HtmlMarker';

export default class GoogleMap extends AbstractDispatcher {

    //google map api key
    public static KEY: string = '';
    //google map sdk version
    public static VERSION: string = '';
    //google map ui language
    public static LANGUAGE: string = 'fr';
    //google map ui region
    public static REGION: string = 'CA';

    //script is add/ready
    private static _hasScriptDOM: boolean = false;
    private static _hasScriptReady: boolean = false;

    //map counter
    private static _mapCount: number = 0;

    //init stack when sdk is not ready(multiple map manager)
    private static _stackInit: string[] = [];


    private _map = null; //google map obejct
    private _index = null; //map's index
    private _markers = {}; //marker list
    private _directions = {}; //marker list
    private _options = { //default options
        //map dom target
        target: document.getElementById('map'),
        //libraries
        libraries: null,
        //disable click action on places markers
        disableInfoPlaces:false,
        //map api options
        mapOptions:{
            zoom: 13,
            center: {lat:45.505, lng:-73.555},
        },
        //map styles
        styles:[{}],
        //map callbacks
        callbacks:{}
    };


    /**
     * Create a Map
     * @param {Object} Configuration
     */
    constructor(obj:any = {}) {
        super();

        // map counter + map indexer
        GoogleMap._mapCount++;
        this._index = GoogleMap._mapCount;

        // extends custom options
        this._options = ObjectUtils.extends(this._options, obj);

        // map target validation
        if (!this._options.target) throw new Error('Your map target is not found in DOM');

        // google key validation
        if(GoogleMap.KEY === ''){
            if (window.console) console.warn('GOOGLE_MAP: Don\'t forget to set your GoogleMap.KEY');
        }

        // sdk ready
        window['gmapInit' + this._index] = function(stack) {
            GoogleMap._hasScriptReady = true;

            // create map
            this._map = new google.maps.Map(this._options.target, this._options.mapOptions);
            this._map.setOptions({styles: this._options.styles});

            (google.maps as any).visualRefresh = true;

            //add base listener events
            this.addBaseListeners();


            //disable infowindow from place markers
            if (this._options.disableInfoPlaces === true) {
                var set = google.maps.InfoWindow.prototype.set;
                google.maps.InfoWindow.prototype.set = function (key, val) {
                    if (key === 'map') {
                        if (!this.get('noSupress')) return;
                    }
                    set.apply(this, arguments);
                };
            }


            //add markers from list
            if (this._options.markers) {
                for (var i in this._options.markers) {
                    this.addMarker(this._options.markers[i]);
                }
            }

            //init all other maps in the stack (stacking)
            if (stack === undefined) {
                while(GoogleMap._stackInit.length !== 0){
                    var init:any = GoogleMap._stackInit[0];
                    GoogleMap._stackInit.splice(0, 1);
                    init.call(null, [true]);
                }
            }

            // callback ready after everything else.
            if (this._options.callbacks.ready !== undefined) {
                this._options.callbacks.ready();
                this.dispatch({type:'ready'
                });
            }

            //self-deleting method instance
            window['gmapInit' + this._index] = undefined;
            try{delete window['gmapInit' + this._index];}catch(e){}
        }.bind(this);


        //add sdk script
        if (GoogleMap._hasScriptDOM === false) {
            GoogleMap._hasScriptDOM = true;

            //add/create sdk
            var params = {
                key: GoogleMap.KEY,
                v: GoogleMap.VERSION,
                callback: 'gmapInit' + this._index,
                language: GoogleMap.LANGUAGE,
                region: GoogleMap.REGION
            };

            // We check for required libraries
            if (this._options.libraries !== null) {
                params['libraries'] = this._options.libraries;
            }

            this.addScript(params);
        } else {
            //add to the stackflow and waiting sdk loading
            if (GoogleMap._hasScriptReady === false) GoogleMap._stackInit.push(window['gmapInit' + this._index]);
            else window['gmapInit' + this._index].call();
        }
    };


    /**
     * function addScript - Create sdk url
     * @param {Object} Query string to sdk
     */
    private addScript(data) {
        var sdk = UrlUtils.query('https://maps.googleapis.com/maps/api/js',data);
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = sdk;
        document.getElementsByTagName('body')[0].appendChild(script);
    };


    /**
     * function addBaseListeners - Add Google Map listener base
     */
    private addBaseListeners() {

        //listeners base
        var listeners = {
            'idle': function() {},
            'click': function() {},
            'dblclick': function() {},
            'rightclick': function(e) {
                if (window.console && window.console.log) {
                    console.info('GOOGLE_MAP: lat:' + e.latLng.lat() + ' lng:' + e.latLng.lng());
                }
            },
            'drag': function() {},
            'dragend': function() {},
            'dragstart': function() {},
            'mousemove': function() {},
            'mouseout': function() {},
            'mouseover': function() {},
            'resize': function() {},
            'bounds_changed': function() {},
            'center_changed': function() {},
            'zoom_changed': function() {},
        };

        //add listener to map object
        for (var func in listeners) {
            google.maps.event.addListener(this._map, func, (function(func) {
                return function(e) {
                    if (this._options.callbacks[func] !== undefined) this._options.callbacks[func](e);
                    listeners[func](e);
                    this.dispatch({type:func, data:e});
                };
            })(func).bind(this));
        }
    };


    /**
     * function getDirection - get marker from object markers
     * @param {Object || Number || String} Object DirectionsService, number index or string id
     */
    private getDirection(direction) {
        if (typeof(direction) === 'object') {
            return direction;
        } else if (typeof direction === 'number') {
            var cpt = 0,
                found = false;

            for (var key in direction as any) {
                if (direction === cpt) {
                    return this._directions[key];
                }
                cpt += 1;
            }

            // If no marker was found, throw an error
            if (!found) {
                throw new Error('Direction index(' + direction + ') not found');
            }
        } else if (direction in this._directions) {
            return this._directions[direction];
        } else {
            throw new Error('Direction id(' + direction + ') not found');
        }
    };


    /**
     * Checks whether if map is valid.
     * @return { Boolean }
     */
    private validateMap():boolean {
        if (!this._map) {
            throw new Error('Map is not defined. Maybe that SDK is not ready. Watch it with "ready" callback');
        }
        return true;
    };


    /**
     * function toggleDirection - Toggle visibility of a direction on map
     *
     * @param {Object || Number || String} direction - Object direction, number index or string id
     * @param {boolean] show
     */
    private toggleDirection(direction: any, show: boolean = true): void {
        var parentToAttached = show ? this._map : null;

        if (this.validateMap()) {
            var obj = this.getDirection(direction);
            obj.setMap(parentToAttached);
        }
    };


    /**
     * function toggleMarker - Toggle visibility of a marker on map
     *
     * @param {Object || Number || String} Object marker, number index or string id
     * @param {boolean] show
     */
    private toggleMarker(marker, show: boolean = true): void {
        var parentToAttached = show ? this._map : null;

        if (this.validateMap()) {
            var obj = this.getMarker(marker);
            obj.setMap(parentToAttached);
        }
    };


    /**
     * function addDirection - add directions from A to B with alternatives.
     *
     * @param { google.maps.LatLng || google.maps.Marker || string } from.
     * @param { google.maps.LatLng || google.maps.Marker || string } to.
     * @param { google.maps.TravelMode } travelMode. Optional. If not specified, DRIVING by default
     * @param { google.maps.UnitSystem } unit. Optional. METRIC by default
     * @param { Array } waypoints (optional) specifies an array of DirectionsWaypoints
     *
     */
    public addDirection(options) {
        if (this.validateMap()) {
            var travelModes = [
                    google.maps.TravelMode.DRIVING,
                    google.maps.TravelMode.BICYCLING,
                    google.maps.TravelMode.TRANSIT,
                    google.maps.TravelMode.WALKING
                ],
                units = [
                    google.maps.UnitSystem.METRIC,
                    google.maps.UnitSystem.IMPERIAL
                ];

            // validate parameters
            if (!(options.origin instanceof google.maps.Marker) || !(options.destination instanceof google.maps.Marker))
                throw new Error('origin and destination must be instance of Marker google.maps.Marker');
            if (!(ArrayUtils.contains(travelModes, options.travelMode))) options.travelMode = google.maps.TravelMode.DRIVING;
            if (!(ArrayUtils.contains(units, options.unit))) options.unit = google.maps.UnitSystem.METRIC;

            // @todo, find a better id. same origin and destination
            var uniqId = 'direction-' +
                options.origin.get('id') + '-' +
                options.destination.get('id') + '-' +
                options.unit + '-' +
                options.travelMode;

            if (uniqId in this._directions) {
                this.showDirection(this._directions[uniqId]);
                this.showMarker(options.origin);
                this.showMarker(options.destination);
                this.fit();
            } else {
                var directionsService = new google.maps.DirectionsService(),
                    directionsDisplay = new google.maps.DirectionsRenderer({
                        suppressMarkers: true,
                        polylineOptions: {
                            strokeColor: '#2eab89',
                            strokeOpacity: 0.8,
                            strokeWeight: 7
                        }
                    }),
                    request = {
                        origin: options.origin.getPosition(),
                        destination: options.destination.getPosition(),
                        travelMode: options.travelMode,
                        unitSystem: options.unit
                    };

                directionsDisplay.setMap(this._map);

                this._directions[uniqId] = directionsDisplay;

                directionsService.route(request, function(result, status) {
                    if (status == google.maps.DirectionsStatus.OK) {
                        this.showMarker(options.origin);
                        this.showMarker(options.destination);
                        directionsDisplay.setDirections(result);
                        this.fit();
                        /**
                         *   @to-do . Move cursors to be more accurate with Directions

                         var leg = response.routes[ 0 ].legs[ 0 ];
                         makeMarker( leg.start_location, icons.start, 'title' );
                         makeMarker( leg.end_location, icons.end, 'title' );
                         function makeMarker( position, icon, title ) {
                                new google.maps.Marker({
                                    position: position,
                                    map: map,
                                    icon: icon,
                                    title: title
                                });
                            }
                         */
                    }
                });
            }
        }
    };


    /**
     * Add marker to map
     * @param {Object} Object marker (position: {lat:45.505, lng:-73.555})
     * @return {Object} Google Marker object
     */
    public addMarker(options, addToList) {
        if (this.validateMap()) {
            //marker map
            if (options.map === undefined) options.map = this._map;

            //marker position
            //Docs: https://developers.google.com/maps/documentation/javascript/reference?hl=fr#LatLng
            if (options.position === undefined) throw new Error('Marker don\'t have position.');
            if (!(options.position instanceof google.maps.LatLng)) {
                if (options.position.lat === undefined) throw new Error('Position object need to have "lat" property.');
                if (options.position.lng === undefined) throw new Error('Position object need to have "lng" property.');
                options.position = new google.maps.LatLng(options.position.lat, options.position.lng);
            }

            if (options.icon) options.icon = GoogleMap.createIcon(options.icon);

            //create marker
            var marker = null;
            if (options.template) {
                marker = new HtmlMarker(options)
            } else if (options.icon) {
                marker = new google.maps.Marker(options); //Docs: https://developers.google.com/maps/documentation/javascript/reference?hl=fr#Marker
            }

            if (!marker) throw new Error('The marker need to have "icon" or "template" property');

            // set id to marker if there's no one.
            if (!marker.get('id')) {
                var lat = options.position.lat().toString(),
                    lng = options.position.lng().toString();

                marker.set('id', 'marker-' + lat.replace(/[^\d]/i, '') + lng.replace(/[^\d]/i, ''));
            }

            addToList = addToList != undefined ? addToList : true;
            if (addToList) this._markers[marker.get('id')] = marker;

            //click marker base for basic marker only
            if (marker instanceof google.maps.Marker) {
                google.maps.event.addListener(marker, 'click', (function (marker) {
                    return function () {
                        if ((marker as any).callback && typeof((marker as any).callback) === 'function') (marker as any).callback(marker);
                    };
                })(marker));
            }

            return marker;
        }
    };


    public static createIcon(icon) {
        //marker image
        //Docs: https://developers.google.com/maps/documentation/javascript/reference?hl=fr#Icon
        if (icon !== undefined && !(icon instanceof (google.maps as any).MarkerImage)) {
            var _icon = icon;

            //marker image
            var url = null;
            if (_icon.img === undefined) throw new Error('Icon image is undefined. Property "img" must be defined.');
            else if (_icon.retina === undefined) url = _icon.img;
            else url = window.devicePixelRatio > 1 ? _icon.retina : _icon.img;

            //marker size
            var size = null;
            if (_icon.width !== undefined && _icon.height !== undefined) {
                size = new google.maps.Size(_icon.width, _icon.height);
            }

            //marker origin
            var origin = null;
            if (_icon.originx !== undefined && _icon.originy !== undefined){
                origin = new google.maps.Point(_icon.originx, _icon.originy);
            }

            //marker anchor
            var anchor = null;
            if (_icon.anchorx !== undefined && _icon.anchory !== undefined) {
                anchor = new google.maps.Point(_icon.anchorx, _icon.anchory);
            }

            //marker scaledSize
            var scaledSize = null;
            if (_icon.scaledSizeWidth !== undefined && _icon.scaledSizeHeight !== undefined){
                scaledSize = new google.maps.Size(_icon.scaledSizeWidth, _icon.scaledSizeHeight);
            }

            return new (google.maps as any).MarkerImage(url, size, origin, anchor, scaledSize);
        }

        return icon;
    };


    /**
     * function deleteDirections - Remove from map and delete directions from object
     */
    public deleteDirections() {
        for (var key in this._directions) {
            this.hideDirections();
        }
        this._directions = {};
    };


    /**
     * function deleteMarkers - remove from map and delete markers from object
     */
    public deleteMarkers() {
        for (var key in this._markers) {
            this.hideMarker(this._markers[key]);
        }
        this._markers = {};
    };


    /**
     * function fit - Fits all visible markers in the map
     */
    public fit() {
        if (this.validateMap()) {
            var bounds = new google.maps.LatLngBounds();
            for (var id in this._markers) {
                if (this._markers[id].getMap() !== null) {
                    bounds.extend(this._markers[id].getPosition());
                }
            }
            this._map.fitBounds(bounds);
        }
    };


    /**
     * function getDirections - Directions Getter
     * @return {Object} Directions
     */
    public getDirections() {
        return this._directions;
    };


    /**
     * function getIndex - Map index Getter
     * @return {Number} Map index
     */
    public getIndex() {
        return this._index;
    };


    /**
     * function getMap - Get map object
     * @return {Object} Map
     */
    public getMap() {
        return this._map;
    };

    /**
     * function getMarkers - Markers Getter
     * @return {Object} Markers
     */
    public getMarkers() {
        return this._markers;
    };


    /**
     * function getMarker - Get marker from object markers
     * @param {Object || Number || String} Object marker, number index or string id
     */
    public getMarker(marker) {
        if (marker instanceof google.maps.Marker) {
            return marker;
        } else if (typeof marker === 'number') {
            var cpt = 0,
                found = false;

            for (var key in this._markers) {
                if (marker === cpt) {
                    return this._markers[key];
                }
                cpt += 1;
            }

            // If no marker was found, throw an error
            if (!found) {
                throw new Error('Marker index(' + marker + ') not found');
            }
        } else if (marker in this._markers) {
            return this._markers[marker];
        } else {
            throw new Error('Marker id(' + marker + ') not found');
        }
    };


    /**
     * function hideDirection - Hide direction for this instance
     * @param {Object || Number || String} Object marker, number index or string id
     */
    public hideDirection(direction) {
        this.toggleDirection(direction);
    };


    /**
     * function hideDirections - Hide directions for this instance
     */
    public hideDirections() {
        for (var key in this._directions) {
            this.hideDirection(this._directions[key]);
        }
    };


    /**
     * function hideMarker - Hide marker on map
     * @param {Object || Number || String} Object marker, number index or string id
     */
    public hideMarker(marker) {
        this.toggleMarker(marker);
    };


    /**
     * function hideMarkers - Hide markers from this instance
     */
    public hideMarkers() {
        for (var key in this._markers) {
            this.hideMarker(this._markers[key]);
        }
    };


    /**
     * function removeDirection - Remove direction from map and destroy it from cached objects
     * @param {String} string id given by drawDirection.
     */
    public removeDirection(direction) {
        var obj = this.getDirection(direction);
        this.toggleDirection(obj);
        delete this._directions[obj.get('id')];
    };


    /**
     * function removeMarker - Remove marker from map and destroy it from cached objects
     * @param {Object || Number || String} Object marker, number index or string id
     */
    public removeMarker(marker) {
        var obj = this.getMarker(marker);
        this.toggleMarker(obj);
        delete this._markers[obj.get('id')];
    };


    /**
     * function showDirection - Show directions on the map
     * @param {Object || Number || String} Object marker, number index or string id
     */
    public showDirection(direction) {
        this.toggleDirection(direction, true);
    };


    /**
     * function showMarker - Show a specific marker for this instance
     * @param {Object || Number || String} Object marker, number index or string id
     */
    public showMarker(marker) {
        this.toggleMarker(marker, true);
    };


    /**
     * function showMarkers - Show markers for this instance
     */
    public showMarkers() {
        for (var key in this._markers) {
            this.showMarker(this._markers[key]);
        }
    };


    /**
     * apply offset for setCenter map
     * @param {latlng} google.maps.LatLng CENTER POINT
     * @param {number} offset x in pixel
     * @param {number} offset y in pixel
     */
    public offsetCenter(latlng, offsetx, offsety, panTo) {
        var scale = Math.pow(2, this._map.getZoom());
        var nw = new google.maps.LatLng(
            this._map.getBounds().getNorthEast().lat(),
            this._map.getBounds().getSouthWest().lng()
        );

        var worldCoordinateCenter = this._map.getProjection().fromLatLngToPoint(latlng);
        var pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) ||0)

        var worldCoordinateNewCenter = new google.maps.Point(
            worldCoordinateCenter.x - pixelOffset.x,
            worldCoordinateCenter.y + pixelOffset.y
        );

        var newCenter = this._map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

        if(panTo) this._map.panTo(newCenter);
        else this._map.setCenter(newCenter);
    };


    /**
     * function setMapOptions - Google Map Options setter at runtime
     * @param {Object} Options object (See JS SDK for syntax)
     */
    public setMapOptions(options) {
        this._map.setOptions(options);
    };


    /**
     * function getOptions - Options's Getter
     * @return {Object} Options
     */
    public getOptions() {
        return this._options;
    };


    /**
     * function resize - Resize map trigger
     */
    public resize() {
        if (this._map) {
            google.maps.event.trigger(this._map, 'resize');
        } else {
            throw new Error('Map is not defined. Maybe that SDK is not ready. Watch it with "ready" callback');
        }
    };


    /**
     * Takes a valid JSON style object and parses it to a querystring to be used with Google Map Static API
     * @param {object} json - json style object that can be obtained from tools like SnazzyMaps
     *
     * @returns {string} Formatted queryString parameter
     */
     public static JSONtoURL(json) {
        var _items = [],
            separator = '|',
            _parameters = '';

        var isColor = function(value) { return /^#[0-9a-f]{6}$/i.test(value.toString()); };
        var toColor = function(value) { return '0x' + value.slice(1); }

        for (var i = 0; i < json.length; i++) {
            var item = json[i],
                hasFeature = item.hasOwnProperty('featureType'),
                hasElement = item.hasOwnProperty('elementType'),
                stylers = item.stylers,
                target = '',
                style = '';

            if (!hasFeature && !hasElement) {
                target = 'feature:all';
            } else {
                if (hasFeature) {
                    target = 'feature:' + item.featureType;
                }
                if (hasElement) {
                    target = target ? target + separator : '';
                    target += 'element:' + item.elementType;
                }
            }

            for (var s = 0; s< stylers.length; s++) {
                var styleItem = stylers[s],
                    key = Object.keys(styleItem)[0]; // there is only one per element

                style = style ? style + separator : '';
                style += key + ':' + (isColor(styleItem[key]) ? toColor(styleItem[key]) : styleItem[key]);
            }

            _items.push(target + separator + style);
        }

        return '&style=' + _items.join('&style=');
    };

}
