import AbstractDispatcher from '../vendors/frontools/src/abstract/AbstractDispatcher';

declare const TweenMax;

export default class Module extends AbstractDispatcher {

    public static DOM_CHANGE : string = 'dom_change';

    protected _element : HTMLElement;
    protected _intersectionObserver : IntersectionObserver;
    protected _options : ModuleOptions = {
        intersection: { rootMargin: '-150px 0px', threshold: 0} 
    };

    constructor(element, options?: ModuleOptions) {
        super();

        this._element = element;

        if (options) {
            this._options = options
        }

        this._intersectionObserver = new IntersectionObserver(this._onIntersection, this._options.intersection)
        this._intersectionObserver.observe(this._element);
    }

    public destroy() {}

    public onGroupMobile() { }

    public onGroupDesktop() { }

    public onTablet() { }

    public onMobile() { }


    public get element() {
        return this._element;
    }

    public showElement() {
        TweenMax.set(this._element, {opacity: 1});
    }

    protected _onIntersection(entries) {
        // if (entries[0].isIntersecting) {
        //     this.showElement();
        // }
    }
    
}

interface ModuleOptions {
    intersection : IntersectionObserverInit;
};



