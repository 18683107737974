import Module from '../Abstract/Module';
import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import Youtube, { YoutubeObject } from '../vendors/frontools/src/medias/video/Youtube';
import Context from '../vendors/frontools/src/core/Context';
import Breakpoint from '../vendors/frontools/src/core/Breakpoint';
import FormValidation from '../vendors/frontools/src/data/FormValidation';
import { FormEventType } from '../vendors/frontools/src/data/FormValidation';
import { RequestConfig, RequestEvent } from 'vendors/frontools/src/data/Request';
import { RequestMethod } from '../vendors/frontools/src/data/Request';
import StringUtils from '../vendors/frontools/src/utils/StringUtils';
import ScrollUtils from '../vendors/frontools/src/utils/ScrollUtils';
import Request from '../vendors/frontools/src/data/Request';
import { EventBus } from '../Events/EventBus';
import { TrackingEvents } from './Tracking';
import AnimationObserver from '../Factories/AnimationObserver';


declare const TweenMax;
declare const Expo;

export class Form extends Module {
    public static FORM_SENT : string = "form_sent";

    private _formValidator : FormValidation;

    private _steps         : NodeListOf<HTMLElement>
    private _stepsNavList  : HTMLElement;
    private _name          : string = 'default form';
    private _lastIntersectingSection : HTMLElement;
    private _currentIntersectingSection : HTMLElement;

    private _url            : string;

    constructor(protected _element: HTMLFormElement, private _parentElement) {
        super(_element);

        this._initValidation();
        this._initFields();
        this._createSteps();
        
    }

    private _initValidation() {
        this._formValidator = new FormValidation(this._element as HTMLFormElement);    

        this._formValidator.addListener(FormEventType.ERROR, function(e) {
            const firstErrorEl = document.querySelector('.error') as HTMLElement;
            if (firstErrorEl) {
                let overlay = this._parentElement.querySelector('.overlay');
                let header= overlay.querySelector('header');
                let element = Breakpoint.getInstance().isInGroup('group_mobile') ? overlay.querySelector('main') : overlay;
                TweenMax.to(element, .5, {scrollTo: {y:firstErrorEl.offsetTop - header.clientHeight }});
            }
        }.bind(this));
    
        this._formValidator.addListener(FormEventType.SEND, function(e) {
            
            var formData = new FormData();

            for (let i = 0; i < this._element.elements.length; i++) {
                const field = this._element.elements[i];
                if(field.getAttribute('data-sc-field-name')) {
                    const name = field.getAttribute('data-sc-field-name').split(' ').join('').toLowerCase();
                    
                    if(field.type =="file") formData.append(name, field.files[0]);
                    else if(field.type == "checkbox") formData.append(name, field.checked);
                    else formData.append(name, field.value);
                }
            }

            // NEED TO ADD AJAX CALL TO NEXIO HERE
            e.submit.preventDefault();
            let config:RequestConfig = new RequestConfig();
            config.url = this._url
            config.method = RequestMethod.POST;
            config.data = formData


            let request = new Request(config);
            request.addListener(RequestEvent.LOAD, this._onFormSent)
  
        }.bind(this));
    }
   
    private _initFields() {
        let fields = this._element.querySelectorAll('input:not([type="checkbox"]), textarea');
        
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if(field.classList.contains('file_upload')) {
                this._createUploadField(field);
            } else {
                this._initInputText(field);
            }
            
        }
    }
    
    private _initInputText(target) {
        target.addEventListener('focus', (e) => {
            let f:HTMLElement = e.currentTarget as HTMLElement;
            let l:HTMLElement= f.previousElementSibling as HTMLElement;
            l.classList.add('focused');
        });

        target.addEventListener('blur', (e) => {
            let f:HTMLInputElement = e.currentTarget as HTMLInputElement;
            let l:HTMLElement= f.previousElementSibling as HTMLElement;
            if(f.value == "") l.classList.remove('focused');
            this._checkFinishStep();
        });
    }

    private _createUploadField(target) {
        let wrapper = document.createElement('div');
        wrapper.classList.add('file_upload_wrapper');

        let inputValue = document.createElement('span');
        inputValue.classList.add('input_value')
        

        let parent = target.parentNode;
        parent.replaceChild(wrapper, target);
        wrapper.appendChild(target);
        wrapper.appendChild(inputValue);

        target.addEventListener('change',(e) => {
            let f:HTMLInputElement = e.currentTarget as HTMLInputElement;
            let l:HTMLElement = (f.parentNode as HTMLElement).previousElementSibling as HTMLElement;
            let v:HTMLElement = (f.parentNode as HTMLElement).querySelector('.input_value');

            if(f.value == "") l.classList.remove('focused');
            else l.classList.add('focused');

            v.innerText = f.value.split('\\').pop();
        })
    }

    private _createSteps() {
        this._stepsNavList = document.querySelector('.right-pannel-content.show [data-js="form-steps-list"]');
        this._steps = this._element.querySelectorAll('.form_step');
        
        for (let i = 0; i < this._steps.length; i++) {
            const step = this._steps[i];
            const originalTitle = step.querySelector('h2');
            const title = originalTitle.cloneNode(true) as HTMLElement;
            let item = this._createListItem(i, title.innerText);
            step.setAttribute('data-section-id', StringUtils.toSlug(title.innerText));
            this._stepsNavList.appendChild(item);
            item.addEventListener('click', this._goToSection);

            // Observing section changes
            // const bottomMargin = (i === this._steps.length-1) ? "0" : "-45";
            AnimationObserver.instance.observe([{
                selector: `[data-section-id]`,
                once: false,
                intersectionOptions: {
                    rootMargin: "-45% 0% -45% 0%",
                    // threshold: [0.2, 0.8]
                },
                onIntersect: (entry, observer) => {
                    const id = entry.target.getAttribute('data-section-id');
                    const element = this._stepsNavList.querySelector(`[data-section="${id}"]`) as HTMLElement;

                    
                    if (!element) return;

                    if (entry.isIntersecting) {
                        if (this._currentIntersectingSection) {
                            this._lastIntersectingSection = this._currentIntersectingSection;
                            this._lastIntersectingSection.classList.remove('is-active');
                        } 
                        this._currentIntersectingSection = element;
                        this._currentIntersectingSection.classList.add('is-active');
                    } else {
                        if (this._currentIntersectingSection === element) {
                            this._currentIntersectingSection.classList.remove('is-active');
                            this._currentIntersectingSection = this._lastIntersectingSection;
                            this._lastIntersectingSection = undefined;
                            if (this._currentIntersectingSection) this._currentIntersectingSection.classList.add('is-active');
                        } 
                    }
                    
                }
            }], this._element)
            
        }
    }

    private _createListItem(step:number, text:string):HTMLAnchorElement {
        var link:HTMLAnchorElement = document.createElement('a');
        link.setAttribute('href','#');
        link.setAttribute('data-section', StringUtils.toSlug(text));
        
        link.className= "link link--normal link--white";
        link.innerText = (step+1) + " - " + text;
        return link;
    }

    @autobind
    private _goToSection(e) {
        e.preventDefault();
        let link:HTMLAnchorElement = e.currentTarget;
        
        let target:HTMLElement = this._element.querySelector('[data-section-id="' + link.getAttribute('data-section') + '"]');
        let overlay = this._parentElement.querySelector('.overlay');
        let header= overlay.querySelector('header');

        TweenMax.to(overlay, .5, {scrollTo: {y:target.offsetTop - header.clientHeight }});
    }

    private _checkFinishStep() {
        for (let i = 0; i < this._steps.length; i++) {
            const step = this._steps[i];
            const inputs:NodeListOf<HTMLInputElement> = step.querySelectorAll('input:not([type="hidden"]), textarea');
            let valid = true;
            
            for (let j = 0; j < inputs.length; j++) {
                const input:HTMLInputElement = inputs[j];
            
                if(input.getAttribute('data-val-required') && input.value == "") valid = false;
            }
            
            if(valid) {
                this._stepsNavList.querySelector('[data-section="' + step.getAttribute('data-section-id') + '"]').classList.add('finished');
            } else {
                this._stepsNavList.querySelector('[data-section="' + step.getAttribute('data-section-id') + '"]').classList.remove('finished');
            }
        }
    }

    public open() {
        EventBus.dispatch({ type: TrackingEvents.FORM_OPENED, data: {
            name: this._name,
            action: this._element.action
        } });
    }

    @autobind
    private _onFormSent(e) {
        //RETURN OF NEXIO HERE

        var success = JSON.parse(e.response).Success.toLowerCase() == 'true' ? true : false;
        
        this.dispatch({ type: Form.FORM_SENT, success: success  });
        
        if (success) {
            EventBus.dispatch({ type: TrackingEvents.FORM_SUBMITTED, data: {
                name: this._name,
                action: this._element.action
            } });
        }
        // this._handleNextStep()
    }

    public setUrl(pValue:string) {
        this._url = pValue;
    }

    public set name(value: string) {
        this._name = value;
    }

    public clear() {
        if(this._stepsNavList) this._stepsNavList.innerHTML = "";
        this._formValidator.clear();
    }
}