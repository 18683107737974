/**
 * 
 * Overlay Iframe
 * 
 * Example with same link as href :
 * <a href="https://nexio.com" data-module="overlay-iframe|cta" data-cta-type="circle">
 *     <span class="cta__text" data-cta-text>Infolettre</span>     
 * </a>
 * 
 * Example with different link :
 * <a href="https://nexio.com" data-module="overlay-iframe|cta" data-overlay-iframe-url="https://google.com/other-link" data-cta-type="circle">
 *     <span class="cta__text" data-cta-text>Infolettre</span>     
 * </a>
 * 
 * 
 */


import OverlayAbstract from "../Abstract/OverlayAbstract";
import Template from "../vendors/frontools/src/data/Template";
import { autobind } from "../vendors/frontools/src/decorators/Autobind";

declare const TweenMax;
declare const Expo;

export class OverlayIframe extends OverlayAbstract { 

    // private _elsClose: HTMLElement[];
    private _mainTemplate: Template;
    private _template: Template;
    private _overlayWrapper: HTMLElement;
    private _overlay: HTMLElement;
    private _overlayContent: HTMLElement;
    private _elsClose: NodeListOf<HTMLElement>;
    private _iframeSrc:string;

    constructor(protected _element: HTMLElement) {
        super(_element);


        const dataUrl = this._element.getAttribute('data-overlay-iframe-url');
        if (dataUrl) {
            this._iframeSrc = dataUrl;
        } else {
            this._iframeSrc = (this._element as HTMLAnchorElement).href;
        }



        this._element.addEventListener('click', this._init);

    }

    @autobind
    private _init(e: Event) {
        e.preventDefault();
        // this._templateId = this._element.getAttribute('data-overlay-id');

        this._mainTemplate = new Template(document.getElementById('overlay'), {});
        this._template = new Template(document.getElementById('overlay-iframe'), {});


        this._overlayWrapper = document.createElement('div');
        this._overlayWrapper.innerHTML = this._mainTemplate.htmlAsString;

        
        this._overlay = this._overlayWrapper.querySelector('.overlay');
        this._overlay.classList.add('overlay--simple');
        this._overlayContent = this._overlayWrapper.querySelector('.main')
        
        const htmlElement = this._template.htmlAsString;
        this._overlayContent.innerHTML = htmlElement;
        
        document.body.appendChild(this._overlayWrapper);

        const iframeEl = this._overlayWrapper.querySelector('iframe');

        if (this._iframeSrc) {
            iframeEl.src = this._iframeSrc;
        }

        this._overlayWrapper.addEventListener('click', this._onOverlayClick);

        this.open();
        
        this.close = this.close.bind(this);
        this._elsClose = this._overlayWrapper.querySelectorAll('[data-js="button-close"]');        
        for (let j = 0; j < this._elsClose.length; j++) {
            const btn = this._elsClose[j];            
            btn.addEventListener('click', this.close);
        }
    }

    @autobind
    private _onOverlayClick(e) {
        if (e.target  === this._overlay) {
            this.close();
        }
    }

    public open() {
        super.open();

        TweenMax.from(this._overlayContent, .5, {alpha: 0, y: 100, ease: Expo.easeOut });
    }

    public close() {
        super.close();

        TweenMax.to(this._overlayContent, .5, {alpha: 0, y: 100, ease: Expo.easeOut});
        TweenMax.to(this._overlayWrapper, .5, {alpha: 0, ease: Expo.easeOut, onComplete: () => {
            this._overlayWrapper.parentElement.removeChild(this._overlayWrapper);
        }});
    }

    public destroy() {
        for (let j = 0; j < this._elsClose.length; j++) {
            const btn = this._elsClose[j];            
            btn.removeEventListener('click', this.close);
        }
    }
}