/**
 * TransitionUtils
 * (c) lg2 2019
 *
 */

export class TransitionUtils {
    // TransitionEnd vendor
    private static _transitionEnd = {
        transition: 'transitionend',
        OTransition: 'otransitionend',
        MozTransition: 'transitionend',
        WebkitTransition: 'webkitTransitionEnd'
    };

    private static _animationEnd = {
        animation: 'animationend',
        OAnimation: 'oAnimationEnd',
        MozAnimation: 'animationend',
        WebkitAnimation: 'webkitAnimationEnd'
    };

    /**
     * Return vendor transitionEnd event to be used with events listeners
     * @return {String} Correct vendor event
     *   TransitionUtils.transitionEnd; // 'transitionend'
     */
    public static transitionEnd(): string {
        const el = document.createElement('div');

        for (let i in TransitionUtils._transitionEnd) {
            if (
                TransitionUtils._transitionEnd.hasOwnProperty(i) &&
                el.style[i] !== undefined
            ) {
                return TransitionUtils._transitionEnd[i];
            }
        }
    }

    /**
     * Return vendor animationend event to be used with events listeners
     * @return {String} Correct vendor event
     *   TransitionUtils.animationEnd; // 'animationend'
     */
    public static animationEnd(): string {
        const el = document.createElement('div');

        for (let i in TransitionUtils._animationEnd) {
            if (
                TransitionUtils._animationEnd.hasOwnProperty(i) &&
                el.style[i] !== undefined
            ) {
                return TransitionUtils._animationEnd[i];
            }
        }
    }
}
