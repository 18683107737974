import DocumentUtils from '../utils/DocumentUtils';

/**
 * Decorator - Add debounce delay to a method
 * @param {number} delay
 */
export function debounce(delay:number = 500) {

    return function(target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) {

        var original = descriptor && descriptor.value;
        if (typeof original !== 'function' || original == undefined) return;
        descriptor.value = DocumentUtils.debounce(original, delay);

        return descriptor;
    }
}