import ScrollUtils from "../vendors/frontools/src/utils/ScrollUtils";
import { autobind } from "../vendors/frontools/src/decorators/Autobind";
import Module from '../Abstract/Module';
// import { Linear } from "gsap";

declare const TweenMax;
declare const Power2;

export class ImageSlide extends Module {

    private _attribute: string = "data-image-slide-notifier";
    private _attributeTitle: string = "data-image-title-notifier";
    private _actualBackground: string = "";

    private _notifierElements: NodeListOf<HTMLElement>;
    private _notifierTitleElements: NodeListOf<HTMLElement>;
    
    protected _element: HTMLElement;
    private _backgroundElement: HTMLElement;
    private _actualDiv: HTMLElement;    

    protected _intersectionObserver : IntersectionObserver;

    constructor(element) {
        super(element);

        const rootBottom = window.innerHeight < 750
            ? '-30%'
            : '60%';
        const options = {
            rootMargin: `-120px 0px ${rootBottom} 0px`,
            threshold: 0
        };

        this._intersectionObserver = new IntersectionObserver(this._onReached, options)
        this._notifierElements = document.querySelectorAll("[" + this._attribute + "]");
        this._notifierTitleElements = document.querySelectorAll("[" + this._attributeTitle + "]");
        this._backgroundElement = element.querySelector("[data-image-slide-background]");
    }

    public onGroupDesktop() {
        this.showElement();
        if (this._backgroundElement && this._notifierElements.length) {
            for (let i = 0, l = this._notifierElements.length; i < l; i++) {
                this._intersectionObserver.observe(this._notifierElements[i]);
            }
        }
    }

    public destroy() {
        this._intersectionObserver.disconnect();
    }

    public onGroupMobile() {
        this.destroy();
    }


    @autobind
    private _onReached(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this._changeBackground(entry.target.getAttribute(this._attribute), entry.target.getAttribute(this._attributeTitle));
            }
        });
    }

    private _changeBackground(background,title) {
        if (this._actualBackground == background) return;

        this._actualBackground = background;

        const div = document.createElement('div');

        if (title != null) {
            div.setAttribute("title", title);
            div.setAttribute("role", "img");
            div.setAttribute("aria-label", title);
        }

        const image = document.createElement('img');

        image.src = this._actualBackground;
        image.onload = () => {
            if (this._actualBackground != background) return;

            TweenMax.set(div, {backgroundImage:"url('" + background + "')", opacity: 0});

            this._backgroundElement.appendChild(div);

            TweenMax.to(div, 1.2, {opacity: 1, ease: Power2.easeInOut,
                onComplete: () => {
                    setTimeout(() => {
                        if (this._actualDiv) this._backgroundElement.removeChild(this._actualDiv);
                        this._actualDiv = div;
                    }, 200)
                }
            });
        }
    }

}
