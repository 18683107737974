/**
 * Abstract class to create a singleton
 * (c) lg2fabrique 2016
 */

export default class AbstractSingleton {

    private static instance;

    constructor() {
        if(AbstractSingleton.instance) console.warn('Error: Use [CLASS].getInstance() instead of new.');
        AbstractSingleton.instance = this;
    };

    public static getInstance(): AbstractSingleton{
        return AbstractSingleton.instance;
    }

}