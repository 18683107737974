
import Module from '../Abstract/Module';
import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import ScrollUtils from '../vendors/frontools/src/utils/ScrollUtils';
import EventType from '../vendors/frontools/src/events/EventType';
import Request from '../vendors/frontools/src/data/Request';
import { RequestConfig, RequestEvent } from '../vendors/frontools/src/data/Request';

declare const ScrollMagic;
declare const Linear;
declare const TimelineMax;
declare const TweenMax;

export class Grid  extends Module {

    private _scrollMagicController;
    private _scrollMagicScene;
    private _oddChildrens : NodeListOf<HTMLElement>;
    private _evenChildrens : NodeListOf<HTMLElement>;
    private _loadMoreButton : HTMLElement;
    private _gridWrapper : HTMLElement;
    private _id : string;
    private _timeline;

    constructor(_element : HTMLElement) {   
        super(_element);

        this._scrollMagicController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: 0.8}});
        
        this._loadMoreButton = this._element.querySelector('[data-grid-load-more]');
        this._gridWrapper = this._element.querySelector('[data-grid-wrapper]');
        this._id = this._element.getAttribute('data-grid-id');

        if (this._loadMoreButton) this._loadMoreButton.addEventListener('click', this._onLoadMoreClick);
    } 

    @autobind
    private _onLoadMoreClick(e) {
        e.preventDefault();

        let requestConfig = new RequestConfig();
        requestConfig.url = e.currentTarget.href;

        const request = new Request(requestConfig);
        request.addListener(RequestEvent.LOAD, this._onRequestLoaded);
    }

    @autobind
    private _onRequestLoaded(e) {
        const div = document.createElement('div');
        div.innerHTML = e.response;

        const grid = div.querySelector(`[data-grid-id="${this._id}"]`);
        const gridItems = grid.querySelectorAll('[data-grid-element]');

        let newElements = [];
        for (let i = 0, l = gridItems.length; i < Math.min(l, 2); i++) {
            let clone = gridItems[i].cloneNode(true);
            TweenMax.set(clone, {opacity: 0});
            newElements.push(clone);
            this._gridWrapper.appendChild(clone);
        }

        ScrollUtils.scrollTo({
            target: newElements[0],
            speed: 0.4,
            offset: 200,
        })

        this._loadMoreButton.removeEventListener('click', this._onLoadMoreClick);
        const newLoadMoreButton = grid.querySelector('[data-grid-load-more]') as HTMLAnchorElement;
        if (newLoadMoreButton) {
            const html = newLoadMoreButton.querySelector('[data-cta-text]').innerHTML;
            const href = newLoadMoreButton.href;
            this._loadMoreButton.querySelector('[data-cta-text]').innerHTML = html;
            this._loadMoreButton.setAttribute('href', href);
        } else {
            TweenMax.to(this._loadMoreButton, 0.4, {opacity: 0, onComplete: () => { TweenMax.set(this._loadMoreButton, {visibility: 'hidden'})} });
        }

        this.dispatch(new EventType(Module.DOM_CHANGE, {elements: newElements}));
        
        TweenMax.staggerTo(newElements, 0.6, {opacity: 1}, 0.2);

        if (this._scrollMagicScene) {
            this.destroy();
            this._initScene();
        }

    }

    private _initScene() {
        if (this._scrollMagicScene) return;

        this._oddChildrens = this._element.querySelectorAll('.g_item:nth-child(odd)');
        this._evenChildrens = this._element.querySelectorAll('.g_item:nth-child(even)');

        this._timeline = new TimelineMax();
        this._timeline.to(this._oddChildrens, 0.4, {y: -25, ease: Linear.easeNone})
                      .to(this._evenChildrens, 0.4, {y: 150, ease: Linear.easeNone});
        this._scrollMagicScene = new ScrollMagic.Scene({
            triggerElement: this._element,
            duration: "120%"
        }).setTween(this._timeline)
          .addTo(this._scrollMagicController)
        //   .addIndicators();
    }

    public destroy() {
        if (this._oddChildrens) TweenMax.set(this._oddChildrens, {clearProps:"all"});
        if (this._evenChildrens) TweenMax.set(this._evenChildrens, {clearProps:"all"});
        if (this._scrollMagicScene) this._scrollMagicScene.destroy();

        this._scrollMagicScene = undefined;
    }

    public onMobile() {
        this.destroy();
    } 

    public onTablet() {
        this._initScene();
    }

    public onGroupDesktop() {
        this._initScene();
    }


}
