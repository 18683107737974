import AbstractDispatcher from '../../abstract/AbstractDispatcher';
import { autobind } from '../../decorators/Autobind';

/**
 * VideoPlayer super light
 * (c) lg2fabrique 2014
 
 
    //HTML
    <div class="video" 
        data-mp4="/static/video/bg.mp4" 
        data-webm="/static/video/bg.webm" 
        data-ogv="/static/video/bg.ogg">
    </div>


    //JAVASCRIPT
    var video = new VideoPlayer({
        target: document.getElementById('vid'), //video tag or jQuery ($('.wrapper')[0])
        
        width:500,
        height:500,
        
        volume:1, //volume gate
        backgroundColor:'#000000', //video background color
        position:'fixed', //set postion type
        
        fullsize:true,
        forceratio:true, //force rato according to width and height
        
        video:{//video tag properties
            attributes:{ loop:true, autoplay:true},
            sources:[{ //if you don't use data attribute
                src:'', //file path
                type:'' //file type video/mp4, video/webm, video/ogv
            }]
        },
        
        callbacks:{ //callbacks natives events (http://www.w3schools.com/tags/ref_av_dom.asp "HTML Audio/Video Events")
            oncanplay:function(e){},
            onended:function(e){},
            onplay:function(e){},
            onpause:function(e){},
            onloadedmetadata :function(e){}
        }
    });
*/


export default class VideoPlayer extends AbstractDispatcher {


        private _video                              : HTMLVideoElement               = null;
        private _options = {
            target:null,//DOM element to put video tag
            
            width:500, //video width
            height:500, //video height
            
            fullsize:false, //fullsize showing of
            forceratio:false,  //force to set in ratio according to width and height
            
            volume:1, //volume gate

            position: 'static',
            
            backgroundColor:'#000000', //video background color
            
            video: {
                attributes:{ //video tag attributes
                    autoplay:true,
                    loop:true
                },
                sources:[{
                    src:'', //file path
                    type:'' //file type video/mp4, video/webm, video/ogv
                }]
            },
            
            callbacks:{ //callbacks natives events
                onended:function(e){},
                onplay:function(e){},
                onpause:function(e){},
                onloadedmetadata :function(e){}
            }
        };
        private static propsDict: string[] = [
            'src', 'muted', 'poster', 'preload', 'autoplay', 'loop', 'audio', 'controls', 'width', 'height'
        ]


        /**
         * Create a Vimeo player
         * @param {Object} Configuration
         */
        constructor(obj:any = {}) {
            super();

            //validation html5 video type
            if (!this._isHtml5Video()) return;            
            
            //extends options
            for (var i in obj) this._options[i] = obj[i];
            
            //target validation
            if(this._options.target === undefined) throw new Error('Property "target" must be specified');
            
            
            //get sources data
            let mp4 = this._options.target.getAttribute('data-mp4'),
                webm = this._options.target.getAttribute('data-webm'),
                ogv = this._options.target.getAttribute('data-ogv'),
                ogg = this._options.target.getAttribute('data-ogg');

            if (this._options.video.sources === undefined) this._options.video.sources = [];
            if (mp4 !== null) this._options.video.sources.push({src:mp4, type:'video/mp4'});
            if (webm !== null) this._options.video.sources.push({src:webm, type:'video/webm'});
            if (ogv !== null) this._options.video.sources.push({src:ogv, type:'video/ogv'});
            if (ogg !== null) this._options.video.sources.push({src:ogg, type:'video/ogv'});
            
            if (this._options.video.sources.length === 0) throw new Error('No sources are found.');
            
            
            //Create html video tag
            this._video = document.createElement('video');
            for (let j in this._options.video.sources) {
                let source = document.createElement('source');
                source.src = this._options.video.sources[j].src;
                source.type =  this._options.video.sources[j].type;
                this._video.appendChild(source);
            }
            
            //set background
            this._video.style.backgroundColor = this._options.backgroundColor;
            
            //set default volume
            this._video.volume = this._options.volume;
            
            //set attribute to video tag
            for (var attribute in this._options.video.attributes) {
                let value = this._options.video.attributes[attribute];
                
                // If present in dict, set them as attribute instead
                if (VideoPlayer.propsDict.indexOf(attribute) >= 0) {
                    this._video[attribute] = value;
                } else { 
                    // Else, set them as attributes (with no value when explicitely true)
                    value = value === true ? '' : value;
                    this._video.setAttribute(attribute, value);
                }
            }
            
            //set default position type
            if (this._options.fullsize === true || this._options.forceratio === true) {
                if (!this._options.position) this._video.style.position = 'absolute';
                else  this._video.style.position = this._options.position;
            }
            
            if (this._options.fullsize === true) {
                //add resize event
                (document.body as HTMLBodyElement).onresize = this._resize;
                this._resize();
            }else{
                this._video.width = this._options.width;
                this._video.height = this._options.height;
            }
            
            //add to DOM
            this._options.target.innerHTML = '';
            this._options.target.appendChild(this._video);
            
            //add base listeners
            this._addBaseListeners();

        }

        
        /**
         * Get HTML5 video support
        */
        private _isHtml5Video() {
			return !!document.createElement('video').canPlayType;
		};
        
        
        /**
         * Add listener base
        */
        private _addBaseListeners() {
            //listeners base
            let listeners = {
                'onloadedmetadata': function(e) {                    
                    if (this._video.videoWidth && this._video.videoHeight) {
                        if (this._options.forceratio) {
                            this.setSize(this._options.width, this._options.height);
                        }
                        if (this._options.fullsize === true) {
                            this._resize();
                        }
                    }
                }.bind(this)
            };
            
            //add listener to video object
            for (var func in listeners) {
                if (this._video.addEventListener) {
                    this._video.addEventListener(func.substr(2), function(e) {
                        var name = 'on' + e.type;
                        listeners[name](e);
                        
                        if (typeof this._options.callbacks[name] !== 'undefined') this._options.callbacks[name](e);
                    }.bind(this));
                } else {
                    this._video[func] = (function(base, method) {
                        return function(e) {
                            base(e);
                            if (typeof this._options.callbacks[method] !== 'undefined') this._options.callbacks[method](e);
                        }; 
                    })(listeners[func], func);
                }
                
            }
            //add listener to video object
            for (var callback in this._options.callbacks){
                if (this._video.addEventListener){
                    this._video.addEventListener(callback.substr(2), this._options.callbacks[callback]);
                }else{
                    this._video[callback] = this._options.callbacks[callback];
                }
            }
        }
        
        
        /**
         * Set size according to reference
        */
        public setSize(refWidth, refHeight) {
            //width height
            var width = 0;
            var height = 0;
            var nWidth = this._video.videoWidth || refWidth;
            var nHeight = this._video.videoHeight || refHeight;
            
            //scale factor
            var scaleX = refWidth / nWidth;
            var scaleY = refHeight / nHeight;
            var scale = (scaleX > scaleY) ? scaleX : scaleY;
            
            width = Math.ceil(nWidth * scale);
            height = Math.ceil(nHeight * scale);
            
            //postion adjust
            var offsetX = Math.ceil((refWidth - width) / 2);
            var offsetY = Math.ceil((refHeight - height) / 2);
            
            //set properties
            this._video.width = width;
            this._video.height = height; 
            this._video.style.left = offsetX + 'px';
            this._video.style.top = offsetY + 'px';
        };
        

        /**
         * Resize handler
        */
        @autobind
        private _resize() {
            var width = Math.max(document.documentElement["clientWidth"], document.body["scrollWidth"], document.documentElement["scrollWidth"], document.body["offsetWidth"], document.documentElement["offsetWidth"]);
            this.setSize(width, window.innerHeight); //16 is the scroll bar
        }
        
        
        /**
         * Video play
         * @param {Number} Position to seek
        */
        public play(position) {
            if (this._video) {
                if(position !== undefined) this.seek(position);
                this._video.play();
            }
        };
        
        /**
         * Video play
        */
        public resume() {
            if (this._video) this._video.play();
        };
        
        
        /**
         * Video pause
        */
        public pause() {
            if (this._video) this._video.pause();
        };
        
        
        /**
         * Video seek
         * @param {Number} Position to seek
        */
        public seek(position) {
            if (this._video) this._video.currentTime = position;
        };
        
        
        /**
         * Video is playing
        */
        public isPlaying = function() {
            if (this._video) {
                return !this._video.paused;
            } else {
                return false;
            }
        };
        
        
        /**
         * Video's getter
         * @return {Object} Video
        */
        public getVideo() {
            return this._video;
        };
        
        
        /**
         * Public function (getter)
         * @return {Object} Options
        */
        public getOptions() {
            return this._options;
        };
        
}