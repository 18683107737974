import AbstractDispatcher from '../abstract/AbstractDispatcher';
import { autobind } from '../decorators/Autobind';

export default class Request extends AbstractDispatcher {

    private _xhr                 : XMLHttpRequest        = new XMLHttpRequest();
    private _config              : RequestConfig;
    private _defaultHeaders      : Object                = {'X-Requested-With': 'XMLHttpRequest'};

    constructor(config: RequestConfig) {
        super();

        this._config = config;

        if (typeof this._config.url === 'undefined') {
            throw new Error('Property "id" must be specified');
        } 

        // Progress dispatch
        this._xhr.onprogress = this._onProgress;
        
        this._xhr.open(this._config.method, this._config.url, this._config.async);

        // Headers setup
        this._config.headers = {...this._defaultHeaders, ...this._config.headers};
        Object.keys(this._config.headers).forEach(function(key) {
            this._xhr.setRequestHeader(key, this._config.headers[key]);
        }.bind(this));
        
        this._xhr.onload = this._onLoad;
        this._xhr.onerror = this._onError;
        
        this._xhr.send(this._config.data);
    };

    @autobind
    private _onProgress(e) {
        if (e.lenghtComputable) {
            let percent: number = e.position / e.totalSize * 100;
            this.dispatch({type: RequestEvent.PROGRESS, event: e, percent: percent});
        } else {
            this.dispatch({type: RequestEvent.PROGRESS, event: e, percent: null});
        }
    }

    
    /**
     * _onLoad - XHR onLoad handler
     * @param {Event} type - Event's type
     */
    @autobind
    private _onLoad(e) {
        this.dispatch({type: RequestEvent.LOAD, event: e, response: e.target.response});
    };


    /**
     * _onLoad - XHR onLoad handler
     * @param {Event} type - Event's type
     */
    @autobind
    private _onError(e) {
        this.dispatch({type: RequestEvent.ERROR, event: e, status: e.target.status});
    };

    @autobind
    public abort() {
        if (this._xhr.status !== 200) {
            this._xhr.abort();
        }
    }

    /**
     * get status - Get XHR current status
     * @typedef {Object} Object
     * @property {number} http status code
     * @return {string} http status message
     */
    public get status(): Object {
        return {
            code: this._xhr.status,
            message: this._xhr.statusText
        }

    }

}


export class RequestConfig {

    private _url                    : string;
    private _method                 : string                        = RequestMethod.GET;
    private _headers                : Object;
    private _data                   : Object                        = null;
    private _async                  : boolean                       = true;


    public set url(value: string) { this._url = value; }
    public get url(): string { return this._url; }

    public set method(value: string) { this._method = value; }
    public get method(): string { return this._method; }

    public set headers(value: Object) { this._headers = value; }
    public get headers(): Object { return this._headers; }

    public set data(value: Object) { this._data = value; }
    public get data(): Object { return this._data; }

    public set async(value: boolean) { this._async = value; }
    public get async(): boolean { return this._async; }
}



export class RequestMethod {
    public static GET               : string                        = 'GET';
    public static POST              : string                        = 'POST';
}

export class RequestEvent {
    public static PROGRESS          : string                        = 'progress';
    public static LOAD              : string                        = 'load';
    public static ERROR             : string                        = 'error';
}