import Module from '../Abstract/Module';
import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import Youtube, { YoutubeObject } from '../vendors/frontools/src/medias/video/Youtube';
import Context from '../vendors/frontools/src/core/Context';
import Breakpoint from '../vendors/frontools/src/core/Breakpoint';


declare const TweenMax;
declare const Expo;

export class Gdpr extends Module {
    private _cookieKey : string = 'bcf:gdpr-closed';
    private _elScrollingElement: Element
    
    constructor(protected _element: HTMLElement) {
        super(_element);
        
        document.querySelector('body').classList.add('gdpr-visible');
        
        this._elScrollingElement = document.scrollingElement || document.documentElement;
        this._initEvents();

    }
    
    private _initEvents() {
        this._onScroll = this._onScroll.bind(this);
        window.addEventListener('scroll', this._onScroll);
        this._element.querySelector('[data-js="button-close"]').addEventListener('click', this._close)
    }

    private _onScroll(e) {
        let scrollPos = this._elScrollingElement.scrollTop;
        
        if(scrollPos > 0) {
            document.body.classList.remove('gdpr-visible');
        } else {
            document.body.classList.add('gdpr-visible');
        }
    }

    @autobind
    private _close(e) {
        e.preventDefault();
        document.body.classList.remove('gdpr-visible');
        window.removeEventListener('scroll', this._onScroll);
        this._element.parentElement.removeChild(this._element);
        var date:Date = new Date(); 
		date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
		document.cookie = this._cookieKey + '=true; expires=' + date.toUTCString();
    }
}