import Module from '../Abstract/Module';
import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import Youtube, { YoutubeObject } from '../vendors/frontools/src/medias/video/Youtube';
import Context from '../vendors/frontools/src/core/Context';
import Breakpoint from '../vendors/frontools/src/core/Breakpoint';

declare const TweenMax;
declare const Expo;

export class Video extends Module {

    private _isInit : boolean = false;

    private _link : HTMLLinkElement;
    private _videoWrapper : HTMLElement;
    private _youtubePlayer: Youtube;
    
    constructor(protected _element: HTMLElement) {
        super(_element, {intersection: {
            rootMargin: `-48% 0px -48% 0px`,
        },}
        );

        this._link = this._element.querySelector('[data-video-link]') as HTMLLinkElement;
        this._videoWrapper = this._element.querySelector('[data-video-wrapper]');

        if (!this._link || !this._videoWrapper) return;

        this._link.addEventListener('click', this._onLinkClick);
    }


    @autobind
    private _onLinkClick(e) {
        e.preventDefault();

        this._init();
    }
    
    private _init() {
        if (this._isInit) return;

        this._isInit = true;

        this._element.classList.add('is-loading');

        const youtubeObject = new YoutubeObject();
        youtubeObject.id = Youtube.getIdFromUrl(this._link.href);
        youtubeObject.target = this._videoWrapper;  
        const youtubePlayer = new Youtube(youtubeObject);


        youtubePlayer.addListener(Youtube.READY, this._onPlayerReady);
    }       

    @autobind
    private _onPlayerReady() {
        this._element.classList.add('is-ready');            
    }

    @autobind   
    protected _onIntersection(entries) {
        if (entries[0].isIntersecting)  {
            document.documentElement.classList.add('has-video');
            if (Breakpoint.getInstance().isInGroup('group_mobile')) {
                this._init();   
            }
        } else {
            document.documentElement.classList.remove('has-video');
        }  
    }
  
}