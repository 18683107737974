import ScrollUtils from "../vendors/frontools/src/utils/ScrollUtils";

declare const TweenMax;
declare const Expo;
declare const Quad;
declare const ScrollMagic;

export class AnchorScroll {

	private _offset : number = 200;
	private _speed : number = 0.4;
	private _nav : HTMLElement;
	private _cookieAlert : HTMLElement;

    constructor() {

    	//use media query to check if at desktop width
    	var desktopWidth = window.matchMedia('(min-width: 1100px)');

        //get height of visible navigation element
		if(desktopWidth){
			this._offset = 200;
		}
		else{
			this._offset = 85;
		}

		//go to mathcing anchro on scroll
		this._anchorScrollOnLoad();

		//grab all anchors with a.href, in the main, matching "#"
        this._setAnchorsActions();

    }

    private _anchorScrollOnLoad() {

    	//checks is window hash is NOT null and scroll to found element
        if(window.location.hash != null){
            let urlHash = String(window.location.hash).replace("#","");
            this._scrollToElement(urlHash);
        }

    }

    //general scrollToElement item
     private _scrollToElement(hashName:String){
    	var el = document.querySelector("a[name='" + hashName + "']");

    	if(el != null){
	    	ScrollUtils.scrollTo({
	            target: el,
	            speed: this._speed,
	            offset: this._offset,
	        })
    	}
    }

    //click event for anchor elements
    private _jumpToElementOnClick(e:MouseEvent){
    	var target = e.target as HTMLElement;
    	var elAttribute : string = target.getAttribute("href");

    	if(elAttribute != null){
	    	let hrefHash = String(elAttribute).replace("#","");
	    	this._scrollToElement(hrefHash);
    	}
    }

    //grab all anchors with an href set to a hashBROWN :)
    private _setAnchorsActions(){
    	let inPageAnchors = document.querySelectorAll(".main a[href*='#']");

    	//bind this context for click action
    	this._jumpToElementOnClick =  this._jumpToElementOnClick.bind(this);

    	//cycles through all achors and assign click event
    	for (let i = 0; i < inPageAnchors.length; i++) {	
            inPageAnchors[i].addEventListener('click', this._jumpToElementOnClick);
        }
    }

   

}
