/**
 * ArrayUtils
 * (c) lg2fabrique 2016
 *
 */

export default class ArrayUtils {

    /**
     * function clone - Clone an array
     * @param {array} arr - Array to clone
     * @return {array}
        *   ArrayUtils.clone([1,2,3]);
    */
    public static clone(arr: any[]): any[] {
        var array = [];
        for (var i = 0, l = arr.length; i < l; ++i) {
            array.push(arr[i]);
        }
        return array;
    };

    /**
     * Determines whether the specified array contains value.
     * @param {array} arr - Array
     * @param {*} needle Value to match against
     * @return {boolean}
        *   ArrayUtils.contains([1,2,3], 3);
    */
    public static contains(arr: any[], needle: any): boolean {
        return (arr.indexOf(needle) !== -1);
    };

    /**
     * function remove - Remove item in array
     * @param {*} needle - Value to be removed
     * @param {array} arr - Array
     * @param {boolean} needle - Value to be removed
     * @param {boolean} [clone=false] - modify and returns a cloned array instead of the original
     * @return {array} - Returns a new array
        *   ArrayUtils.remove([1,2,3], 2);
    */
    public static remove(needle: any, arr: any[]): any[] {
        arr = ArrayUtils.clone(arr);

        for (var i = arr.length; i > -1; i--) {
            if (arr[i] === needle) arr.splice(i, 1);
        }
        return arr;
    };

    /**
     * function insert - Insert item in array
     * @param {*} needle - Value to insert
     * @param {array} arr - Array
     * @param {number} [position=0] - Position where to insert the needle
     * @param {boolean} [clone=false] - modify a cloned array instead of an original
     * @return {array} - Returns a new array
        *   ArrayUtils.insert([1,2,3], 'allo', 1);
    */
    public static insert(needle: any, arr: any[], position: number = 0, clone: boolean = false): any[] {
        let aA = arr.slice(0, position),
            aB = arr.slice(position);
        aA.push(needle);
        let merged = ArrayUtils.merge(aA, aB);
        arr = clone ? merged : ArrayUtils.clone(merged);

        return arr;
    };

    /**
     * functionDetermines if 2 array are equal
     * @param {array} arr1 - Array 1
     * @param {array} arr2 - Array 2
     * @return {boolean}
        *   ArrayUtils.equal([1,2,3], [1,2,3]);
    */
    public static equal(arr1: any[], arr2: any[]): boolean {
        if (arr1.length != arr2.length) return false;

        for (var i = 0, l = arr1.length; i < l; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    };

    /**
     * function merge - Merge 2 arrays in 1
     * @param {array} arr1 - Array
     * @param {array} arr2 - Array
     * @return {array} - New array
        *   ArrayUtils.merge([1,2,3], [4,5,6]);
    */
    public static merge(arr1: any[], arr2: any[]): any[] {
        let arr3 = arr1.concat(arr2),
            i = arr1.length - 1;
        return arr3;
    };


    /**
     * Random the array
     * @param {array} arr1 - Array
     * @return {array} - New array
     *   ArrayUtils.randomize([1,2,3]);
     */
    public static randomize(arr: any[]){
        for (var i = arr.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = arr[i];
            arr[i] = arr[j];
            arr[j] = temp;
        }
        return arr
    };
}
