/**
 * Pinterest
 * (c) lg2fabrique 2016
 */

export default class Pinterest {

    private static _instance: Pinterest;

    /**
     * constructor
     */
    constructor(parseName:string = 'parsePinBtns') {
        //singleton validation
        if(Pinterest._instance) throw new Error('Error: Use Pinterest.getInstance() instead of new.');
        Pinterest._instance = this;

        (function() {var po = document.createElement('script'); po['data-pin-build'] = parseName; po.src = '//assets.pinterest.com/js/pinit.js';var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);})();
    }

    /**
     * Re-parse the DOM
     *   Pinterest.parse();
     */
    public static parse(func:string = 'parsePinBtns') {
        if((window as any)[func]) (window as any)[func]();
    };

    /**
     * get singleton instance
     * @returns {Pinterest}  instance's Pinterest
     */
    public static getInstance(): Pinterest {
        return Pinterest._instance;
    }
}