/**
 * Youtube
 * (c) lg2fabrique 2017
 *
 */

/// <reference path="../../definitions/medias/Youtube.d.ts" />

import AbstractDispatcher from '../../abstract/AbstractDispatcher';
import { autobind } from '../../decorators/Autobind';

export default class Youtube extends AbstractDispatcher {
    
    public static READY                         : string                          = "onready";
    public static ERROR                         : string                          = "onerror";
    public static STATE_CHANGE                  : string                          = "onstatechange"
    public static PROGRESS                      : string                          = "onProgress"
    public static PROGRESS_QUARTER              : string                          = "onprogressQuarter"
    public static PROGRESS_TRACK                : string                          = "onProgressTrack"
    public static SEEK                          : string                          = "onSeek"

    private static PROGRESS_DELAY                : number                          = 500;
    private static SEEK_PERID                    : number                          = 500;
    private static SEEK_MARGIN                   : number                          = 500;

    private static playerCount                  : number                          = 0;
    private static playerStack                  : Array<any>                      = [];
    
    private static hasScriptDOM                 : boolean                         = false;
    private static hasScriptReady               : boolean                         = false;
    private static hasAPIDefined                : boolean                         = false;
    
    private _settings                           : YoutubeObject;

    private _isPlaying                          : boolean                         = false;
    private _progressTimer                      : number                          = null;
    private _lastPercentRange                   : number                          = 0;
    private _checkerInterval                    : number                          = null;
    private _prevCurrentTime                    : number                          = 0;
    private _hasSeek                            : boolean                         = false;
    private _tracked                            : any                             = {};
    private _index                              : number                          = 0;
    private _player                             : any                             = null;

    private _APIEvents                           :any;

    constructor(obj:YoutubeObject) {
        super();
       
        this._settings = obj;
                
        //id validation
        if(this._settings.id === undefined) throw new Error('Property "id" must be specified');
        if(this._settings.target === undefined) throw new Error('Property "target" must be specified');

        //create player to DOM + API
        this.createPlayer();
    }

     /**
     * Create iFrame element from options
    */
    private createPlayer() {
        
        //empty target
        this._isPlaying = false;
        this._settings.target.innerHTML = '';
        clearTimeout(this._progressTimer);
        
        
        //Player count + set index;
        Youtube.playerCount++;
        this._index = Youtube.playerCount; 
        //Appending responsive CSS style
        if(!document.getElementById('youtube-js-style') && this._settings.responsive) {
            var head = document.head || document.getElementsByTagName('head')[0];
            var css = '.youtube_video_wrapper{width:100%;position:relative;padding:0;}.youtube_video_wrapper iframe,.youtube_video_wrapper object,.youtube_video_wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}';
            var div = document.createElement("div");
            div.innerHTML = '<p>x</p><style id="youtube-js-style">' + css + '</style>';
            head.appendChild(div.childNodes[1]);
        }        
        
        
        //load/init API Frame
        if(this._settings.playerSettings.enablejsapi) this.createAPI();
        else this.createFrame();   
    }

    private createFrame() {
        //set source url
        var src:string = 'https://www.youtube.com/embed/' + this._settings.id;
        var no:number = 0;
        for(var j in this._settings.playerSettings){
            src += (no===0 ? '?' : '&') + j + '=' + this._settings.playerSettings[j];
            no++;
        }
        
        //set iframe element
        this._player = document.createElement('iframe');
        this._player.setAttribute('src', src);
        this._player.setAttribute('id', this._settings.namespace + this._index);
        for(var k in this._settings.iframeSettings){
            this._player.setAttribute(k, this._settings.iframeSettings[k]);
        }
        
        //append to DOM
        if(this._settings.responsive) {
            this.createWrapperFor(this._player);
        } else {
            this._player.setAttribute('width', String(this._settings.width)) ;
            this._player.setAttribute('height', String(this._settings.height));

            this._settings.target.appendChild(this._player);
        }
    }

    /*
        For responsive video only
        Create a wrapper for resizing purpose.
        @param child : iframe
    */
    private createWrapperFor(child:HTMLIFrameElement|HTMLDivElement) {
        if(!this._settings.aspectRatio) this._settings.aspectRatio = this._settings.height / this._settings.width;
        else {
            if(typeof this._settings.aspectRatio == 'string'){
                var ratio:any = this._settings.aspectRatio.split(':');
                this._settings.aspectRatio = ratio[1] / ratio[0];
            }
        }
        
        var wrapper= document.createElement('div');
        wrapper.setAttribute('class', "youtube_video_wrapper");
        wrapper.setAttribute('style', 'padding-top: '+this._settings.aspectRatio*100 +'%;');
        
        wrapper.appendChild(child);
        this._settings.target.appendChild(wrapper);
    }

    /**
     * Create API if enablejsapi == true
    */
    private createAPI() {
        this._APIEvents = {
            onReady: this.handleApiReady,
            onError: this.handleApiError,
            onStateChange: this.handleApiStateChange
        };
        
        //youtube init
        window['youtubeInit' + this._index] = this.handleApi;
        
        if((window as any).onYoutubeIframeAPIReady) {
            Youtube.hasAPIDefined = true;
            (window as any).onYouTubeIframeAPIReady = this.handleIframeApiReady;
        } else if (!Youtube.hasAPIDefined) {
            this._checkerInterval = 0;
            this._checkerInterval = setInterval(this.checkAPI, 500);
        }
        
        //add script to DOM
        if(Youtube.hasScriptDOM === false) {
            Youtube.hasScriptDOM = true;
            //add/create API
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://www.youtube.com/iframe_api';
            document.getElementsByTagName('body')[0].appendChild(script);
        } else {
            //add to the stackflow and waiting skd loading
            if(Youtube.hasScriptReady === false) Youtube.playerStack.push(window['youtubeInit' + this._index]);
            else window['youtubeInit' + this._index].call();
        }
    }

    @autobind
    private handleApi(stack) {
        clearInterval(this._checkerInterval);

        var iframe:HTMLDivElement = document.createElement('div');        
        iframe.setAttribute('id', this._settings.namespace + this._index);
        
        if(this._settings.responsive) this.createWrapperFor(iframe);                       
        else this._settings.target.appendChild(iframe);
        
        this._player = new YT.Player(this._settings.namespace + this._index, {
            width: this._settings.width,
            height: this._settings.height,
            videoId: this._settings.id,
            playerVars: this._settings.playerSettings,
            events: this._APIEvents
        });
        
        //init all other players in the stack (stacking)
        if(stack === undefined){
            while(Youtube.playerStack.length !== 0){
                var init = Youtube.playerStack[0];
                Youtube.playerStack.splice(0, 1);
                init.call(null, [true]);
            }
        }
        
        //self-deleting method instance
        window['youtubeInit' + this._index] = undefined;
        try{delete window['youtubeInit' + this._index];}catch(e){}
    }
    @autobind
    private handleIframeApiReady() {
        Youtube.hasScriptReady = true;
        window['youtubeInit' + this._index].call();
    }
    @autobind
    private checkAPI() {
        if((window as any).YT && (window as any).YT.Player) {
            Youtube.hasScriptReady = true;
            Youtube.hasAPIDefined = true;
            clearInterval(this._checkerInterval);
            window['youtubeInit' + this._index].call();
        }
    }

    @autobind
    private handleApiReady(event) {
        console.info('YOUTUBE: Ready', this._settings.id);
        this.dispatch({type: Youtube.READY})
    }
    @autobind
    private handleApiError(event) {
        console.info('YOUTUBE: Error', this._settings.id);
        this.dispatch({type: Youtube.ERROR})
    }
    @autobind
    private handleApiStateChange(event) {
        if(event.data === YT.PlayerState.BUFFERING){
            this._isPlaying = false;
        }
        if(event.data === YT.PlayerState.PLAYING){
            this._isPlaying = true;
            this._progressTimer = setTimeout(this.onProgress, Youtube.PROGRESS_DELAY);
            this.onCheckSeek();
        }
        if(event.data === YT.PlayerState.ENDED || event.data === YT.PlayerState.PAUSED){
            this._isPlaying = false;
            clearTimeout(this._progressTimer);
        }
        console.info('YOUTUBE: State Change', event.data);
        this.dispatch({type: Youtube.STATE_CHANGE, data: event.data});
    }

    /**
     * Progress player timer
    */
    @autobind
    private onProgress(){
        if (this._player && this._player.getPlayerState() === YT.PlayerState.PLAYING) {
            this.track();
            this.dispatch({type: Youtube.PROGRESS, currentTime: this._player.getCurrentTime()});
            
            this._progressTimer = setTimeout(this.onProgress, Youtube.PROGRESS_DELAY);
        }
    }

    private track() {
        var time = this._player.getCurrentTime();
        var duration = this._player.getDuration();
        var second = Math.floor(time);
        var percent = Math.floor(time/duration*100);

        var quarter = Math.floor(time / duration * 4);
        var percentRange = quarter * 25;
        if(isFinite(percentRange) && percentRange >= 0 && percentRange <= 100 && percentRange !== this._lastPercentRange){
            this._lastPercentRange = percentRange;
            this.dispatch({type: Youtube.PROGRESS_QUARTER, percentRange: percentRange, hasSeek: this._hasSeek});
        }

        for(var i = 0; i < this._settings.trackSeconds.length; i++){
            if(second >= this._settings.trackSeconds[i] && !this._tracked['second_' + i]){
                this._tracked['second_' + i] = true;
                this.dispatch({type: Youtube.PROGRESS_TRACK, second: this._settings.trackSeconds[i], hasSeek: this._hasSeek});
            }
        }

        for(var i = 0; i < this._settings.trackPercents.length; i++){
            if(percent >= this._settings.trackPercents[i] && !this._tracked['percent_' + i]){
                this._tracked['percent_' + i] = true;
                this.dispatch({type: Youtube.PROGRESS_TRACK, percent: this._settings.trackPercents[i], hasSeek: this._hasSeek});
            }
        }
    }
    
    /**
     * User seeking validation
    */
    @autobind
    private onCheckSeek(){
        if (!this._isPlaying) {
            this._prevCurrentTime = -1;
            return;
        }

        var currentTime = this._player.getCurrentTime();
        if(this._prevCurrentTime > 0){
            var diff = (currentTime - this._prevCurrentTime) * 1000;
            if(Math.abs(diff - Youtube.SEEK_PERID) > Youtube.SEEK_MARGIN){
                this._hasSeek = true;

                this.dispatch({type: Youtube.SEEK, currentTime: currentTime});
            }
        }
        this._prevCurrentTime = currentTime;
        
        setTimeout(this.onCheckSeek, Youtube.SEEK_PERID);
    }

    public destroy() {
        if (this._settings.playerSettings.enablejsapi) {
            this._player.destroy();
        } else {
            this._player.parentNode.removeChild(this._player);
        }
    }
    public get player():any { return this._player}
    public get isPlaying():boolean { return this._isPlaying }
    
    public get id():string {
        return this._settings.id;
    }
    public set id(id:string) {
        this._settings.id = id;
        this.createPlayer();
    }

    public set src(url:string) {
        this._settings.id = Youtube.getIdFromUrl(url);
        this.createPlayer();
    }

    public static getIdFromUrl(url:string) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
            match = url.match(regExp);
        return match && match[2].length == 11 ? match[2] : null;
    }
}

export class YoutubeObject {

    private _id                     : string;
    private _target                 : HTMLElement;
    
    private _width                  : number                        = 560;
    private _height                 : number                        = 315;
    
    private _isResponsive           : boolean                       = true; 
    private _aspectRatio            : string|number                 = '16:9';

    private _namespace              : string                        = 'player_';

    private _trackSeconds            : Array<number>                 = [30];
    private _trackPercents           : Array<number>                 = [10, 25, 50, 75, 90];

    private _iframeSettings         : any                           = {
                                                                        frameBorder: 0,
                                                                        allowFullScreen: ''
                                                                    };
    
    //(https://developers.google.com/youtube/player_parameters?hl=fr) "All YouTube player parameters"
    private _playerSettings         : any                           = {
                                                                        autoplay: 1,
                                                                        controls: 1,
                                                                        showinfo: 1, 
                                                                        autohide: 1,
                                                                        rel:0,
                                                                        enablejsapi:1
                                                                    };

    public set id(value:string) { this._id = value; }
    public get id():string { return this._id; }

    public set target(value:HTMLElement) { this._target = value; }
    public get target():HTMLElement { return this._target; }

    public set width(value:number) { this._width = value; }
    public get width():number { return this._width; }
    
    public set height(value:number) { this._height = value; }
    public get height():number { return this._height; }

    public set responsive(value:boolean) { this._isResponsive = value; }
    public get responsive():boolean { return this._isResponsive; }
    
    public set aspectRatio(value:string|number) { this._aspectRatio = value; }
    public get aspectRatio():string|number { return this._aspectRatio; }
    
    public set namespace(value:string) { this._namespace = value; }
    public get namespace():string { return this._namespace; }

    public set iframeSettings(value:any) { this._iframeSettings = value; }
    public get iframeSettings():any { return this._iframeSettings; }

    public set trackSeconds(value:Array<number>) { this._trackSeconds = value; }
    public get trackSeconds():Array<number> { return this._trackSeconds; }

    public set trackPercents(value:Array<number>) { this._trackPercents = value; }
    public get trackPercents():Array<number> { return this._trackPercents; }

    public set playerSettings(value:any) { this._playerSettings = value; }
    public get playerSettings():any { return this._playerSettings; }
}