/**
 * Twitter
 * (c) lg2fabrique 2016
 *
 var twitter = new Twitter({
    ready:function(){ console.log('ready')},
    click:function(){ console.log('click')},
    tweet:function(){ console.log('tweet')},
    retweet:function(){ console.log('retweet')},
    favorite:function(){ console.log('favorite')},
    follow:function(){ console.log('follow')}
});
 */

import DocumentUtils from '../utils/DocumentUtils';
import UrlUtils from '../utils/UrlUtils';

export default class Twitter {

    private static _instance: Twitter;

    /**
     * Create Twitter widget
     * @param {Object} every callbacks of twitter
     *   var twitter = new Twitter({
                ready:function(){ console.log('ready')},
                click:function(){ console.log('click')},
                tweet:function(){ console.log('tweet')},
                retweet:function(){ console.log('retweet')},
                favorite:function(){ console.log('favorite')},
                follow:function(){ console.log('follow')}
            });
     */
    constructor(callbacks:any = {}) {
        //singleton validation
        if(Twitter._instance) throw new Error('Error: Use Twitter.getInstance() instead of new.');
        Twitter._instance = this;

        (window as any).twttr = (function (d,s,id) { var t, js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return; js=d.createElement(s); js.id=id;js.src="//platform.twitter.com/widgets.js"; fjs.parentNode.insertBefore(js, fjs); return (window as any).twttr || (t = { _e: [], ready: function(f){ t._e.push(f); } });}(document, "script", "twitter-wjs"));
        (window as any).twttr.ready(function (twttr) {
            if (typeof(callbacks.ready) === 'function')  callbacks.ready();
            if (typeof(callbacks.click) === 'function') (window as any).twttr.events.bind('click', callbacks.click);
            if (typeof(callbacks.tweet) === 'function') (window as any).twttr.events.bind('tweet', callbacks.tweet);
            if (typeof(callbacks.retweet) === 'function') (window as any).twttr.events.bind('retweet', callbacks.retweet);
            if (typeof(callbacks.favorite) === 'function') (window as any).twttr.events.bind('favorite', callbacks.favorite);
            if (typeof(callbacks.follow) === 'function') (window as any).twttr.events.bind('follow', callbacks.follow);
        });
    }


    /**
     * Re-parse the DOM
     *   Twitter.parse();
     */
    public static parse() {
        if((window as any).twttr) (window as any).twttr.widgets.load();
        else throw new Error('window.twttr is not defined. new Twitter() must be called.');
    };


    /**
     * Open window to the Twitter Page
     * @param {String} Url to open
     * @param {Object} Configuration of twitter according to url('https://dev.twitter.com/docs/intents')
     * @param {Boolean} Open popup or _blank
     * @param {Number} Popup width
     * @param {Number} Popup height
     *   Twitter.open('https://twitter.com/intent/tweet', {hashtags:'google'});
     */
    public static open(url:string, options:any = {}, popup:boolean = true, width:number = 600, height:number = 400) {
        url = UrlUtils.query(url, options);

        if(popup === true) DocumentUtils.openPopup(url, width, height);
        else window.open(url, '_blank');
    };


    /**
     * Open window to the Twitter Tweet page
     * @param {Object} Configuration for twitter (url, via, text, in_reply_to, hashtags, related)
     * @param {Boolean} Open popup or _blank
     * @param {Number} Popup width
     * @param {Number} Popup height
     *   Twitter.tweet({
                url:'http://www.google.ca',
                text:'Un site super.',
                hashtags:'google,recherche'
            });
     */
    public static tweet(options:any = {}, popup:boolean = true, width:number = 600, height:number = 400) {
        if(options.url){
            options.url =  UrlUtils.query(options.url, {
                utm_source: 'twitter',
                utm_medium: 'social'
            });
        }
        //if(options.text) options.text = encodeURIComponent(options.text);
        Twitter.open('https://twitter.com/intent/tweet', options, popup, width, height);
    };


    /**
     * Open window to the Twitter Retweet page
     * @param {Object} Configuration for twitter (tweet_id, related)
     * @param {Boolean} Open popup or _blank
     * @param {Number} Popup width
     * @param {Number} Popup height
     *   Twitter.retweet({tweet_id:'770247441887141888'});
     */
    public static retweet(options:any = {}, popup:boolean = true, width:number = 600, height:number = 400) {
        if(!options.tweet_id) throw new Error('tweet_id is not defined');
        Twitter.open('https://twitter.com/intent/retweet', options, popup, width, height);
    };


    /**
     * Open window to the Twitter Favorite page
     * @param {Object} Configuration for twitter (tweet_id, related)
     * @param {Boolean} Open popup or _blank
     * @param {Number} Popup width
     * @param {Number} Popup height
     *   Twitter.favorite({tweet_id:'770247441887141888'});
     */
    public static favorite(options:any = {}, popup:boolean = true, width:number = 600, height:number = 400) {
        if(!options.tweet_id) throw new Error('tweet_id is not defined');
        Twitter.open('https://twitter.com/intent/favorite', options, popup, width, height);
    };


    /**
     * Open window to the Twitter User page
     * @param {Object} Configuration for twitter (screen_name, user_id)
     * @param {Boolean} Open popup or _blank
     * @param {Number} Popup width
     * @param {Number} Popup height
     *   Twitter.user({screen_name:'fperreault'});
     */
    public static user(options:any = {}, popup:boolean = true, width:number = 600, height:number = 400) {
        if(!options.screen_name && !options.user_id) throw new Error('screen_name and user_id are not defined');
        Twitter.open('https://twitter.com/intent/user', options, popup, width, height);
    };


    /**
     * Wrap link into a tag.
     * @param {String} String to convert
     * @return {String}
     *   Twitter.parseURL('Été de mes 11 ans à http://www.lg2.com', 'a')
     */
    public static parseURL(str:string) {
        return str.replace(
            /[A-Za-z]+:\/\/[A-Za-z0-9-​_]+\.[A-Za-z0-9-_​:%&~\?\/.=]+/g,
            function(url) {
                return '<a href="' + url + '" target="_blank">' + url + '</a>';
            });
    };


    /**
     * Wrap a username into a tag.
     * @param {String} String to convert
     * @return {String}
     *   Twitter.parseUsername('@toto Tu es un boute en train.', 'strong')
     */
    public static parseUsername(str:string) {
        return str.replace(/[@]+[A-Za-z0-9-_öäüÖÄÜáàâéèêúùûóòôÁÀÂÉÈÊÚÙÛÓÒÔ]+/g, function(u) {
            var username = u.replace('@', '');
            return '<a href="https://twitter.com/' + encodeURIComponent(username) + '" target="_blank">' + u + '</a>';
        });
    };


    /**
     * Wrap a hashtag into a tag.
     * @param {String} String to convert
     * @return {String}
     *   Twitter.parseHashtag('Un autre text #lol', 'strong')
     */
    public static parseHashtag(str:string) {
        return str.replace(/[#]+[A-Za-z0-9-_öäüÖÄÜáàâéèêúùûóòôÁÀÂÉÈÊÚÙÛÓÒÔ]+/g, function(t) {
            return '<a href="https://twitter.com/search?q=' + encodeURIComponent(t) + '" target="_blank">' + t + '</a>';
        });
    };


    /**
     * get singleton instance
     * @returns {Twitter}  instance's Twitter
     */
    public static getInstance(): Twitter {
        return Twitter._instance;
    }

}