import AbstractDispatcher from '../abstract/AbstractDispatcher';
import EventType from '../events/EventType';

export default class TouchFeature extends AbstractDispatcher {
    protected static _instance: TouchFeature;
    private _touchpoints = [];
    private _isTouch: boolean = null;

    constructor() {
        super();

        //singleton validation
        if (TouchFeature._instance)
            throw new Error('Error: Use TouchFeature.instance instead of new.');
        TouchFeature._instance = this;

        this.init();
    }

    private init() {
        this.handleTouchInteraction = this.handleTouchInteraction.bind(this);
        this.handleMouseInteraction = this.handleMouseInteraction.bind(this);
        this.registerTouch = this.registerTouch.bind(this);

        window.addEventListener(EventType.TOUCH_START, this.registerTouch);
        window.addEventListener(EventType.TOUCH_END, this.registerTouch);

        document.addEventListener(
            EventType.MOUSE_MOVE,
            this.handleMouseInteraction
        );
        document.addEventListener(
            EventType.TOUCH_START,
            this.handleTouchInteraction
        );
    }

    private registerTouch(e) {
        let touch = e.touches[0] || e.changedTouches[0];
        this._touchpoints.push(touch);
        setTimeout(() => {
            this._touchpoints.splice(this._touchpoints.indexOf(touch), 1);
        }, 1000);
    }

    private handleTouchInteraction(e) {
        document.removeEventListener(
            EventType.TOUCH_START,
            this.handleTouchInteraction
        );

        this._isTouch = true;

        // We then dispatch the result
        this.dispatch({
            type: EventType.INTERACTION_CHANGE,
            isTouch: this._isTouch
        });

        document.addEventListener(
            EventType.MOUSE_MOVE,
            this.handleMouseInteraction
        );
    }

    private handleMouseInteraction(e) {
        // prevent event if the mouse interaction was triggered by a touch event
        if (this._touchpoints.length > 0) {
            e.cancel = true;
            e.returnValue = false;
            e.cancelBubble = true;
            e.preventDefault();
            e.stopPropagation();
        } else {
            document.removeEventListener(
                EventType.MOUSE_MOVE,
                this.handleMouseInteraction
            );

            this._isTouch = false;

            // We then dispatch the result
            this.dispatch({
                type: EventType.INTERACTION_CHANGE,
                isTouch: false
            });

            document.addEventListener(
                EventType.TOUCH_START,
                this.handleTouchInteraction
            );
        }
    }

    /**
     * get singleton instance
     * @returns {TouchFeature}  instance's TouchFeature
     */
    public static get instance(): TouchFeature {
        return TouchFeature._instance;
    }
}
