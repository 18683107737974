import LatLng = google.maps.LatLng;
import ObjectUtils from "../utils/ObjectUtils";
/**
 * HtmlOverlay
 * (c) lg2fabrique 2016

 var overlay = new HtmlOverlay({
    position: {lat: 48.79239019646408, lng: -67.159423828125},
    anchorX: 0,
    anchorY: 0,
    map: GoogleMapObject(map.getMap()), //google map object
    classname: 'html-overlay',
    template: '<div>overlay</div>',
    close: '<div>X</div>',
    callbacks: {
        ready:function(overlay){},
        close:function(){}
    }
 });
 */

/// <reference path="../definitions/map/GoogleMap.d.ts" />

export default class HtmlOverlay {

    public static zIndex                        : number            = 4000000;  //z-index change on click

    private _overlay                             : any;                          //overlay object
    private _holder                              : HTMLElement;                  //overlay holder
    private _latlng                              : LatLng;                       //geo position
    private _isRendered                          : boolean           = false;    //render state
    private _options                             : any               = {         //overlay default options
        anchorX:0,
        anchorY:0,
        template:'<div>default</div>',
        close:'<div>x</div>',
        classname:'html-overlay'
    };

    /**
     * constructor
     */
    constructor(obj:any) {
        //google map validation
        if(!google.maps.OverlayView){
            throw new Error('GoogleMap need to be "ready" to use HtmlOverlay');
        }

        // extends custom options
        ObjectUtils.extends(this._options, obj);

        //create overlay
        this._overlay = new google.maps.OverlayView();
        if(obj.position instanceof google.maps.LatLng ) this._latlng = obj.position;
        else this._latlng = new google.maps.LatLng(obj.position.lat, obj.position.lng);

        //override default function
        this._overlay.draw = this.draw.bind(this);
        this._overlay.remove = this.removeOverlay.bind(this);

        //overlay configuration
        this._overlay.latlng = this._latlng;

        if(!obj.map){
            throw new Error('map is not specified. HtmlOverlay need to have a map. Can be set on constructor object.');
        }else this._overlay.setMap(obj.map);
    }

    /**
     * Create holder of overlay
     */
    private setHolder(){
        //set base div
        this._holder = this._overlay.div = document.createElement('div');
        this._holder.className = this._options.classname;
        this._holder.style.position = 'absolute';

        //append template
        this._holder.innerHTML = this._options.template;

        //add holder to overlay
        var panes = this._overlay.getPanes();
        panes.overlayImage.appendChild(this._holder);

        //close button
        var close = document.createElement('div');
        close.className = 'button-close';
        close.innerHTML = this._options.close;
        close.addEventListener('click', function(){
            if(this._options.callbacks.close) this._options.callbacks.close();
            this.parentElement.removeChild(this);
        }.bind(this));
        this._holder.appendChild(close);


        this.getHtmlElement().style.zIndex = String(HtmlOverlay.zIndex);
        HtmlOverlay.zIndex++;

        this._isRendered = true;
        if(this._options.callbacks.ready) this._options.callbacks.ready(this);
    }

    /**
     * Render process - called when zoomIn/zoomOut
     */
    private draw(){
        //create holder
        this._holder = this._overlay.div;
        if (!this._holder) this.setHolder();

        //apply projection to holder
        var point = this._overlay.getProjection().fromLatLngToDivPixel(this._latlng);
        if (point) {
            //adjust position accoring to overlay anchor
            this._holder.style.left = (point.x - this._options.anchorX) + 'px';
            this._holder.style.top = (point.y - this._options.anchorY) + 'px';
        }
    }

    /**
     * Override Remove - called after overlay.setMap(null) or self.remove();
     */
    private removeOverlay() {
        if(this._holder){
            this._holder.parentNode.removeChild(this._holder);
            this._isRendered = false;
        }
    };


    /**
     * Remove overlay
     */
    public remove(){
        if(this._overlay) this._overlay.setMap(null);
    };

    /**
     * Return DOM elemt of the overlay
     */
    public getHtmlElement(){
        if(this._holder) return this._holder;
        else if(!this._isRendered) throw new Error('Overlay is not rendered. Perhaps it\'s not added to a map or overlay is not ready');
    };

    /**
     * Return latlng of the overlay (google.maps.LatLng)
     */
    public getPosition(){
        return this._latlng;
    };

    /**
     * Return marker's anchor, origin in pixels
     */
    public getAnchor(){
        return {x:this._options.anchorX, y:this._options.anchorY};
    };

    /**
     * Get self props
     */
    public get(key:string){
        return this[key];
    };

    /**
     * Set self props
     */
    public set(key:string, value:any){
        this[key] = value;
    }


}