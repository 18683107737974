/**
 * Decorator - Add bind automaticaly - Usefull to use removeEventListener
 */
export function autobind(target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) {

    var original = descriptor && descriptor.value;
    var definingProperty = false;

    if (typeof original !== 'function' || original == undefined) return;

    return {
        configurable: true,
        set(value){
            original = value;
        },
        get() {
            if (definingProperty || this === target.prototype || this.hasOwnProperty(propertyKey)) {
                return original;
            }

            let binding = original.bind(this);
            definingProperty = true;
            
            Object.defineProperty(this, propertyKey, {
                value: binding,
                configurable: true,
                writable: true
            });
            definingProperty = false;
            return binding;
        }
    };
}