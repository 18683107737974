/**
 * NumberUtils
 * (c) lg2fabrique 2016
 *
 */

export default class NumberUtils {

    /**
     * Get a random for a particular range
     * @param {Number} min Minimum number
     * @param {Number} max Maximum number
     * @param {Boolean} [rounded=true] Makes sure the result will be an integer
     *
     * @return {Number}
     *   NumberUtils.random(2, 10);
     */
    public static random(min:number, max:number, rounded:boolean = true):number {
        var number = Math.random() * (max - min) + min;
        return rounded === true ? Math.round(number) : number;
    };

    /**
     * Get a random chance for a specific ratio
     * @param {Number} Change decimal (.5 = 50% of chance to return true)
     * @return {Boolean}
     *   NumberUtils.boolean(.5);
     */
    public static boolean(chance:number):boolean {
        return (Math.random() < chance);
    };

    /**
     * Get even validation
     * @param {Number}
     * @return {Boolean}
     *   NumberUtils.isEven(2);
     */
    public static isEven(value:number):boolean {
        return (value & 1) === 0;
    };

    /**
     * Get odd validation
     * @param {Number}
     * @return {Boolean}
     *   NumberUtils.isOdd(2);
     */
    public static isOdd(value:number): boolean {
        return !NumberUtils.isEven(value);
    };

    /**
     * Get interger validation
     * @param {Number}
     * @return {Boolean}
     *   NumberUtils.isInteger(2);
     */
    public static isInteger(value:number) :boolean {
        return (value % 1) === 0;
    };

    /**
     * Pad a number with a leading zero(s) to achieve a specific width
     * @param {Number} value Number to pad
     * @param {Number} [width=2] Length the returned number should have
     * @param {Number} [filler='0'] Character to fill the remaining width
     * @return {String} Padded number
     *   NumberUtils.leadingZero(9); => 09
     *   NumberUtils.leadingZero(500, 7); => 0000500
     */
    public static leadingZero(value:string | number, width:number = 2, filler:string = '0'):string {
        var str = value + '';
        return str.length >= width ? str : new Array(width - str.length + 1).join(filler) + str;
    };

    /**
     * Add thousand separators to a number
     * @param {Number} value that needs thousand separators
     * @param {String} [lang='fr'] Context language
     * @return {String} Separated number
     *   NumberUtils.thousandSeparators(1000000, 'en'); => '1,000,000'
     *   NumberUtils.thousandSeparators(55237, 'fr'); => '55 234'
     */
    public static thousandSeparators(value:number, lang:string = 'fr'):string {
        var sep = lang === 'fr' ? " " : ',';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    };

}