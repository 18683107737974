export class EventBus {
    //dictionary events list
    private static listeners: Object = {};

    /**
     * Add event listener to object
     * @param {string} type - Event's type
     * @param {Function} listener - Function
     */
    public static addListener(type: string, listener: Function) {
        if (EventBus.listeners[type] === undefined)
            EventBus.listeners[type] = [];
        if (EventBus.listeners[type].indexOf(listener) === -1) {
            EventBus.listeners[type].push(listener);
        }
    }

    /**
     * Validate event listener
     * @param {string} type - Event's type
     * @param {Function} listener - Function
     */
    public static hasListener(type: string, listener: Function) {
        if (EventBus.listeners[type] !== undefined) {
            if (EventBus.listeners[type].indexOf(listener) !== -1) {
                return true;
            }
        }
        return false;
    }

    /**
     * Remove event listener
     * @param {string} type - Event's type
     * @param {Function} listener - Function
     */
    public static removeListener(type: string, listener: Function) {
        let listenersList = EventBus.listeners[type];
        if (listenersList !== undefined) {
            let index = listenersList.indexOf(listener);
            if (index !== -1) listenersList.splice(index, 1);
        }
    }

    /**
     * Remove all events listener
     */
    public static removeAllListener() {
        EventBus.listeners = {};
    }

    /**
     * Dispatch a event
     * @param {any} event - Event to dispatch
     */
    public static dispatch(event: any) {
        let listenersList = EventBus.listeners[event.type];

        if (listenersList !== undefined) {
            let array = [];
            let length = listenersList.length;

            for (let i = 0; i < length; i++) {
                array[i] = listenersList[i];
                array[i].call(this, event);
            }
        }
    }
}
