/**
 * Facebook
 * (c) lg2fabrique 2016

 var facebook = new Facebook({
      appId      : 'your-app-id',
      xfbml      : true,
      version    : 'v2.8'
    },{

    //quand Facebook SDK est prêt
    ready:function(){},

    //quand l'utilisateur "like" via le bouton widget
    like:function(href){},

    //quand l'utilisateur "dislike" via le bouton widget
    removeLike:function(href){},

    //quand l'utilisateur ajoute un commentaire via le comment widget
    comment:function(href){},

    //quand l'utilisateur enlève un commentaire via le comment widget
    removeComment:function(href){},

    //quand l'utilisateur partage via le bouton widget
    share:function(){},

    //quand les widgets sont bien rendus et visibles dans la page
    xfbmlRender:function(){}

});
 */

import UrlUtils from "../utils/UrlUtils";
import DocumentUtils from "../utils/DocumentUtils";

declare var FB: any;

export default class Facebook {

    public static LANGUE        : string                 = 'fr_CA';

    private static _instance    : Facebook;

    public isReady              : boolean                = false;
    private _appid              : string;


    /**
     * Create new instance of Facebook - Work in progresse
     * @param {string} Application ID
     * @param {object} Callbacks functions actions
     *   var facebook = new Facebook({
            appId      : 'your-app-id',
            xfbml      : true,
            version    : 'v2.8'
         });
     */
    constructor(option:any, callbacks?:any){

        //singleton validation
        if(Facebook._instance) throw new Error('Error: Use Facebook.getInstance() instead of new.');
        Facebook._instance = this;

        this._appid = option.appId;

        (window as any).fbAsyncInit = function() {
            FB.init(option);
            console.info('FACEBOOK: ', this._appid);

            if(callbacks){
                if(typeof(callbacks.ready) === 'function') {
                    this.isReady = true;
                    callbacks.ready();
                }
                if(typeof(callbacks.like) === 'function') FB.Event.subscribe('edge.create', callbacks.like);
                if(typeof(callbacks.removeLike) === 'function') FB.Event.subscribe('edge.remove', callbacks.removeLike);
                if(typeof(callbacks.comment) === 'function') FB.Event.subscribe('comment.create', callbacks.comment);
                if(typeof(callbacks.removeComment) === 'function') FB.Event.subscribe('comment.remove', callbacks.removeComment);
                if(typeof(callbacks.share) === 'function') FB.Event.subscribe('message.send', callbacks.share);
                if(typeof(callbacks.xfbmlRender) === 'function') FB.Event.subscribe('xfbml.render', callbacks.xfbmlRender); 
            }
        }.bind(this);

        //sdk
        (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s); js.id = id;js.src = "//connect.facebook.net/" + Facebook.LANGUE + "/sdk.js";fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));
    }

    /**
     * Open window to the Facebook Sharer page
     * @param {String} Url to share
     * @param {Boolean} Open popup or _blank
     * @param {Number} Popup width
     * @param {Number} Popup height
     *   Facebook.sharer('http://www.lg2.com');
     */
    public static sharer(url:string, popup:boolean = true, width:number = 600, height:number = 400) {
        url = UrlUtils.query(url, {
            utm_source: 'facebook',
            utm_medium: 'social'
        });

        var link = 'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
        if(popup === true) DocumentUtils.openPopup(link, width, height);
        else window.open(link, '_blank');
    };

    /**
     * get singleton instance
     * @returns {Facebook}  instance's Facebook
     */
    public static getInstance(): Facebook {
        return Facebook._instance;
    }
}