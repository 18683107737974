import Module from '../Abstract/Module';
import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import Scroll from '../Events/Scroll';
import AnimationObserver from '../Factories/AnimationObserver';

declare const Expo;
declare const TweenMax;

export class ToolBar extends Module {

    private _facets : NodeListOf<HTMLElement>;
    private _handle : HTMLElement;
    private _currentFacet : HTMLElement;
    private _lastScrollTop: number = 0;
    private _lastTranslateValue: number = 0;
    private _scroll: Scroll;
    private _open : boolean = false;
    private _isSticky : boolean = true;
    private _isMobile = false;
    private _isCoveoInit = false;
    private _leftPannel;
    private _btnAll: HTMLElement;

    constructor(element: HTMLElement) {
        super(element);

        this._leftPannel = document.querySelector('.left-pannel');

        this._scroll = new Scroll();

        AnimationObserver.instance.observe([{
            selector: '[data-module="footer"]',
            once: false,
            onIntersect: this._onFooterIntsersect
        }])
        
        document.addEventListener('newResultsDisplayed', function(e) {
            this.initCoveo();
        }.bind(this));
        
        this.initBtnAll();

        this._updateMain();
        document.documentElement.classList.add('has-coveo-filters');
        
        // this.showElement();
        this._bindHandle();
    }

    @autobind
    private _onFooterIntsersect(entry, observer) {
        if (entry.isIntersecting) {
            this._isSticky = false;
            this._element.classList.add('is-not-sticky');
        } else {
            this._isSticky = true;
            this._element.classList.remove('is-not-sticky');
        }
    }

    @autobind
    private _onScroll(e) {
        if (!this._isSticky) return;
        
        const height = this._element.getBoundingClientRect().height;
        const windowHeight = window.innerHeight;
        const scrollTop = this._scroll.scrollTop;
        const heightDiff = windowHeight - height;
        const scrollDiff = this._lastScrollTop - scrollTop;

        this._lastScrollTop = scrollTop;

        let translateValue = this._lastTranslateValue + scrollDiff;
        
        if (translateValue <= heightDiff) {
                translateValue = heightDiff
        } else if (translateValue >= 0) {
            translateValue = 0;
        }

        this._lastTranslateValue = translateValue;

        TweenMax.set(this._element, {y: translateValue});
    }

    private initCoveo() {
        if(!this._isCoveoInit) {
            document.documentElement.classList.add('coveo-loaded');
            this.initFacetObserver();
            this._bindFacetsHeader();
            
            this._isCoveoInit = true;
        } else {
            // this._updateScene();
            if (this._btnAll) this._updateBtnAll()
        }
        
        
    }

    private initFacetObserver() {
        var facets = document.querySelectorAll('.CoveoFacet');

        var observer = new MutationObserver(function (event) {
            setTimeout(() => {
                //this._updateScene();
            },300);
        }.bind(this));

        for (let i = 0; i < facets.length; i++) {
            const facet = facets[i];

            observer.observe(facet, {
                attributes: true, 
                attributeFilter: ['class'],
                childList: false, 
                characterData: false
            });
        }

        // this._updateScene();
    }

    private _bindFacetsHeader() {
        let isSearch = document.querySelectorAll('.section.search').length > 0;

        this._facets = this._element.querySelectorAll('.CoveoFacet');
        for (let i = 0, l = this._facets.length; i < l; i++) {
            let facet = this._facets[i];

            if (!isSearch) {
                let facetHeader = facet.querySelector('.coveo-facet-header');
                if (facetHeader) facetHeader.addEventListener('click', this._onFacetHeaderClick);
            } else {
                this._openFacet(facet);
            }
                
        }
    }

    private _bindHandle() {
        this._handle = this._element.querySelector('[data-tool-bar-handle]');
        if (this._handle) this._handle.addEventListener('click', this._handleToggle);
    }

    @autobind
    private _handleToggle() {
        if (!this._open) {
            this._element.classList.add('is-open');
            document.documentElement.classList.add('overflow-is-hidden');
        }
        else {
            this._element.classList.remove('is-open');
            document.documentElement.classList.remove('overflow-is-hidden');
        }
        this._open = !this._open;
    }

    @autobind
    private _onFacetHeaderClick(e) {
        const facet:HTMLElement = e.currentTarget.parentElement;

        if (facet.classList.contains('is-open')) {
            this._closeFacet(facet)
        }
        else this._openFacet(facet);
    }

    private _openFacet(facet) {
        const facetValues = facet.querySelector('.coveo-facet-values');
        facet.classList.add('is-open');
        TweenMax.set(facetValues, {height:"auto"});
        TweenMax.from(facetValues, 0.2, {height:0, ease: Expo.easeOut, 
            onComplete: () => {
                this._currentFacet = facet;
                // this._updateScene();
            }
        });
        
    }

    private _closeFacet(facet, onComplete?) {
        const facetValues = facet.querySelector('.coveo-facet-values');
        facet.classList.remove('is-open');
        TweenMax.to(facetValues, 0.2, {height:0, ease: Expo.easeOut, onComplete: ()=> {
            // this._updateScene();
        }});
        
    }

    private initBtnAll() {
        this._btnAll = document.querySelector('[data-value="All"]');
        if(this._btnAll) this._btnAll.addEventListener('click', this._resetFilters);
        
    }
    private _updateBtnAll() {
        if (document.querySelectorAll('.coveo-selected').length > 0) {
            this._btnAll.classList.remove('coveo-selected');
        } else {
            this._btnAll.classList.add('coveo-selected');
        }
    } 

    @autobind
    private _resetFilters(e:Event) {
        if (this._btnAll.classList.contains('coveo-selected')) return;

        (document.querySelector('.coveo-facet-header-eraser') as HTMLElement).click();
    }
    


    private _updateMain() {
        let main: HTMLElement = document.querySelector('.main');
        main.classList.add('two-cols');
    }


    private _getDuration():number {
        let duration = (this._leftPannel.clientHeight + 130) - this._element.clientHeight;
        if(duration < 0)  duration = 0;

        return duration
    }

    public onGroupDesktop() {
        this._isMobile=false;
        document.addEventListener('scroll', this._onScroll);

    }
    
    public onGroupMobile() {
        this._isMobile=true;
        document.removeEventListener('scroll', this._onScroll);
        TweenMax.set(this._element, {clearProps: "transform"});
    }
    

}
