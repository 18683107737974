import { TransitionUtils } from './../vendors/frontools/src/utils/TransitionUtils';

import Module from '../Abstract/Module';
import TouchFeature from '../vendors/frontools/src/features/TouchFeature';
import EventType from '../vendors/frontools/src/events/EventType';
import Context from '../vendors/frontools/src/core/Context';

export class Tooltip extends Module {

    private _content: string;
    private _safeGutter: number = 30;
    private _tip: HTMLElement = null;
    private _isActive: boolean = false;
    private _template: string = `<div class="tooltip is-active"><div class="tooltip__content">[[CONTENT]]</div></div>`

    constructor(_element : HTMLElement) {
        super(_element);

        this._content = this._element.title;
        this._element.removeAttribute('title');

        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onClick = this._onClick.bind(this);
        this._dispose = this._dispose.bind(this);
        this._disposeTouch = this._disposeTouch.bind(this);

        TouchFeature.instance.addListener(EventType.INTERACTION_CHANGE, (e) => {
            this._init(e.isTouch);
        });
    }

    private _init(isTouch: boolean = Context.getInstance().isTouch()) {     
        this._dispose();

        if (isTouch) {
            this._element.addEventListener('click', this._onClick);
        } else {
            this._element.addEventListener('mouseenter', this._onMouseEnter);
            this._element.addEventListener('mousemove', this._onMouseMove);
            this._element.addEventListener('mouseleave', this._onMouseLeave);

            // accessibility
            this._element.addEventListener('focus', this._onMouseEnter);
            this._element.addEventListener('blur', this._onMouseLeave);
        }
    }

    private _onMouseEnter(e: MouseEvent) {
        // We prevent the touchstart/touchmove from triggering mouse events
        if (!Context.getInstance().isTouch()) {
            e.preventDefault();
            e.stopPropagation();
            this._create();
        }
    }

    private _onClick(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        this._create(true);
    }

    private _onMouseLeave(e: MouseEvent) {
        // We prevent the touchstart/touchmove from triggering mouse events
        if (!Context.getInstance().isTouch()) { 
            this._dispose();
        }
    }

    private _onMouseMove(e: MouseEvent) {
        return false;
    }

    private _create(fromTouch: boolean = false) {
        if (this._isActive) return;
        
        this._isActive = true;
        this._tip = document.createElement('div');
        this._tip.innerHTML = this._template.replace('[[CONTENT]]', this._content);
        this._tip = this._tip.firstElementChild as HTMLElement;
        document.body.appendChild(this._tip);

        this._position();
        document.addEventListener('click', this._dispose);
        
        if (fromTouch) {
            document.documentElement.classList.add('overflow-is-hidden');
            window.addEventListener('scroll', this._dispose);
        }
    }

    private _position() {
        let el = this._element;
        let tipWidth = this._tip.clientWidth;
        let tipHeight = this._tip.clientHeight;
        let elWidth = el.clientWidth;
        let vpWidth = window.innerWidth
        let elRect = el.getBoundingClientRect();
        
        let targetX = elRect.left + elWidth / 2 - tipWidth / 2; // centered
        let targetY = 0;
        let position = [];

        // vertical positioning
        if (elRect.top - tipHeight - 30 > 0) {
            position.push('top');
            targetY = elRect.top - tipHeight - this._safeGutter;
        } else {
            position.push('bottom');
            targetY = elRect.bottom + this._safeGutter;
        }

        // horizontal positioning
        if (elRect.left - tipWidth / 2 - this._safeGutter < this._safeGutter) {
            position.push('left');
            targetX = elRect.left;
        } else if (vpWidth - (elRect.right + tipWidth / 2 + this._safeGutter) < this._safeGutter) {
            position.push('right');
            targetX = elRect.right - tipWidth;
        }
        this._tip.dataset.position = position.join('-');
        
        this._tip.style.left = `${targetX}px`;
        this._tip.style.top = `${targetY}px`;
    }

    private _dispose() {
        if (this._isActive) {
            this._isActive = false;

            if (Context.getInstance().isTouch()) {
                this._tip.addEventListener(TransitionUtils.animationEnd(), this._disposeTouch);
                this._tip.classList.remove('is-active');
            } else {
                if (this._tip !== null) {
                    this._tip.parentElement.removeChild(this._tip);
                    this._tip = null;
                }
            }

            document.documentElement.classList.remove('overflow-is-hidden');

            document.removeEventListener('click', this._dispose);
            window.removeEventListener('scroll', this._dispose);
            // this._element.removeEventListener('click', this._onClick);
            // this._element.removeEventListener('mouseenter', this._onMouseEnter);
            // this._element.removeEventListener('mouseleave', this._onMouseLeave);
            // this._element.removeEventListener('mousemove', this._onMouseMove);

            // this._init();
        }
    }

    private _disposeTouch() {
        this._tip.removeEventListener(TransitionUtils.animationEnd(), this._disposeTouch);

        if (this._tip !== null) {
            this._tip.parentElement.removeChild(this._tip);
            this._tip = null;
        }
    }

}
