/**
    * YoutubePlaylist description
    * (c) lg2 2017
*/

import { RequestConfig, RequestEvent} from '../../data/Request';
import Request from '../../data/Request';
import AbstractDispatcher from '../../abstract/AbstractDispatcher';
import Template from '../../data/Template';

export default class YoutubePlaylist extends AbstractDispatcher {
    
    public static BASIC_HTML_TEMPLATE         : string                = `
        <div data-id="<% videoId %>">
            <h2><% title %></h2>
            <img src="<% thumbnails %>">
        </div>
    `
    private _playlistid                 : string;
    private _apikey                     : string;

    constructor(apikey:string, playlistid:string) {
        super();

        this._apikey = apikey;
        this._playlistid = playlistid;
    }

    private parseDataToBasicHtml(data){
        var items = data.items;
        var html = '';

        for(var i in items) {
            var item = items[i].snippet;

            if(item.thumbnails){
                html += new Template(YoutubePlaylist.BASIC_HTML_TEMPLATE, {
                    videoId : item.resourceId.videoId,
                    thumbnails : item.thumbnails.default.url,
                    title : item.title
                }).htmlAsString;
            }
        }

        return html;
    }

    private api(apiurl:string, type:YoutubePlaylistEvent){
        let config = new RequestConfig();
        config.url = apiurl + '&key=' + this._apikey;

        let request = new Request(config);
        
        request.addListener(RequestEvent.LOAD, (e) => {
            
            var response = JSON.parse(e.response);
            
            if(response.error) {
                this.dispatch({
                    type: YoutubePlaylistEvent.PLAYLIST_ERROR, 
                    error:  response
                });
            } else{
                if(type == YoutubePlaylistEvent.PLAYLIST_HTML) {
                    var html = this.parseDataToBasicHtml(response);
                    this.dispatch({
                        type: type, 
                        response: response,
                        html: html
                    });
                } else {
                    this.dispatch({
                        type: type, 
                        response: response
                    });
                }
            } 
        });

        request.addListener(RequestEvent.ERROR, (e) => {
            this.dispatch({type:YoutubePlaylistEvent.PLAYLIST_ERROR});
        });

    }

    public getPlaylistInfo(){
        this.api(
            'https://www.googleapis.com/youtube/v3/playlists?part=snippet&id=' + this._playlistid, 
            YoutubePlaylistEvent.PLAYLIST_INFO
        );
    }
    public getPlaylistData(max:number = 50){
        this.api(
            'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=' + max + '&playlistId=' + this._playlistid, 
            YoutubePlaylistEvent.PLAYLIST_DATA
        );
    }
    public getPlaylistHtml(max:number = 50) {
        this.api(
            'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=' + max + '&playlistId=' + this._playlistid, 
            YoutubePlaylistEvent.PLAYLIST_HTML
        );
    }
    
}

export class YoutubePlaylistEvent {
    public static PLAYLIST_INFO             = 'playlistinfo';
    public static PLAYLIST_DATA             = 'playlistdata';
    public static PLAYLIST_HTML             = 'playlisthtml';
    public static PLAYLIST_ERROR            = 'playlisterror';
}