
export class HeightUtils {
    private _vh: number;

    constructor() {
        window.addEventListener('resize', this._onResize.bind(this));
        this._onResize();
    }

    private _onResize(e?) {
        this._vh = window.innerHeight * 0.01;

        document.documentElement.style.setProperty('--vh', `${this._vh}px`);
    }
}
