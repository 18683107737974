
import { autobind } from "../vendors/frontools/src/decorators/Autobind";
import Module from '../Abstract/Module';

export class Footer  extends Module {

    private _contactNav : HTMLElement;
    private _contactNavWrapper : HTMLElement;

    constructor(_element : HTMLElement) {   
        super(_element, {intersection: {rootMargin:'0px'}});    

        this._contactNav = this._element.querySelector('[data-footer-contact-nav]');
        this.showElement();

        this._contactNav.addEventListener('mouseenter', this._onNavEnter.bind(this));
        this._contactNav.addEventListener('mouseleave', this._onNavLeave.bind(this));
        this._contactNav.addEventListener('click', this._onNavLeave.bind(this));
    } 
    
    @autobind
    protected _onIntersection(entries) {
        if (entries[0].isIntersecting) {
            this._onAfterReached();
        } else {
            this._onBeforeReached();
        }
    }

    private _onNavEnter(e) {
        if (this._contactNav) this._contactNav.classList.add('is-active');
    }

    private _onNavLeave(e) {
        if (this._contactNav) this._contactNav.classList.remove('is-active');
    }

    private _onAfterReached() {
        if (this._contactNav) this._contactNav.classList.add('is-absolute');
    }
  
    private _onBeforeReached() {
        if (this._contactNav) this._contactNav.classList.remove('is-absolute');
    }
}
