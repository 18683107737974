/**
 * HtmlMarker description
 * (c) lg2fabrique 2016
 *
var marker = new HtmlMarker({
    position: {lat: 48.79239019646408, lng: -67.159423828125},
    map: GoogleMapObject(map.getMap()), //google map object
    anchorX:30,
    anchorY:80,
    callbacks:{
        click:function(e, marker){},
        mouseover:function(e, marker){},
        mouseout:function(e, marker){},
        ready:function(marker){}
    },
    template: '<div>template</div>'
});
*/

/// <reference path="..//definitions/map/GoogleMap.d.ts" />
import LatLng = google.maps.LatLng;
import OverlayView = google.maps.OverlayView;
import ObjectUtils from "../utils/ObjectUtils";

export default class HtmlMarker {

    public static MAX_ZINDEX                : number            = 60000;

    private _overlay                         : any;                          //google map overlay object
    private _marker                          : HTMLElement;                  //google map overlay div holder
    private _latlng                          : LatLng;                       //geo position
    private _zindex                          : number;                       //marker z-index
    private _map                             : any;                          //google map object
    private _isRendered                      : boolean           = false;    //render state
    private _options                         : any               = {         //marker default options
        anchorX:0,
        anchorY:0,
        callbacks:{},
        template:'<div>default</div>',
        classname:'html-marker'
    };


    /**
     * Create a HtmlMarker
     * @param {Object} Marker options,
     */
    constructor(obj:any) {
        //google map validation
        if(!google.maps.OverlayView){
            throw new Error('GoogleMap need to be "ready" to use HtmlMarker');
        }

        // extends custom options
        ObjectUtils.extends(this._options, obj);


        //Appending basic CSS style
        if(!document.querySelector('#' + this._options.classname)){
            var head = document.head || document.getElementsByTagName('head')[0];
            var css = '.' + this._options.classname + ' *{pointer-events: none};';
            var div = document.createElement("div");
            div.innerHTML = '<p>x</p><style id="' + this._options.classname + '">' + css + '</style>';
            head.appendChild(div.childNodes[1]);
        }


        //create overlay
        this._overlay = new google.maps.OverlayView();
        if(obj.position instanceof google.maps.LatLng ) this._latlng = obj.position;
        else this._latlng = new google.maps.LatLng(obj.position.lat, obj.position.lng);

        //override default function
        this._overlay.draw = this.draw.bind(this);
        this._overlay.remove = this.removeOverlay.bind(this);

        //marker configuration
        this._overlay.latlng = this._latlng;
        this._zindex = Math.round((60 - this._latlng.lat()) * 10000); //z-index according to latitude


        if(!obj.map){
            throw new Error('map is not specified. HtmlMarker need to have a map. Can be set on constructor object.');
        }
        if(obj.map) {
            this._map = obj.map;
            console.log(obj.map)
            this._overlay.setMap(obj.map);
        }
    }

    /**
     *
     * Create marker to overlay
     */
    private setMarker(){
        //set base div
        this._marker = this._overlay.div = document.createElement('div');
        this._marker.className = this._options.classname;
        this._marker.style.position = 'absolute';

        //append template
        this._marker.innerHTML = this._options.template;

        //add marker to overlay
        var panes = this._overlay.getPanes();
        (panes as any).overlayImage.appendChild(this._marker);

        //add handler event
        for(var event in this._options.callbacks){
            this._marker.style.cursor = 'pointer';
            google.maps.event.addDomListener(this._marker, event, (function(marker, event) {
                return function(e) {
                    this._options.callbacks[event](e, this);
                }
            })(this._marker, event).bind(this));
        }

        this.setZIndex();
        this._isRendered = true;

        if(this._options.callbacks.ready) this._options.callbacks.ready(this);
    }

    /**
     * Render process - called when zoomIn/zoomOut
     */
    private draw(){
        //create marker
        this._marker = this._overlay.div;
        if (!this._marker) this.setMarker();

        //apply projection to marker
        var point = this._overlay.getProjection().fromLatLngToDivPixel(this._latlng);
        if (point) {
            //adjust position accoring to marker anchor
            this._marker.style.left = (point.x - this._options.anchorX) + 'px';
            this._marker.style.top = (point.y - this._options.anchorY) + 'px';
        }
    }

    /**
     * Override Remove - called after overlay.setMap(null) or this.remove();
     */
    private removeOverlay() {
        if(this._marker){
            this._marker.parentNode.removeChild(this._marker);
            this._isRendered = false;
            self = null;
        }
    };


    /**
     * Remove marker from a map
     */
    public remove(){
        if(this._overlay) this._overlay.setMap(null);
    };

    /**
     * Return DOM elemt of the marker
     */
    public getHtmlElement(){
        if(this._marker) return this._marker;
        else if(!this._isRendered) throw new Error('Marker is not rendered. Perhaps the marker is not ready, use it on ready callback of the marker.');
    };

    /**
     * Return latlng of the marker (google.maps.LatLng)
     */
    public getPosition(){
        return this._latlng;
    };

    /**
     * Return google map object
     */
    public getMap(){
        return this._map;
    };

    /**
     * Set z-index. If value is undefined, set to default z-index (according to latitude)
     */
    public setZIndex(value?){
        this._marker.style.zIndex = value || this._zindex;
    }

    /**
     * Get z-index.
     */
    public getZIndex(){
        return this._marker.style.zIndex;
    };

    /**
     * Set marker to the top of all markers
     */
    public showAtTop(){
        this.setZIndex(this._zindex + HtmlMarker.MAX_ZINDEX);
    };

    /**
     * Return marker's anchor, origin in pixels
     */
    public getAnchor(){
        return {x:this._options.anchorX, y:this._options.anchorY};
    };

    /**
     * Return render status
     */
    public isRendered(){
        return this._isRendered;
    };

    /**
     * Get self props
     */
    public get(key:string){
        return this[key];
    };

    /**
     * Set self props
     */
    public set(key:string, value:any){
        this[key] = value;
    }

}