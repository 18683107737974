/**
 * Decorator - Add deprecated notice
 * @param {string} Alternative to use
 */
export function deprecated(...args : any[]) {

    var alternative;

    function log(target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) {

        var original = descriptor && descriptor.value;
        if (typeof original !== 'function' || original == undefined) return;

        var message: string = '"{name}" is deprecated.', 
        message = message.replace('{name}', propertyKey);
        if(alternative) message += ' Use "' + alternative + '" instead.'

        descriptor.value = function() {
            console.warn(message);
            return original.apply(target, arguments);
        };

        return descriptor;
    }

    if(typeof args[0] == 'string' || args[0] == undefined){
        alternative = args[0];
        return log;
    }else log.apply(this, args);
}