import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import OverlayAbstract from '../Abstract/OverlayAbstract';
import Template from '../vendors/frontools/src/data/Template';
import Module from '../Abstract/Module';
import EventType from '../vendors/frontools/src/events/EventType';
import FormValidation, { FormEventType } from '../vendors/frontools/src/data/FormValidation';
import { RequestConfig, RequestMethod, RequestEvent } from '../vendors/frontools/src/data/Request';
import Request from '../vendors/frontools/src/data/Request';
import { Form } from './Form';
import { Tracker } from '../Events/Tracker';
import Breakpoint from '../vendors/frontools/src/core/Breakpoint';


declare const TweenMax;
declare const Expo;
declare const objectFitImages;

export class Overlay extends OverlayAbstract {
    private _mainTemplate: Template
    private _template : Template;
    private _overlayWrapper : HTMLElement;
    private _closeButton: HTMLElement;
    private _sections: NodeListOf<HTMLElement>;
    private _currentSection: HTMLElement;
    private _formValidator: FormValidation;

    private _navTools:NodeListOf<HTMLElement>;
    private _navMain:NodeListOf<HTMLElement>;
    private _rightPannelContents:NodeListOf<HTMLElement>;
    
    private _currentForm;

    constructor(protected _element: HTMLElement, protected _nextStepInUrl?: string) {
        super(_element);
        this._nextStepInUrl = _nextStepInUrl;
        this._templateId = this._element.getAttribute('data-overlay-id');

        this._element.addEventListener('click', this._onClick);

        
    }

    @autobind
    private _onClick(e) {
        e.preventDefault();
        this._init(); 
    }

    private _init() {

        this._mainTemplate = new Template(document.getElementById('overlay'), {});
        this._template = new Template(document.getElementById('overlay-'+this._templateId), {});

        this._overlayWrapper = document.createElement('div');
        this._overlayWrapper.innerHTML = this._mainTemplate.htmlAsString;

        const htmlElement = this._template.htmlAsString;
        this._overlayWrapper.querySelector('.main').innerHTML = htmlElement;

        document.body.appendChild(this._overlayWrapper);
        objectFitImages(this._overlayWrapper.querySelectorAll('.object-fit-cover'));

        this._currentSection = this._overlayWrapper.querySelector('[data-overlay-step].active');

        TweenMax.set(this._overlayWrapper.querySelectorAll('.header, .main'),{alpha:0});

        this.updateLayout();
        this.initGlobalActions();
        this.open();


        // if is contact overlay, change url
        if (this._templateId === 'contact') {
            this.updateUrl(this._element.getAttribute('href'));
        }
    }

    private _setLayoutContent(layoutId:string) {
        this._navTools = this._overlayWrapper.querySelectorAll('.nav--tools');
        this._navMain = this._overlayWrapper.querySelectorAll('.nav--main');
        this._rightPannelContents = this._overlayWrapper.querySelectorAll('.right-pannel-content');

        let mainTemplate = (document.querySelector('.nav--main[data-show-in="'+ layoutId +'"]')) ? layoutId : "default";
        let toolsTemplate = (document.querySelector('.nav--tools[data-show-in="'+ layoutId +'"]')) ? layoutId : "default";
        let rightPannelTemplate = (document.querySelector('.right-pannel-content[data-show-in="'+ layoutId +'"]')) ? layoutId : "default";
        
        for (let i = 0; i < this._navTools.length; i++) {
            const nav = this._navTools[i];
            if(nav.getAttribute('data-show-in').indexOf(toolsTemplate)!=-1) {
                nav.classList.add('show')
            }
            else  nav.classList.remove('show')
        }
        for (let j = 0; j < this._navMain.length; j++) {
            const nav = this._navMain[j];
            if(nav.getAttribute('data-show-in').indexOf(mainTemplate)!=-1) nav.classList.add('show')
            else  nav.classList.remove('show')
        }
        for (let j = 0; j < this._rightPannelContents.length; j++) {
            const nav = this._rightPannelContents[j];
            if(nav.getAttribute('data-show-in').indexOf(rightPannelTemplate)!=-1) nav.classList.add('show')
            else  nav.classList.remove('show')
        }
    }

    private initGlobalActions() {
        const actions:NodeListOf<HTMLElement> = this._overlayWrapper.querySelectorAll('.right-pannel [data-overlay-next-step], .nav--main [data-overlay-next-step]');
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            action.addEventListener('click', this._handleNextStep);
        }

        let closeBtns = this._overlayWrapper.querySelectorAll('[data-js="button-close"]');
        for (let j = 0; j < closeBtns.length; j++) {
            const btn = closeBtns[j];
            btn.addEventListener('click', this._onClose);
        }

        

    }

    @autobind
    private _onClose() {
        this.close();
    }

    private _initActions() {
        
        const actions:NodeListOf<HTMLElement> = this._currentSection.querySelectorAll('[data-overlay-next-step]');
        
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            
            switch(action.getAttribute('data-overlay-next-step')) {
                case "validation":
                    // this._initformValidator();
                    if(this._currentForm) {
                        this._currentForm.removeListener(Form.FORM_SENT, this._handleNextStep);
                        this._currentForm.clear();
                    }
                    this._currentForm  = new Form(this._currentSection.querySelector('form'), this._overlayWrapper);
                    this._currentForm.setUrl(this._currentSection.getAttribute('data-ajax-url'));
                    this._currentForm.name = this._templateId;
                    this._currentForm.open();
                    this._currentForm.addListener(Form.FORM_SENT, this._handleNextStep);
                    break;
                default:
                    action.addEventListener('click', this._handleNextStep);
                    break;   
            }
        }
    }

    @autobind
    private _handleNextStep(e?) {
        console.log("handle next step")
        let next:string = "";
        if(e.preventDefault) {
            e.preventDefault();
            next = e.currentTarget.getAttribute('data-overlay-next-step')
        } else {
            next= (e.success) ? this._currentSection.getAttribute('data-next-thanks-step') : this._currentSection.getAttribute('data-next-sorry-step')
        }

        let nextSection: HTMLElement = this._overlayWrapper.querySelector('[data-overlay-step="' + next + '"]');

        this._currentSection.classList.remove('active');
        this._currentSection = nextSection;
        this._currentSection.classList.add('active');

        const overlay = this._overlayWrapper.querySelector('.overlay');
        let scrollingElement = Breakpoint.getInstance().isInGroup('group_mobile') ? overlay.querySelector('main') : overlay;
        TweenMax.to(scrollingElement, .5, {scrollTo: {y:0 }});
        
        this.updateLayout();
        this._initActions();

        // if is contact overlay, change url
        if (next === 'work-with' || next === 'work-for') {
            this.updateUrl(e.currentTarget.getAttribute('href'));
        }

    }

    public close() {
        
        TweenMax.to(this._overlayWrapper.querySelector('.header'), .5, {alpha: 0, ease: Expo.easeOut });
        TweenMax.to(this._overlayWrapper.querySelector('.main'), .5, {alpha: 0, ease: Expo.easeOut});

        TweenMax.delayedCall(.3, ()=> {
            super.close();
            this._overlayWrapper.classList.remove('overlay-is-open')
        });

        TweenMax.delayedCall(.8, ()=> {
            this._currentForm = null;
            this._overlayWrapper.parentElement.removeChild(this._overlayWrapper);
        });

        // if is contact overlay, change url
        if (this._templateId === 'contact') {
            this.updateUrl();
        }
    }

    public open() {
        TweenMax.to(this._overlayWrapper.querySelector('.header'), .5, {alpha: 1, ease: Expo.easeOut, delay: .5 });
        TweenMax.to(this._overlayWrapper.querySelector('.main'), .5, {alpha: 1, ease: Expo.easeOut, delay: .6});
        TweenMax.delayedCall(.3, ()=> {
            this.dispatch(new EventType(Module.DOM_CHANGE, {elements: [this._overlayWrapper]}));
        });
        
        this._initActions();
        this._overlayWrapper.classList.add('overlay-is-open');

        // if # in url 
        if(this._nextStepInUrl) {
            console.log('coucou le next step in url')
            let nextStepCta = this._overlayWrapper.querySelector('[data-overlay-next-step="'+this._nextStepInUrl+'"]')as HTMLLinkElement;
            console.log( nextStepCta)

            nextStepCta.click();
        }
        
        super.open();
    }

    public updateLayout() {
        let layoutId = this._templateId;
        if(this._currentSection.getAttribute('data-layout-id')) {
            layoutId = this._currentSection.getAttribute('data-layout-id')
            this._setLayoutContent(layoutId);
        }


        
    }

    public updateUrl(urlParam?) {
        if(urlParam) {
            window.location.href = window.location.href.split('#')[0] + urlParam;
        } else {
            window.location.href = window.location.href.split('#')[0];
        }
    }
    
    
    
}