/**
 * AnimManager
 * 
 * Launches animation when element is inview
 * 
 * Dependencies : TweenMax, Tweenmax SplitText for specific anim
 * 
 * (c) lg2fabrique 2017
    
    // Default
        this._animManager = new AnimManager();

    // Custom
        var options = new AnimManagerOptions();
        options.selector = '[data-inview]';
        options.inviewAttribute = 'data-inview';
        options.delayAttribute = 'data-inview-delay';
        options.speedAttribute = 'data-inview-speed';
        this._animManager = new AnimManager(options);

    // Available values for inviewAttribute :
        fromBottom
        fromLeft
        fromRight
        fade
        lineByLine
        charByChar
        3dFromLeft
        count

    // Usage
        <section data-inview="fromBottom" data-inview-speed="4" data-inview-delay="1">
            ...
        </section>

    // Create custom animation
        Throws an exception if name is already used or customFunction is not a function
        this._animManager.addCustomAnimation('fromBottomCustom', (e:AnimManagerEvent) => {
            TweenMax.from(e.target, 1, {delay: e.delay + (e.animationQueueIndex * .1), y: 100, x: 100, alpha: 0, ease: Expo.easeOut, onCompleteParams: [e.target]});
        })

        // Template :

        <section data-inview="fromBottomRightCustom">
             ...
        </section>
*/

import AbstractDispatcher from '../abstract/AbstractDispatcher';
import Context from '../core/Context';
import ScrollUtils from '../utils/ScrollUtils';
import NumberUtils from '../utils/NumberUtils';
import { debounce } from '../decorators/Debounce';
import { autobind } from '../decorators/Autobind';

declare var SplitText: any;
declare var TweenMax: any;
declare var Expo: any;

export default class AnimManager extends AbstractDispatcher {
    
    private _items                          : NodeList;
    private _animHashMap                    : {}                   = {};
    private _animTimeout;
    private _animQueue                      : any[]                = [];
    private _lang                           : String               = Context.getInstance().language;
    private _registered                     : HTMLElement[]        = [];

    constructor(private _options:AnimManagerOptions = new AnimManagerOptions()) {
        super();

        this._items = document.querySelectorAll(this._options.selector); 

        this._createAnimsHashMap();
        this._parseElements();

        window.addEventListener('resize', this._onResize); 
    }


    private _parseElements() {
        for (let i = 0, l = this._items.length; i < l; i++) {
            var ele = this._items[i] as HTMLElement;

            ScrollUtils.registerElement(ele, {
                offset: Context.getInstance().viewport.height - 100,
                before: function(el) {}.bind(this),
                after: function(el) {
                    this._anim(el);
                }.bind(this)
            });

            // We save the registerd element
            this._registered.push(ele);

            ele.style.visibility = 'hidden';

            ScrollUtils.triggerEventOf(window);
        }
    }
    
    private _createAnimsHashMap() {
        this._animHashMap['fromBottom'] = (e:AnimManagerEvent) => {
            TweenMax.from(e.target, 1, {delay: e.delay + (e.animationQueueIndex * .1), y: 50, alpha: 0, ease: Expo.easeOut, onComplete: this._clear, onCompleteParams: [e.target]});
        }
        this._animHashMap['fromRight'] = (e:AnimManagerEvent) => {
            TweenMax.from(e.target, 1, {delay: e.delay + (e.animationQueueIndex * .1), x: 50, alpha: 0, ease: Expo.easeOut, onComplete: this._clear, onCompleteParams: [e.target]});
        }
        this._animHashMap['fromLeft'] = (e:AnimManagerEvent) => {
            TweenMax.from(e.target, 1, {delay: e.delay + (e.animationQueueIndex * .1), x: -50, alpha: 0, ease: Expo.easeOut, onComplete: this._clear, onCompleteParams: [e.target]});
        }
        this._animHashMap['fade'] = (e:AnimManagerEvent) => {
            TweenMax.from(e.target, 1, {delay: e.delay + (e.animationQueueIndex * .1), alpha: 0, ease: Expo.easeOut, onComplete: this._clear, onCompleteParams: [e.target]});
        }
        this._animHashMap['lineByLine'] = (e:AnimManagerEvent) => {
            var split = new SplitText(e.target, {type: 'lines'});                
            TweenMax.staggerFrom(split.lines, .8, {delay: e.delay + (e.animationQueueIndex * .1), alpha: 0, x: 40, ease: Expo.easeOut, onComplete: this._clearSplit, onCompleteParams: [e.target, split]}, .1);
        }
        this._animHashMap['charByChar'] = (e:AnimManagerEvent) => {
            var split = new SplitText(e.target, {type: 'chars'});
            TweenMax.staggerFrom(split.chars, .8, {delay: e.delay + (e.animationQueueIndex * .01), alpha: 0, ease: Expo.easeOut, onComplete: this._clearSplit, onCompleteParams: [split]}, .1);
        }
        this._animHashMap['3dFromLeft'] = (e:AnimManagerEvent) => {
            TweenMax.set(e.target, {backfaceVisibility: 'hidden', transformPerspective: 200, transformStyle: 'preserve-3d'});                    
            TweenMax.from(e.target, 1, {delay: e.delay + (e.animationQueueIndex * .1), rotationY: -180, ease: Expo.easeOut, onComplete: this._clear, onCompleteParams: [e.target]});
        }
        this._animHashMap['count'] = (e:AnimManagerEvent) => {
            var value = parseInt(e.target.innerHTML, 10),
                counter = {num: 0};

            TweenMax.to(counter, e.speed, {
                num: value,
                onUpdate: function() {
                    let val = NumberUtils.thousandSeparators(Math.ceil(counter.num), this._lang);
                    e.target.innerText = String(val);
                }.bind(this),
                ease: Expo.easeOut,
                onComplete: this._clear,
                onCompleteParams: [e.target]
            });
        }
       
    }

    private _anim(ele: HTMLElement) {
        var type = ele.getAttribute(this._options.inviewAttribute);

        // We make sure to unregister element from scroll listener
        ScrollUtils.removeRegistredElement(ele);
        let indexOf = this._registered.indexOf(ele);
        this._registered.splice(indexOf, 1);

        // We make sure to never trigger the animation again
        if (this._options.removeAttribute) {
            ele.removeAttribute('data-inview');
        } else {
            ele.setAttribute(this._options.inviewAttribute, 'true');
        }
        var delayAttr = ele.getAttribute(this._options.delayAttribute);
        var speedAttr = ele.getAttribute(this._options.speedAttribute);
        var delay = delayAttr ? parseInt(delayAttr, 10) : 0;
        var speed = speedAttr ? parseInt(speedAttr, 10) : 3;

        var anim = function(i) {
            var event = new AnimManagerEvent(i,ele,delay,speed);
            this._animHashMap[type](event);  
            ele.style.visibility = 'visible';
        }.bind(this);


        this._animQueue.push(anim);
        this._launchAnimation();
    };

    private _clear(ele: HTMLElement, props: string = 'x, y, opacity, visibility') {
        TweenMax.set(ele, {clearProps: props});
    };

    private _clearSplit(ele: HTMLElement, split) {
        split.revert();
        //TweenMax.set(ele, {clearProps: 'visibility'});
    }

    private _launchAnimation() {
        clearTimeout(this._animTimeout);

        this._animTimeout = setTimeout(function() {
            var i = 0;
            while (this._animQueue.length > 0) {                
                this._animQueue.shift().call(this, i);
                i++;
            }
        }.bind(this), 10);
    };

    /**
     * 
     * Add custom animation to Animations Hash map
     * 
     * @param {String} name The name of the new custon animation used in template
     * @param {Function} customFunction The custom Function called with new animation 
     *                  Supplied event params are 
     *                  - i (index in the animation queue), 
     *                  - ele (element to animate)
     *                  - delay (delay given in template or default)
     *                  - speed (speed given in template or default)
     */
    public addCustomAnimation(name:string, customFunction:Function) {
        if (this._animHashMap[name]) throw('This animation name is already taken. Please use another');
        if (!customFunction || {}.toString.call(customFunction) !== '[object Function]') throw('Custom animation is not a function');
        this._animHashMap[name] = customFunction;
    }
    
    @autobind
    @debounce(200)
    private _onResize() {
        for (let i = 0, l = this._items.length; i < l; i++) {
            var ele = this._items[i] as HTMLElement;
            ScrollUtils.registerElement(ele, {});
        }
    };

    public destroy() {
        for (let i = 0, l = this._registered.length; i < l; i++) {
            ScrollUtils.removeRegistredElement(this._registered[i]);
        }
        window.removeEventListener('resize', this._onResize); 
    }

}

export class AnimManagerOptions {

    public static DEFAULT_INVIEW_ATTRIBUTE      : string                        =   'data-inview';
    public static DEFAULT_DELAY_ATTRIBUTE       : string                        =   'data-inview-delay';
    public static DEFAULT_SPEED_ATTRIBUTE       : string                        =   'data-inview-speed';

    private _selector                           : string                        = '['+AnimManagerOptions.DEFAULT_INVIEW_ATTRIBUTE+']';
    private _inviewAttribute                    : string                        = AnimManagerOptions.DEFAULT_INVIEW_ATTRIBUTE;
    private _delayAttribute                     : string                        = AnimManagerOptions.DEFAULT_DELAY_ATTRIBUTE;
    private _speedAttribute                     : string                        = AnimManagerOptions.DEFAULT_SPEED_ATTRIBUTE;
    private _removeAttribute                    : boolean                       = false;

    public set selector(value: string) { this._selector = value; }
    public set inviewAttribute(value: string) {
        this._inviewAttribute = value;
    }
    public set delayAttribute(value: string) {
        this._delayAttribute = value;
    }
    public set speedAttribute(value: string) {
        this.speedAttribute = value;
    }
    public set removeAttribute(value: boolean) {
        this._removeAttribute = value;
    }

    public get selector() : string {
        return this._selector;
    }
    public get inviewAttribute() : string {
        return this._inviewAttribute;
    }
    public get delayAttribute() : string {
        return this._delayAttribute;
    }
    public get speedAttribute() : string {
        return this._speedAttribute;
    }
    public get removeAttribute() : boolean {
        return this._removeAttribute;
    }

}

export class AnimManagerEvent {

    public constructor(
        private _animationQueueIndex: number, 
        private _target: HTMLElement, 
        private _delay: number, 
        private _speed:number) 
        {}

    public get animationQueueIndex() : number {
        return this._animationQueueIndex;
    }
    public get target() : HTMLElement {
        return this._target;
    }
    public get delay() : number {
        return this._delay;
    }
    public get speed() : number {
        return this._speed;
    }

}
