import Module from "./Module";
import KeyCode from "../vendors/frontools/src/events/KeyCode";
import { autobind } from '../vendors/frontools/src/decorators/Autobind';

declare const TweenMax;

export default class Overlay extends Module {

    protected _opened       : boolean = false;
    
    private _toggleButton : HTMLButtonElement;

    constructor(_element) {
        super(_element);

        this._toggleButton = this._element.querySelector('[data-overlay-toggle]');

        if (this._toggleButton) {
            this._toggleButton.addEventListener('click', this._onToggleClick);
        }

        document.addEventListener('keyup', this._onKeyUp);
    }

    public destroy() {
        this.close();
    }

    @autobind
    private _onToggleClick(e) {
        // TODO : move this to Button class so it can be use by all buttons
        TweenMax.fromTo(this._toggleButton, 0.1,{scale: 1}, {scale: 1.1, yoyo: true, repeat:1});

        if (!this._opened) {
            this.open();
        } else {
            this.close();
        }
    }

    @autobind
    private _onKeyUp(e) {
        if (e.keyCode == KeyCode.ESC) {
            this.close();
        }
    }
    
    public open(addClass = true) {
        this._opened = true;
        document.documentElement.classList.add('overflow-is-hidden');
        if (addClass) this._addOpenedClass();
    }
    
    public close(removeClass = true) {
        this._opened = false;
        document.documentElement.classList.remove('overflow-is-hidden');
        if (removeClass) this._removeOpenedClass();
    }   

    protected _removeOpenedClass(element = this._element) {
        element.classList.remove('is-opened')
    }

    protected _addOpenedClass(element = this._element) {
        element.classList.add('is-opened')
    }

}