/**
 * LazyLoadManager
 * 
 * Dependencies : TweenMax for fadeIn animation
 * 
 * (c) lg2fabrique 2017
    
    // Default
        this._lazyLoadManager = new LazyLoadManager();   

    // Custom
        var options = new LazyLoadManagerOptions();
        options.offset = 200;
        options.selector = '[data-lazy-load]';
        options.sourceAttribute = 'data-src';
        this._lazyLoadManager = new LazyLoadManager(options); 

    // Adding listener to image loading (returns the image)
        this._lazyLoadManager.addListener(LazyLoadManager.IMAGE_LOADED, (e) => {
            console.log(e.image)    
        })

    // To parse new elements, use public function parseElements
        let elements = this._elGrid.querySelectorAll(this._lazyLoadManager.options.selector) as NodeListOf<HTMLElement>;
        this._lazyLoadManager.parseElements(elements);   

    // Layout - Standard
        <div data-lazy-load>
            <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="[image url]"/>
        </div>

    // Layout - Twig Craft (Sets image container width and height and background color to dominant image color)
        
        Dependencies : Craft's Image Color plugin
        
        <div style="background: {{ image|imageColor }}; padding-bottom: {{ image.height / image.width * 100 }}%;"  data-lazy-load>
            <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="{{ image.getUrl() }}" height="{{ image.height }}" width="{{ image.width }}"/>
        </div>

    // OnInit and OnLoad are protected, extend this class to manage image animations yourself
    import LazyLoadManager, { LazyLoadManagerOptions } from 'frontools/medias/image/LazyLoadManager';

    export default class TestLazyLoadManager extends LazyLoadManager {

        protected _onImageAnimateIn(e) {
            this.dispatch({type:LazyLoadManager.IMAGE_LOADED, image: e.currentTarget});
            TweenMax.to(e.currentTarget, 1, {scale:1, ease: Expo.easeOut});
        }

        protected _onImageInit(image:HTMLImageElement) {
            TweenMax.set(image, { scale: 2});   
        }
    
}


*/

import AbstractDispatcher from '../../abstract/AbstractDispatcher';
import ScrollUtils from '../../utils/ScrollUtils';
import Context from '../../core/Context';
import { autobind } from '../../decorators/Autobind';

declare var TweenMax:any;
declare var Expo:any;

export default class LazyLoadManager extends AbstractDispatcher {

    public static IMAGE_LOADED                  : string                        = 'image_loaded';
    private _registered                         : HTMLElement[]                 = [];

    private _elItems                            : NodeListOf<HTMLElement>;

    /**
     * 
     * @param _options : See LazyLoadManagerOptions
     */
    constructor(private _options : LazyLoadManagerOptions = new LazyLoadManagerOptions()) {
        super();

        this._elItems = document.querySelectorAll(this._options.selector) as NodeListOf<HTMLElement>;
        
        this.parseElements();
    };

    /**
     * Parsing dom elements to fill items array and binding events
     */
    public parseElements(elItems : NodeListOf<HTMLElement> = this._elItems) {
        for (let i = 0, l = elItems.length; i < l; i++) {
            let ele = elItems[i] as HTMLElement;
            let image = (ele.nodeName == "IMG") ? ele as HTMLImageElement : ele.querySelector('img') as HTMLImageElement;
            
            this._onImageInit(image);   

            ScrollUtils.registerElement(ele, {
                offset: Context.getInstance().viewport.height - this._options.offset,   
                after: (el) => {
                    image.onload = this._onImageLoad;
                    this._load(image);

                    //removes registered element
                    ScrollUtils.removeRegistredElement(ele);
                    let indexOf = this._registered.indexOf(ele);
                    this._registered.splice(indexOf, 1);
                }
            });

            // We save the registered element
            this._registered.push(ele);

            ScrollUtils.triggerEventOf(window);
        }
    }

    /**
     * Loads image when scrooling to element
     * @param image 
     */
    private _load(image:HTMLImageElement) {
        let source = image.getAttribute(this._options.sourceAttribute),
            sourceSet = image.getAttribute(this._options.sourceSetAttribute);
        if (source) image.src = source;
        if (sourceSet) image.srcset = sourceSet;
        
    }

    /**
     * When image has loaded, fading in effect and dispatching event
     * @param image 
     */
    @autobind
    private _onImageLoad(e:any) {
        this.dispatch({type:LazyLoadManager.IMAGE_LOADED, image: e.currentTarget});
        this._onImageAnimateIn(e.currentTarget);
    }

    protected _onImageAnimateIn(image:HTMLImageElement) {
        TweenMax.to(image, 1, {opacity:1, ease: Expo.easeOut });
    }

    public destroy() {
        for (let i = 0, l = this._registered.length; i < l; i++) {
            ScrollUtils.removeRegistredElement(this._registered[i]);
        }
    }

    /**
     * When initilializing image before load    
     * @param image 
     */
    protected _onImageInit(image:HTMLImageElement) {
        TweenMax.set(image, {opacity:0});   // Setting image element to 0 opacity for fading effect
    }

    public get options() : LazyLoadManagerOptions {
        return this._options;
    }
}

export class LazyLoadManagerOptions {
    
    private _selector           : string = '[data-lazy-load]';
    private _sourceAttribute    : string = 'data-src';
    private _sourceSetAttribute : string = 'data-src-set';
    private _offset             : number = 200;
  
    /**
     * @param _selector : Selector used to get lazy load parent of image elements
     * @param _offset : Offset for scroll image loading
     * @param _sourceAttribute : Source attribute on the img tag
     */

    public set selector(selector: string) {
        this._selector = selector;
    }
    public set sourceAttribute(sourceAttribute: string) {
        this._sourceAttribute = sourceAttribute;
    }
    public set offset(offset: number) {
        this._offset = offset;
    }

    public get selector() : string {
        return this._selector;
    }
    public get sourceAttribute() : string {
        return this._sourceAttribute;
    }

    public get sourceSetAttribute(): string {
        return this._sourceSetAttribute
    }
    public get offset() : number {
        return this._offset;
    }
}