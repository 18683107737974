import { autobind } from 'vendors/frontools/src/decorators/Autobind';

export default class Scroll {
    private static _instance: Scroll;
    private _scrollPosition: number = 0;
    private _scrollingElement: Element;
    private _lastScrollPosition: number = 0;

    constructor() {
        //singleton validation
        if (Scroll._instance)
            throw Error('Scroll Error: Use Scroll.instance instead of new.');
        Scroll._instance = this;

        this._scrollingElement = document.scrollingElement || document.documentElement;

        window.addEventListener('scroll', this.onScroll);
    }

    @autobind
    private onScroll(e: Event) {
        this._lastScrollPosition = this._scrollPosition;
        this._scrollPosition = this._scrollingElement.scrollTop;
        
        if (this._scrollPosition > 0) {
            document.documentElement.classList.add('has-scrolled-down');
        } else {
            document.documentElement.classList.remove('has-scrolled-down');
        }
    }

    public get direction() {
        return this._scrollPosition >= this._lastScrollPosition ? 1 : -1;
    }

    /**
     * get singleton instance
     * @returns {Breakpoint}  instance's Breakpoint
     */
    public static get instance(): Scroll {
        if (typeof this._instance === 'undefined') {
            throw Error(
                'Scroll error: Scroll has to be instantiated first via new Scroll();'
            );
        }
        return this._instance;
    }

    public get scrollTop(): number {
        return this._scrollingElement.scrollTop;
    }
}
