/**
 * GooglePlus
 * (c) lg2fabrique 2016
 *
 */

import DocumentUtils from '../utils/DocumentUtils';
import UrlUtils from '../utils/UrlUtils';

export default class GooglePlus {

    private static _instance: GooglePlus;

    public static LANGUE = 'fr-CA';

    /**
     * constructor
     */
    constructor() {
        //singleton validation
        if(GooglePlus._instance) throw new Error('Error: Use GooglePlus.getInstance() instead of new.');
        GooglePlus._instance = this;

        (window as any).___gcfg = {lang: GooglePlus.LANGUE};
        (function() {var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;po.src = '//apis.google.com/js/platform.js';var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);})();
    }

    /**
     * Open window to the GooglePlus Sharer page
     * @param {String} Url to share
     * @param {Boolean} Open popup or _blank
     * @param {Number} Popup width
     * @param {Number} Popup height
     *   GooglePlus.sharer('http://www.lg2.com');
     */
    public static sharer(url:string, popup:boolean = true, width:number = 600, height:number = 400) {
        url = UrlUtils.query(url, {
            utm_source: 'google',
            utm_medium: 'social'
        });

        var link = 'https://plus.google.com/share?url=' + encodeURIComponent(url);
        if(popup === true) DocumentUtils.openPopup(link, width, height);
        else window.open(link, '_blank');
    };

    /**
     * get singleton instance
     * @returns {GooglePlus}  instance's GooglePlus
     */
    public static getInstance(): GooglePlus {
        return GooglePlus._instance;
    }

}