/**
 * EventType
 * (c) lg2fabrique 2016
 *
 */

export default class EventType {
    public static CHANGE                = 'change';
    public static CLEAR                 = 'clear';
    public static CLOSE                 = 'close';
    public static COMPLETE              = 'complete';
    public static CLICK                 = 'click';
    public static DOM_READY             = 'domready';
    public static ERROR                 = 'error';
    public static KEY_DOWN              = 'click';
    public static INTERACTION_CHANGE    = 'interaction_change';
    public static MOUSE_MOVE            = 'mousemove';
    public static OPEN                  = 'open';
    public static READY                 = 'ready';
    public static RENDER                = 'render';
    public static RESIZE                = 'resize';
    public static START                 = 'start';
    public static STOP                  = 'stop';
    public static TOUCH_START           = 'touchstart';
    public static TOUCH_END             = 'touchend';
    public static UPDATE                = 'update';
    public static WIN_LOAD              = 'winload';

    private _target         = null;

    constructor(
        private _type:string,
        private _data?:Object) {
    }

    public get type():string {
        return this._type;
    }
    public get data():any {
        return this._data;
    }

    public set target(value) {
        this._target = value;
    }
    public get target() {
        return this._target;
    }
}