
/**
 * Form (validator and serializer)
 * (c) lg2fabrique 2017
    

    Supporte les balises suivantes:
        input, checkbox, radio, select, textarea

    Supporte (testé) les types de input suivants:
        text, number, password, email, tel

    Ne supporte pas (pas testé) ces types:
        color, date, datetime, datetime-locale, month, range, search, time, url, week

    Pour tous les types de input non supportés, la validation de type "text" sera appliquée sur le champs.


    //HTML
    <form name="form" class="form" action="" method="get">
        <input name="text" type="text"></input>
        <input name="age" type="number"></input>
        <input name="mail" type="email" value="sfsdf@sdf"></input>
        <input name="check1" type="checkbox" value="check1">check1
        <input name="check1" type="checkbox" value="check2">check2

        <input name="check3" type="checkbox" value="check1" data-group="checkgroup" required>check3
        <input name="check4" type="checkbox" value="check2" data-group="checkgroup">check4

        <input name="radio" type="radio" value="radio1">radio1
        <input name="radio" type="radio" value="radio2">radio2
        <textarea name="textarea"></textarea>
        <select name="select" required>
            <option value="">Faites un choix</option>
            <option value="choix1">choix1</option>
            <option value="choix2">choix2</option>
        </select>
        <select multiple name="select" required>
            <option value="">faires un choix</option>
            <option value="choix1">choix1</option>
            <option value="choix2">choix2</option>
        </select>
        <button type="submit" class="button">envoyer</button>
    </form>


    //JAVASCRIP
    var form = new FormValidation(document.querySelector('.form') as HTMLFormElement);    
    form.addListener(FormEventType.ERROR, function(e) {
        console.log(e.errors)
    }.bind(this));
    form.addListener(FormEventType.SEND, function(e) {
        //form is valid, but if you wanna add something, this line stop the form submit
        e.submit.preventDefault();
    }.bind(this));


    //or


    var form = new FormValidation(document.querySelector('.form') as HTMLFormElement);
    var errors:Array<any> = form.validate();
    if(errors.length > 0) console.log(errors);
    else console.log('send');

*/
import StringUtils from '../utils/StringUtils';
import AbstractDispatcher from '../abstract/AbstractDispatcher';
import VanillaUtils from '../utils/VanillaUtils';

export default class FormValidation extends AbstractDispatcher{

    private _el                 : HTMLFormElement;
    private _fields             : Array<any>                = [];
    private _requires           : Array<any>                = [];
    private _radiogroups        : any                       = {};
    private _checkboxgroups     : any                       = {};
    
    /**
     * constructor
     * * @param {HTMLFormElement} Form element from DOM
     */
    constructor(form:HTMLFormElement) {
        super();

        this._el = form;
        this._el.setAttribute('novalidate', 'true');
        this._el.onsubmit = this.onValidateHandler.bind(this);

        //add ie11 fix for XHR request
        if(!this._el.hasfix) {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = '_iefix';
            this._el.appendChild(input);
            this._el.hasfix = true;
        }

        for (var i = 0; i < this._el.elements.length; i++) {

            //get all form elements
            var element:any = this._el.elements[i];
            switch(element.nodeName) {
                case FormElement.INPUT:
                case FormElement.TEXTAREA:
                case FormElement.SELECT:
                    switch(element.type) {
                        case FormElementType.CHECKBOX:
                        case FormElementType.RADIO:
                            VanillaUtils.addEvent(element, 'change', this.onFieldChange.bind(this));
                            break;
                        default:
                            VanillaUtils.addEvent(element, 'input', this.onFieldChange.bind(this));
                    }
                    
                    if(element.nodeName == FormElement.TEXTAREA){
                        VanillaUtils.addEvent(element, 'input', this.onFieldChange.bind(this));
                    }

                    if(element.nodeName == FormElement.SELECT){
                        VanillaUtils.addEvent(element, 'change', this.onFieldChange.bind(this));
                    }
                    if(element.type != "hidden") this._fields.push(element);
                break;
            }

            //get all required elements
            //remove default browser required style
            if (element.getAttribute('data-val-required')) {
                this._requires.push(element);
                // element.required = false;
            }
        }
    }


    /**
     * Execute valition on a field
     * @param {any} field to validate
     * @return {boolean|any} validation result
     */
    private executeValidation(field): any {
        var validation:any;
        
        if (field.nodeName == FormElement.INPUT) {
            
            //trim only if is not a password field
            if(field.type != FormElementType.PASSWORD && field.type != FormElementType.FILE){
                field.value = StringUtils.trim(field.value);
            }
            
            switch(field.type) {
                case FormElementType.NUMBER:
                    validation = this.validateText(field);
                    if (validation === true) validation = this.validateNumber(field);
                    break;
                case FormElementType.EMAIL:
                    validation = this.validateText(field);
                    if (validation === true) validation = this.validateEmail(field);
                    break;
                case FormElementType.CHECKBOX:
                    validation = this.validateCheckbox(field);
                    break;
                case FormElementType.RADIO:
                    validation = this.validateRadio(field);
                    break;
                default:
                    validation = this.validateText(field);
            }
        } else if (field.nodeName == FormElement.SELECT) {
            validation = this.validateSelect(field);
        } else if (field.nodeName == FormElement.TEXTAREA) {
            validation = this.validateText(field);
        }

        return validation;
    } 


    /**
     * Validate text
     * @param {HTMLInputElement} field to validate
     * @return {boolean|any} validation result
     */
    private validateText(field:HTMLInputElement): any {
        var value = field.value;
        
        var minlength = field.minLength;
        var maxlength = field.maxLength;
        var length = value.length;

        if (this._requires.indexOf(field) !=-1 && length <= 0) return { error: FormErrorType.MANDATORY};
        
        if (length  > 0) {
            if (maxlength != -1 && length > maxlength) return { error: FormErrorType.MAXLENGTH};  
            else if (minlength != -1 && length < minlength) return { error: FormErrorType.MINLENGTH};

            var custom = field.getAttribute('data-custom');
            if (custom && custom != '') {
                var patt = new RegExp(custom);
                if(patt.test(value) == false) return { error: FormErrorType.CUSTOM };
                else return true;
            }  
        }

        return true;
    }

    /**
     * Validate number
     * @param {HTMLInputElement} field to validate
     * @return {boolean|any} validation result
     */
    private validateNumber(field:HTMLInputElement): any {
        var value = Number(field.value);
        
        var max = field.max;
        var min = field.min;
        
        if (max != '' && value > Number(max)) return { error: FormErrorType.MAX};
        else if (min != '' && value < Number(min)) return { error: FormErrorType.MIN};
        
        return true;
    }


    /**
     * Validate email
     * @param {HTMLInputElement} field to validate
     * @return {boolean|any} validation result
     */
    private validateEmail(field:HTMLInputElement): any {
        var value = field.value;
        if(value.indexOf('@') == -1) return { error: FormErrorType.EMAIL };
        else return true;
    }

    /**
     * Validate checkbox
     * @param {HTMLInputElement} field to validate
     * @return {boolean|any} validation result
     */
    private validateCheckbox(field:HTMLInputElement): any {
        this._checkboxgroups = {};
        for(var i in this._fields) {
            var element = this._fields[i];

            if (element.type == FormElementType.CHECKBOX) {

                var name = element.getAttribute('data-group');
                if (name) {
                    if (!this._checkboxgroups[name]) {
                        this._checkboxgroups[name] = {required:false, checked:false, fields:[]};
                    }
                    
                    this._checkboxgroups[name].fields.push(element);
                    
                    if (this._requires.indexOf(element) != -1) {
                        this._checkboxgroups[name].required = true;
                    }

                    if (element.checked == true) this._checkboxgroups[name].checked = true;
                } 

            }
        }

        var currentGroup = field.getAttribute('data-group');
        if (currentGroup && (this._checkboxgroups[currentGroup].required && !this._checkboxgroups[currentGroup].checked)) {
            return { error: FormErrorType.MANDATORY};
        } return true;
    }
    
    /**
     * Validate radio button (with same name)
     * @param {HTMLInputElement} field to validate
     * @return {boolean|any} validation result
     */
    private validateRadio(field:HTMLInputElement): any {
        this._radiogroups = {};
        for(var i in this._fields) {
            var element = this._fields[i];

            if (element.type == FormElementType.RADIO) {

                var name = element.name;
                if (name) {
                    if (!this._radiogroups[name]) {
                        this._radiogroups[name] = {required:false, checked:false, fields:[]};
                    }
                    
                    this._radiogroups[name].fields.push(element);
                    
                    if (this._requires.indexOf(element) != -1) {
                        this._radiogroups[name].required = true;
                    }

                    if (element.checked == true) this._radiogroups[name].checked = true;
                } 

            }
        }
        
        if (this._radiogroups[field.name].required && !this._radiogroups[field.name].checked) {
            return { error: FormErrorType.MANDATORY};
        } return true;
    }

    /**
     * Validate select-one and select-multiple
     * @param {HTMLSelectElement} field to validate
     * @return {boolean|any} validation result
     */
    private validateSelect(field:HTMLSelectElement): any {
        if (this._requires.indexOf(field) !=-1 && field.value == '') return { error: FormErrorType.MANDATORY};
        return true;
    }


    /**
     * On change handler, remove error class
     * @return {Event}
     */
    private onFieldChange(e:Event){
        var element:any = e.currentTarget;
        if(element.type == FormElementType.RADIO){
            var group = this._radiogroups[element.name];
            if (group) {
                for(var i= 0; i< group.fields.length; i++){
                    this.removeClassType(group.fields[i], 'error');
                }   
            }
        } else {
            this.removeClassType(element, 'error');
        }

        this.removeParentClassType(element, 'error');
    }

    /**
     * Default submit handler
     * @return {Event}
     */
    private onValidateHandler(e:Event) {
        var errors:Array<any> = this.validate();
        if (errors.length > 0) e.preventDefault();
        else this.dispatch({type:FormEventType.SEND , submit:e});
    }


    /**
     * Remove class type like "error", "error_..."
     * @param {HTMLElement} element
     * @param {string} class name
     */
    private removeClassType(el, type){
        var classes = el.className.split(" ").filter(function(c) {
            return c.lastIndexOf(type, 0) !== 0;
        });
        el.className = classes.join(' ').trim();
    }
    /**
     * Remove all parents class type
     * @param {HTMLElement} element
     * @param {string} class name
     */
    private removeParentClassType(el, type){
        var parent = el.parentNode;
        while(parent.nodeName != 'FORM'){
            this.removeClassType(parent, type);
            parent = parent.parentNode;
        }
    }

    /**
     * Validate a form
     * @return {array} errors list
     */
    public validate(): Array<any> {
        var isValid = true;
        var fields = [];

        for(var i = 0; i < this._fields.length; i++) {
            var field:any = this._fields[i];
            
            this.removeClassType(field, 'error');
            this.removeParentClassType(field, 'error');

            var result = this.executeValidation(field);
            if (result !== true) {
                isValid = false;

                VanillaUtils.addClass(field as HTMLElement, 'error');
                VanillaUtils.addClass(field as HTMLElement, 'error_' + result.error);
                
                var parent = field.parentNode;
                while(parent && parent.nodeName != 'FORM') {
                    console.log(parent, "add class")
                    VanillaUtils.addClass(parent as HTMLElement, 'error');
                    VanillaUtils.addClass(parent as HTMLElement, 'error_' + result.error);
                    parent = parent.parentNode;
                }

                result.fieldname = field.name || i;
                fields.push(result);
            }
        }

        if (fields.length > 0) {
            this.dispatch({type:FormEventType.ERROR, errors: fields});
        }

        return fields;
    }

    /**
     * Clear all form fields values
     * @param {boolean} clean hidden field too
     */
    public clear(force_hidden:boolean = false) {
        
        for(var i = 0; i < this._fields.length; i++) {

            var field:any = this._fields[i];

            switch(field.type) {
                case FormElementType.RADIO:
                case FormElementType.CHECKBOX:
                    if (field.checked) field.checked = false;
                    break;
                case FormElementType.SELECT_ONE:
                case FormElementType.SELECT_MULTIPLE:
                    field.selectedIndex = -1;
                    break;
                case FormElementType.HIDDEN:
                    if (force_hidden) field.value = '';
                    break;
                default:
                    field.value = '';
            }
        }

        this._radiogroups = {};
        this._checkboxgroups = {};
    }


    /**
     * Get field by "name" attribute
     * @return {boolean} state
     */
    public getElementByName(name): any {
        for (var i = 0; i < this._el.elements.length; i++) {
            var element:any = this._el.elements[i];
            if(element.name == name) {
                return element;
            }
        } 
        return false;
    }

    /**
     * This form is valid ?
     * @return {boolean} state
     */
    public get isValid(): boolean {
        for(var i = 0; i < this._fields.length; i++) {
            var field:any = this._fields[i];
            var result = this.executeValidation(field);
            if (result !== true) return false;
        }
        return true;
    }


    /**
     * Get form
     * @return {HTMLFormElement} Form DOM element
     */
    public get element(): HTMLFormElement{
        return this._el;
    }

    /**
     * Get all fields
     * @return {Array} Fields form list
     */
    public get fields(): Array<any>{
        return this._fields;
    }
    
    /**
     * Get all required fields
     * @return {Array} Fields form list
     */
    public get requiresFields(): Array<any>{
        return this._requires;
    }


    /**
     * Compare two fields like email/pawword confirmation
     * @return {boolean} state
     */
    public static isEqual(field1, field2): boolean {
        var value1 = field1.value;
        var value2 = field2.value;

        if(value1 == value2) return true;
        return false;
    }

    /**
     * Serialize form to a string
     * @return {string}
     */
    public serialize() : string{
        return FormValidation.serialize(this._el);
    }

    /**
     * Serialize form to a JSON object
     * @return {any}
     */
    public serializeToJSON() : any{
        return FormValidation.serializeToJSON(this._el);
    }


     /**
     * Serialize a form element to a string.
     *
     * @param form
     * @returns string
     */
    public static serialize(form:HTMLFormElement) {
        var serializedJSON = FormValidation.serializeToJSON(form),
            q = [],
            urlencode = function(str) {
                return encodeURIComponent(str).replace(/%20/g, '+');
                // According to this guy, other characters should be encoded. But compared to jQuery serialized version,
                // it doesn't seem to be mandatory
                // @link https://gist.github.com/brettz9/7147458
                // .replace(/!/g, '%21')
                // .replace(/'/g, '%27')
                // .replace(/\(/g, '%28')
                // .replace(/\)/g, '%29')
                // .replace(/\*/g, '%2A')
                // .replace(/~/g, '%7E');
            };

        if (serializedJSON) {
            for (var key in serializedJSON) {
                if (typeof(serializedJSON[key]) === "string") {
                    q.push(urlencode(key) + "=" + urlencode(serializedJSON[key]));
                } else {
                    for (var k=0; k < serializedJSON[key].length; k++) {
                        q.push(urlencode(key) + "=" + urlencode(serializedJSON[key][k]));
                    }
                }
            }
            return q.join("&");
        }
        return;
    }

    /**
     * Serialize a form element to a JSON object.
     *
     * @param form {HTMLFormElement}
     * @returns {object} JSON
     */
    public static serializeToJSON(form:HTMLFormElement) {
        var serializedJSON = {},
            singleValueElements = {
                'INPUT': [
                    'text',
                    'email',
                    'number',
                    'hidden',
                    'password',
                    'file'
                ],
                'TEXTAREA': [
                    '*'
                ],
                'SELECT': [
                    'select-one'
                ]
            },
            multipleValuesElements = {
                'INPUT': [
                    'radio',
                    'checkbox'
                ],
                'SELECT': [
                    'select-multiple'
                ]
            };

        for (var i = form.elements.length - 1; i >= 0; i--) {

            var element:any = form.elements[i];

            var nodeName:any = element.nodeName;
            
            if(element.getAttribute('data-sc-field-name')) {
                var name:string = element.getAttribute('data-sc-field-name').split(' ').join('').toLowerCase();
            } else {
                var name:string = element.name;
            }

            // var name:string = element.name;
            var type = element.type;

            /*if (element.name === '') {
                continue;
            }*/

            if (nodeName in singleValueElements && 
                (singleValueElements[nodeName].indexOf(type) > -1 || singleValueElements[nodeName][0] === '*')) {
                var value = StringUtils.trim(element.value);
                if(value != '') serializedJSON[name] = value;
            } else if (nodeName in multipleValuesElements &&
                (multipleValuesElements[nodeName].indexOf(type) > -1 || multipleValuesElements[nodeName][0] === '*')) {

                var selectedElements = [];

                var checkAttribute:string = 'checked';
                var elementsList:any = [];
                if (nodeName === FormElement.INPUT) {
                    elementsList = document.getElementsByName(name);
                    checkAttribute = 'checked';
                } else {
                    elementsList = element.options;
                    checkAttribute = "selected";
                }

                for (var j = 0; j < elementsList.length; j++) {
                    if (elementsList[j][checkAttribute]) selectedElements.push(elementsList[j].value);
                }

                if (selectedElements.length == 1) {
                    serializedJSON[name] = selectedElements[0];
                } else if (selectedElements.length > 1) {
                    serializedJSON[name] = selectedElements;
                }
            }
        }

        return serializedJSON;
    };
}



export class FormElement {
    public static INPUT             : string     = 'INPUT';
    public static TEXTAREA          : string     = 'TEXTAREA';
    public static SELECT            : string     = 'SELECT';
}

export class FormElementType {
    public static TEXT              : string     = 'text';
    public static NUMBER            : string     = 'number';
    public static PASSWORD          : string     = 'password';
    public static EMAIL             : string     = 'email';
    public static TEL               : string     = 'tel';
    public static CHECKBOX          : string     = 'checkbox';
    public static RADIO             : string     = 'radio';
    public static SELECT_ONE        : string     = 'select-one';
    public static SELECT_MULTIPLE   : string     = 'select-multiple';
    public static FILE              : string     = 'file';
    public static HIDDEN            : string     = 'hidden';
}

export class FormErrorType {
    public static MANDATORY         : string     = 'required';
    public static EMAIL             : string     = 'email';
    public static MAX               : string     = 'max';
    public static MIN               : string     = 'min';
    public static STEP              : string     = 'step';
    public static MAXLENGTH         : string     = 'maxlength';
    public static MINLENGTH         : string     = 'minlength';
    public static CUSTOM            : string     = 'custom';
}

export class FormEventType {
    public static ERROR             : string     = 'error';
    public static SEND              : string     = 'send';
}