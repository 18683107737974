import { Tracker } from "../Events/Tracker";
import Context from "../vendors/frontools/src/core/Context";
import { autobind } from "../vendors/frontools/src/decorators/Autobind";
import { EventBus } from "../Events/EventBus";

export class Tracking {
    private _elsGeneric: NodeListOf<HTMLElement>;
    private _context: Context = Context.getInstance();
    private _pageType: string;

    constructor() {
        this._init();
        this._initCoveo();
    }

    private _init() {
        this._pageType = this._context.getValue('pageType');

        // generic (data-tracking and data-tracking-mobile)
        this._elsGeneric = document.querySelectorAll('[data-tracking], [data-tracking-mobile]');
        for (let i = 0, l = this._elsGeneric.length; i < l; i++) {
            const element = this._elsGeneric[i];
            element.addEventListener('click', this._handleGeneric);
        }
        
        // home bi articles (4.1.11)
        let elsHomeBIArticles = document.querySelectorAll('.shortlist--bi .article--bi');
        for (let i2 = 0, l2 = elsHomeBIArticles.length; i2 < l2; i2++) {
            const element = elsHomeBIArticles[i2];
            element.addEventListener('click', this._handleShortlistArticles);
        }
        
        // document.documentElement.addEventListener('click', this._onClickHandler);
        // Form submit event handler
        // (4.1.1, 4.2.1, 4.2.2)
        EventBus.addListener(TrackingEvents.FORM_SUBMITTED, this._onFormSubmit);

        EventBus.addListener(TrackingEvents.FORM_OPENED, this._onFormOpen);
        
        // 4.1.12
        EventBus.addListener(TrackingEvents.CTA_CLICKED, this._onCTAClicked);
    }

    private _initCoveo() {
        // facets
        document.body.addEventListener('changeAnalyticsCustomData', e => {
            const eventDetail = ((e as any).detail as any);

            // Facets
            if (eventDetail.actionCause === 'facetSelect') {
                const meta = eventDetail.metaObject;
                const category = meta.facetTitle;
                const facet = meta.facetValue;

                Tracker.event({
                    eventCategory: `filtre dans la page ${this._context.getValue('pageType', 'autres')}`,
                    eventAction: category,
                    eventLabel: facet
                });
            }
        });

        // Search results
        const resultsList = document.querySelector('.CoveoResultList');

        if (resultsList) {
            resultsList.addEventListener('click', (e) => {
                const target = e.target as HTMLElement;
                const closestResult = target.closest('.CoveoResult')
                
                if (target.classList.contains('.CoveoResult') || closestResult) {
                    const result = closestResult || target;

                    const tagElement = result.querySelector('.article__tag') ? result.querySelector('.article__tag') : result.querySelector('.heading--small');
                    const titleElement = result.querySelector('.article__title') ? result.querySelector('.article__title') : result.querySelector('.heading--medium');
    
                    const action = (!tagElement || tagElement.innerHTML == '') ? 'autres' : tagElement.innerHTML;
                    const label = (!titleElement || titleElement.innerHTML == '') ? 'autres' : titleElement.innerHTML;

                    const pageType = this._context.getValue('pageType', 'autres');
                    
                    if (pageType === 'search' || pageType === 'recherche') {
                        Tracker.event({
                            eventCategory: `recherche`,
                            eventAction: action,
                            eventLabel: label
                        });
                    } else if (pageType === 'team' || pageType === 'equipe') {
                        Tracker.event({
                            eventCategory: `equipe`,
                            eventAction: action,
                            eventLabel: label
                        });
                    } else if (pageType === 'business-intelligence' || pageType === 'veille%20strategique' || pageType === 'veille-strategique') {
                        Tracker.event({
                            eventCategory: `veille strategique`,
                            eventAction: action,
                            eventLabel: label
                        });
                    }
                }
            });
        }
    }
    
    @autobind
    private _onClickHandler(e: Event) {
        let target = e.target as HTMLElement;
        console.log(target);
        
        // 4.1.11 
        if (target.classList.contains('article--bi') || target.closest('.article--bi')) {
            let tag = target.querySelector('.article__tag') as HTMLElement;
            let title = target.querySelector('.article__title') as HTMLElement;

            Tracker.event({
                eventCategory: 'page...',
                eventAction: tag.innerText,
                eventLabel: title.innerText
            });
        }
        
    }

    @autobind
    private _handleGeneric(e) {
        if (e.currentTarget.dataset.tracking) {
            Tracker.event(Tracker.parseEventString(e.currentTarget.dataset.tracking));
        } else if (e.currentTarget.dataset.TrackingMobile && 
            this._context.isTouch) {
                Tracker.event(Tracker.parseEventString(e.currentTarget.dataset.TrackingMobile));
        }
    }

    @autobind
    private _onFormSubmit(e) {
        if (e.data.name === 'contact') {
            // 4.1.1
            Tracker.event({
                eventCategory: 'lead gen',
                eventAction: 'email',
                eventLabel: 'formulaire de contact'
            });
        } 
        
        // 4.2.1 && 4.2.2
        // (Will also trigger for any other submitted Form class instance)
        Tracker.virtualPageView({
            uri: `/form/${e.data.name}?ga_page=confirmation-${e.data.name}`
        });
    }

    @autobind
    private _onFormOpen(e) {
        Tracker.virtualPageView({
            uri: `/form/${e.data.name}`
        });
    }

    @autobind
    private _onCTAClicked(e) {
        let ctaText = e.el.querySelector('.cta__text');

        if (ctaText) {
            // 4.1.12
            Tracker.event({
                eventCategory: this._context.getValue('pageType', 'autres'),
                eventAction: 'CTA',
                eventLabel: ctaText.innerText
            });
        }
    }

    @autobind
    private _handleShortlistArticles(e) {
        let target = e.currentTarget;
        let tag = target.querySelector('.article__tag') as HTMLElement;
        let title = target.querySelector('.article__title') as HTMLElement;

        Tracker.event({
            eventCategory: this._pageType,
            eventAction: tag.innerText,
            eventLabel: title.innerText
        });
    }
}

export const TrackingEvents = {
    FORM_SUBMITTED: 'form_submitted',
    FORM_OPENED: 'form_opened',
    CTA_CLICKED: 'cta_clicked'
};