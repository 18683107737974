import OverlayAbstract from "../Abstract/OverlayAbstract";
import Template from "../vendors/frontools/src/data/Template";
import { autobind } from "../vendors/frontools/src/decorators/Autobind";

declare const TweenMax;
declare const Expo;

export class OverlaySimple extends OverlayAbstract { 

    // private _elsClose: HTMLElement[];
    private _mainTemplate: Template;
    private _template: Template;
    private _overlayWrapper: HTMLElement;
    private _overlay: HTMLElement;
    private _overlayContent: HTMLElement;
    private _elsClose: NodeListOf<HTMLElement>;

    constructor(protected _element: HTMLElement) {
        super(_element);

        this._element.addEventListener('click', this._init);
    }

    @autobind
    private _init(e: Event) {
        e.preventDefault();

        this._templateId = this._element.getAttribute('data-overlay-id');

        this._mainTemplate = new Template(document.getElementById('overlay'), {});
        this._template = new Template(document.getElementById('overlay-'+this._templateId), {});

        this._overlayWrapper = document.createElement('div');
        this._overlayWrapper.innerHTML = this._mainTemplate.htmlAsString;
        
        this._overlay = this._overlayWrapper.querySelector('.overlay');
        this._overlay.classList.add('overlay--simple');
        this._overlayContent = this._overlayWrapper.querySelector('.main')

        const htmlElement = this._template.htmlAsString;
        this._overlayContent.innerHTML = htmlElement;

        document.body.appendChild(this._overlayWrapper);

        this.open();
        
        this.close = this.close.bind(this);
        this._elsClose = this._overlayWrapper.querySelectorAll('[data-js="button-close"]');        
        for (let j = 0; j < this._elsClose.length; j++) {
            const btn = this._elsClose[j];            
            btn.addEventListener('click', this.close);
        }
    }

    public open() {
        super.open();

        TweenMax.from(this._overlayContent, .5, {alpha: 0, y: 100, ease: Expo.easeOut });
    }

    public close() {
        super.close();

        TweenMax.to(this._overlayContent, .5, {alpha: 0, y: 100, ease: Expo.easeOut});
        TweenMax.to(this._overlayWrapper, .5, {alpha: 0, ease: Expo.easeOut, onComplete: () => {
            this._overlayWrapper.parentElement.removeChild(this._overlayWrapper);
        }});
    }

    public destroy() {
        for (let j = 0; j < this._elsClose.length; j++) {
            const btn = this._elsClose[j];            
            btn.removeEventListener('click', this.close);
        }
    }
}