import Module from '../Abstract/Module';
import { autobind } from '../vendors/frontools/src/decorators/Autobind';

declare const TweenMax, Expo;

export class Toggle extends Module {

    private _id: string;
    private _isActive: boolean = false;
    private _elsTarget: NodeListOf<HTMLElement>;

    constructor(_element : HTMLElement) {
        super(_element);

        this._id = this._element.dataset.toggle;
        this._elsTarget = document.querySelectorAll(`[data-toggle-target="${this._id}"]`);        
        this._element.addEventListener('click', this.onTrigger);
        this._element.dataset.active = `${this._isActive}`;
    }

    @autobind
    private onTrigger(e) {
        this._isActive = !this._isActive;
        this._element.dataset.active = `${this._isActive}`;

        for (let i = 0, l = this._elsTarget.length; i < l; i++) {
            const target = this._elsTarget[i];

            if (this._element.dataset.active === 'true') {
                target.classList.add('is-active');
                TweenMax.killTweensOf(target);
                TweenMax.from(target, .7, {height: 0, ease: Expo.easeInOut, onComplete: () => {
                    TweenMax.set(target, {clearProps: 'height'});
                }});
            } else {
                TweenMax.killTweensOf(target);
                TweenMax.to(target, .7, {height: 0, ease: Expo.easeInOut, onComplete: () => {
                    target.classList.remove('is-active');
                    TweenMax.set(target, {clearProps: 'height'});
                }});
            }
        }
    }
    
}
