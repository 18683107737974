/**
 * DisplayUtils
 * (c) lg2fabrique 2016
 */

export default class DisplayUtils {

    public static LEFT                  : string           = 'L';
    public static RIGHT                 : string           = 'R';
    public static TOP                   : string           = 'T';
    public static BOTTOM                : string           = 'B';
    public static TOP_LEFT              : string           = 'TL';
    public static BOTTOM_LEFT           : string           = 'BL';
    public static TOP_RIGHT             : string           = 'TR';
    public static BOTTOM_RIGHT          : string           = 'BR';
    public static CENTER                : string           = 'C';

    /**
     * Get a position matrix
     * @param {any} Target size object (width and height)
     * @param {any} Rectagle size object (width and height)
     * @param {string} Alignement type
     * @param {boolean} Fill all rect or not
     *
     var matrix = DisplayUtils.fitIntoRect({
        width:500, height:500},
     {width:600, height:800},
     DisplayUtils.CENTER,
     false
     );
     */

    public static fitIntoRect(targetSize:any, rectSize:any, alignType:string = DisplayUtils.CENTER, fillRect:Boolean = false) : any {

        var matrix : any = {};

        var width = rectSize.width;
        var height = rectSize.width * targetSize.height / targetSize.width;

        if(height < rectSize.height && fillRect){
            height = rectSize.height;
            width = rectSize.height * targetSize.width / targetSize.height;
        }

        var x = 0;
        var y = 0;

        switch(alignType) {
            case DisplayUtils.LEFT :
            case DisplayUtils.TOP_LEFT :
            case DisplayUtils.BOTTOM_LEFT :
                x = 0;
                break;
            case DisplayUtils.RIGHT :
            case DisplayUtils.TOP_RIGHT :
            case DisplayUtils.BOTTOM_RIGHT :
                x = rectSize.width - width;
                break;
            default :
                x = (rectSize.width - width ) /2;
        }

        switch(alignType) {
            case DisplayUtils.TOP :
            case DisplayUtils.TOP_LEFT :
            case DisplayUtils.TOP_RIGHT :
                y = 0;
                break;

            case DisplayUtils.BOTTOM :
            case DisplayUtils.BOTTOM_LEFT :
            case DisplayUtils.BOTTOM_RIGHT :
                y = rectSize.height - height;
                break;

            default :
                y = (rectSize.height - height ) /2;
        }

        matrix.width = width;
        matrix.height = height;
        matrix.x = x;
        matrix.y = y;
        matrix.scale = width / targetSize.width;

        return matrix;
    }

    
     /**
     * Set equalheight to multiple elements (even row specific in a grid)
     * Should be used with Breakpoint in resize events to allow a change in rowLength
     * 
     * @param {NodeList} elements - Elements to equalize (via querySelectorAll)
     * @param [rowLength] Number - Number of items per row (optional)
     *
        DisplayUtils.setEqualHeight(documeny.querySelectorAll('.news .title'), 3);
     */
    public static setEqualHeight(elements: NodeList, rowLength?: number) {
            
            let total = elements.length,
                elementsArr = Array.prototype.slice.call(elements),
                maxHeight = 0,
                rowItems = [],
                n = 0;  // Simple counter

            rowLength = total < rowLength ? total : rowLength;
            
            // Make sure to reset height before calculation
            for (let i = 0, l = total; i < l; i++) {
                var ele = elements[i] as HTMLElement;
                ele.style.height = '';
                maxHeight = Math.max(ele.offsetHeight, maxHeight);
                rowItems.push(ele);
                
                // We check if we need to check height by row 
                if (rowLength) {
                    if (n < rowLength - 1 && i < (total - 1)) {
                        n++;
                    } else {
                        for (let j = 0, l = rowItems.length; j < l; j++) {
                            (rowItems[j] as HTMLElement).style.height = maxHeight +'px';
                        }
                        n = maxHeight = 0;
                        rowItems = [];
                    }
                }
            }
            if (!rowLength) {
                for (var i = 0, l = total; i < l; i++) {
                    let ele = elements[i] as HTMLElement;
                    ele.style.height = maxHeight + 'px';
                }
            }
        };

}