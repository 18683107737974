/**
 * Accessibility
 * (c) lg2fabrique 2016
 *
 */

import KeyCode from '../events/KeyCode'
import VanillaUtils from '../utils/VanillaUtils'

export default class Accessibility {

    /**
     * Add tab mode class to html hen user touch "tab key".
     * @param {String} Class name to add to html
     *     Accessibility.addTabMode();
     */
    public static addTabMode(className:string = 'tab_mode') {
        var htmlClass = (className) ? className : 'tab_mode';

        function addTabModeClass(e) {
            var keyCode = e.keyCode || e.which;

            // Show except inside form
            if (keyCode == KeyCode.TAB) {
                var el = document.querySelector('html');
                if (!VanillaUtils.hasClass(el, htmlClass)){
                    VanillaUtils.addClass(el, htmlClass);
                    console.log('Add "tab_mode" class on <html>')
                    document.removeEventListener('keydown', addTabModeClass);
                }
            }
        }

        document.addEventListener('keydown', addTabModeClass);

    };
}