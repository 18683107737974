import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import OverlayAbstract from '../Abstract/OverlayAbstract';
import Template from '../vendors/frontools/src/data/Template';
import Module from '../Abstract/Module';
import EventType from 'vendors/frontools/src/events/EventType';


declare const TweenMax;
declare const Expo;

export class Search extends OverlayAbstract {

    private _template : HTMLElement;
    private _overlayWrapper: HTMLElement;
    private _input: HTMLInputElement;
    private _closeButton: HTMLElement;

    constructor(protected _element: HTMLElement) {
        super(_element);
        
        this._element.addEventListener('click', this._onClick);
        this._template = document.querySelector('.overlay-search');
    }

    @autobind
    private _onClick(e) {
        e.preventDefault();

        this.open();
    }

    @autobind
    private _onClose(e) {
        e.preventDefault();
        this.close();
    }


    public close() {
        super.close();

        this._overlayWrapper.classList.remove('overlay-is-open');

        // Waiting for close animation to clear the input and remove the element
        setTimeout(() => {
            this._input.value = "";
            this._overlayWrapper.parentElement.removeChild(this._overlayWrapper);
        }, 200) 
    }

    @autobind
    private _onOverlayClick(e) {
        if (e.target  === this._template) {
            this.close();
        }
    }

    public open() {
        this._overlayWrapper = document.createElement('div');
        this._overlayWrapper.className = "coveo-search-component";

        this._overlayWrapper.appendChild(this._template);

        this._input = this._template.querySelector('.magic-box-input input');

        document.body.appendChild(this._overlayWrapper);
        this._closeButton = this._overlayWrapper.querySelector('[data-js="button-close"]');
        this._closeButton.addEventListener('click', this._onClose);
        this._template.addEventListener('click', this._onOverlayClick);

        window.onhashchange = () => {
            this.close();
        }

        // Fix for open animation when element is not ready in dom
        setTimeout(() => {
            this._overlayWrapper.classList.add('overlay-is-open');
        }, 50)

        if (this._input) this._input.focus();
        
        
        super.open();
    }



    
    
    
    
}