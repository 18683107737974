/**
 * Routar
 * (c) lg2fabrique 2016
    var routar = new Routar({
        'fr/section': {
            beforeUpdate: function(service, nextProcess) {
                console.log('beforeUpdate fr/section');
                nextProcess();
            },
            update: function(service) {
                console.log('update fr/section')
            }
        }
    });
*/

import ObjectUtils from '../utils/ObjectUtils';
import UrlUtils from '../utils/UrlUtils';
import { autobind } from '../decorators/Autobind';

export default class Routar {

    private static _instance        : Routar;

    private _supported               : boolean            = false;
    private _root                    : string             = '/';
    private _routes                  : any                = {};

    private _lastCalledRoute         : any;
    private _lastExecutedRoute       : any;
    private _prevExecutedRoute       : any;
    private _status                  : string              = '';

    private _isStart                 : boolean             = false;


    /**
     * constructor
     * * @param {any} Route object
     * * @param {string} Root base override
     */
    constructor(routes:any = {}) {

        //singleton validation
        if(Routar._instance) throw new Error('Error: Use Routar.getInstance() instead of new.');
        Routar._instance = this;


        //push state validation
        if ((window as any).history && !(window as any).history.pushState) return;
        else this._supported = true;


        //save configuration routes
        this._routes = this.createRegExp(routes);
        console.log(this._routes)

        //start routing automaticaly or at demand
        this.start();
    }


    /**
     * On click handler
     * @param {Event} Mouse event
     */
    @autobind
    private onClickListener(event) {
        if(!this._isStart) return;
        if(!event.currentTarget.href) return;
        if(UrlUtils.isExternalURL(event.currentTarget.href)) return;

        var url = UrlUtils.parse(event.currentTarget.href);
        var requestedRoute = this.parseUrl(url.path + (url.query != '' ? '?' + url.query: ''));
        var route = this.getRoute(requestedRoute.path_without_query);
        if (route) {

            if (this._lastExecutedRoute && this._lastExecutedRoute.url === requestedRoute.url) {
                event && event.preventDefault();
                return;
            }

            event && event.preventDefault();

            this._lastCalledRoute = requestedRoute;

            requestedRoute = this.addRouteData(requestedRoute);
            history.pushState(requestedRoute, requestedRoute.url, requestedRoute.url);

            this.executeRoute(requestedRoute);
        }
    }

    /**
     * Pop Stsate history handler
     * @param {Event} History event
     */
    private onPopState(event) {
        if(!this._isStart) return;
        if (!event.state) return;

        //Pas certain de l'efficacité ces lignes. Je les les ai enlever pour régler un bug...à voir.
        /*if (event && event.state && this._lastExecutedRoute.url === event.state.url) {
            window.history.replaceState(this._lastCalledRoute, this._lastCalledRoute.url, this._lastCalledRoute.url);
            return;
        }*/

        event.state.params = {};

        var parsedUrl = event.state;
        this._lastCalledRoute = parsedUrl;

        this.executeRoute(parsedUrl);
    }



    /**
     * Parse the routeList parameter and create a regex for each one
     * @param {any} Route object list
     */
    private createRegExp(routeList:any) {
        for(var route in routeList) {
            routeList[route] = Object.create(routeList[route]);

            var routeRegExp = route.toString();

            if(routeRegExp.substr(0,1) == '/') routeRegExp = routeRegExp.substr(1);
            if(routeRegExp.substr(routeRegExp.length-1) == '/') routeRegExp = routeRegExp.substr(0, routeRegExp.length-1);
            routeRegExp = routeRegExp.replace(/\//g, '\\/');

            /* looking for dynamic value (:id) */
            var paramList = [];

            var regx = new RegExp("(\\(\\?)?:\\w+", "g");
            var arr;
            while ((arr = regx.exec(route)) !== null) {
                paramList.push(arr[0].slice(1));
            }
            /* add the regex to the route object */
            for(var param in paramList) {
                routeRegExp = routeRegExp.replace(':' + paramList[param].toString(), '([A-Za-z0-9_\-]+)');
            }
    
            //routeRegExp += '(?:\\/)?$';
            //routeRegExp += '\/(.*)?$';
            routeRegExp += '(\/?)(.*)?$';
 
            routeList[route]['regex'] = routeRegExp;
            routeList[route]['params'] = paramList;
        }
   

        return routeList;
    }

    /**
     * Add route_param to object
     * @param {IUri} Route object
     * @return {any} Route object
     */
    private addRouteData(parsedUrl:IUri) {
        var route = this.getRoute(parsedUrl.path_without_query);
        parsedUrl['route_params'] = {};

        var i = 1;
        var arr = [];

        var regex = new RegExp(route.regex, 'g');
        var regexResult = regex.exec(parsedUrl.path_without_query);

        for(var param in route.params) {
            parsedUrl['route_params'][route.params[param]] = regexResult[i];
            i++;
        }

        return parsedUrl;
    }

    /**
     * Return the route object
     * @param {string} Route url
     * @return {any | boolean} Route object
     */
    private getRoute(path:string) {
        if(path == '') path = '/';
        if(path.indexOf('://') != -1){
            var url = UrlUtils.parse(path);
            path = url.path;
        }

        for(var route in this._routes) {
            if (path.toString().match(this._routes[route].regex)) {
                return this._routes[route];
            }
        }
        return false;
    }


    /**
     * Processing route
     * @param {IUri} Route object
     */
    private routeHandler(parsedUrl:IUri) {

        this._lastCalledRoute = parsedUrl;

        parsedUrl = this.addRouteData(parsedUrl);

        history.pushState(parsedUrl, parsedUrl.url,parsedUrl.url);

        this.executeRoute(parsedUrl);
    }

    /**
     * Processing route execution
     * @param {IUri} Route object parsed
     */
    private executeRoute(parsedUrl:IUri){
        var route = this.getRoute(parsedUrl.path_without_query);
        if(!route) return;

        if(route.service === undefined) route.service = false;

        if (route.beforeUpdate) {
            this.routeBeforeUpdate(parsedUrl);
            return;
        }

        if (route.service !== false) {
            this.serviceHandler(parsedUrl);
            return;
        }

        this.routeUpdate(parsedUrl);
    }

    /**
     * Processing before update route
     * @param {IUri} Route object parsed
     */
    private routeBeforeUpdate(parsedUrl) {
        var route = this.getRoute(parsedUrl.path_without_query);
        this._status = 'onBeforeUpdate';
        route.beforeUpdate && route.beforeUpdate( { request: parsedUrl }, this.nextProcess.bind(this) );
    }

    /**
     * Processing update route
     * @param {IUri} Route object parsed
     */
    private routeUpdate(parsedUrl:IUri, response?:any) {
        var route = this.getRoute(parsedUrl.path_without_query);
        this._status = 'onUpdate';

        route.update && route.update( { request: parsedUrl, response: response } );

        this._prevExecutedRoute = this._lastExecutedRoute;
        this._lastExecutedRoute = parsedUrl;
    }

    /**
     * Processing service call
     * @param {IUri} Route object parsed
     */
    private serviceHandler(parsedUrl) {

        var requestObject:any = {};
        var route = this.getRoute(parsedUrl.path_without_query);

        parsedUrl = this.addRouteData(parsedUrl);

        if (route.service && typeof route.service === "string")  {
            requestObject.url = route.service;
            requestObject.params = parsedUrl.route_params;
        } else if (route.service !== false) {
            requestObject.url = parsedUrl.url;
        }

        var xhr = (window as any).XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
        xhr.open('GET', requestObject.url);
        xhr.onreadystatechange = function() {
            if (xhr.readyState > 3 && xhr.status == 200) {
                var response = xhr.responseText;
                if (parsedUrl.url === this._lastCalledRoute.url) {
                    this.routeUpdate(parsedUrl, response);
                }
            }
        }.bind(this);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(requestObject.params);

        return xhr;
    }


    /**
     * Processing next process
     * @param {any} Service object
     */
    private nextProcess(service){
        if (!this._supported) return;

        if (!service) this.routeUpdate(this._lastCalledRoute);
        else this._lastCalledRoute = service.request;

        if (this._lastExecutedRoute.url === this._lastCalledRoute.url) {
            this.routeUpdate(this._lastCalledRoute); //Temporaire
            return;
        }

        var route = this.getRoute(this._lastCalledRoute.path_without_query);

        switch (this._status) {
            case 'onBeforeUpdate':
                if (route.service !== false) this.serviceHandler(this._lastCalledRoute);
                else if (route.update) this.routeUpdate(this._lastCalledRoute);
                break;
            case 'onUpdate':
                break;
        }

        this._status = '';
    };


    /**
     * Parse url object
     * @param {string} Url
     * @returns {IUri}  URI values
     */
    private parseUrl(url):IUri {

        var parser = document.createElement('a');
        parser.href = url;

        var path = url.replace(parser.protocol + '//' + parser.host + '/', '');
        var route = /^[^?]*/i.exec(path);
        var query = path.replace(route, '');
        var parsedUrl:IUri = {
            url: url,
            path: path,
            path_without_query: route[0],
            query_string: query,
            query: {},
            route_params: {}
        };

        // get query parameter
        var regx = new RegExp("(?:\\?|\\&)((?:[^=]+)\\=(?:[^&]+))", "g");
        var arr;
        while ((arr = regx.exec(path)) !== null) {
            var beforeEqual = arr[1].slice(0, +arr[1].indexOf('=')),
                afterEqual = arr[1].slice(+arr[1].indexOf('=')+1);
            parsedUrl['query'][beforeEqual] = afterEqual;
        }

        return parsedUrl;
    }


    /**
     * Start routing listener
     */
    public start(): void{
        if (!this._supported) return;

        //bind base listener
        this.bindLinks();
        window.onpopstate = this.onPopState.bind(this);

        //parse current url
        var parsedUrl: IUri = this.parseUrl(document.location.href);
        parsedUrl = this.addRouteData(parsedUrl);

        //set base var
        this._prevExecutedRoute = parsedUrl;
        this._lastExecutedRoute = parsedUrl;
        this._lastCalledRoute = parsedUrl;

        this._isStart = true;

        //window.history.pushState(parsedUrl, parsedUrl.url, parsedUrl.url);
        this.routeHandler(parsedUrl);
        console.info('ROUTAR: start');
    };

    public bindLinks() {
        //bind base listener
        var links = document.querySelectorAll('a');
        for(var i=0; i<links.length; i++){
            if (!links[i].getAttribute('data-routar-exclude')) {
                links[i].removeEventListener('click', this.onClickListener); 
                links[i].addEventListener('click', this.onClickListener);
            }
        }
    }

    /**
     * Stop routing listener
     */
    public stop(){
        if (!this._supported) return;

        var links = document.querySelectorAll('a')
        for(var i=0; i<links.length; i++){
            links[i].removeEventListener('click', this.onClickListener);
        }
        window.onpopstate = null;

        this._isStart = false;
        console.info('ROUTAR: stop');
    };

    /**
     * Navigate to a route url
     * @param {string} Url
     * @param {any} Parameters
     */
    public navigate(route:string) {
        if (!this._supported) return;

        var link = document.createElement('a');
        link.href = route;

        var requestedRoute = this.parseUrl(route);

        if (this.getRoute(requestedRoute.path_without_query)) {
            this.routeHandler(requestedRoute);
        }else console.info('ROUTAR: route is not found -> ' + requestedRoute.path);
    };

    /**
     * Add a route
     * @param {any} Route object
     * @return {any} route list
     */
    public add(routes:any) {
        if (!this._supported) return;

        var routesRegx = this.createRegExp(routes);
        this._routes = ObjectUtils.extends( this._routes, routesRegx);

        console.info('ROUTAR: ' + Object.keys(routes) + ' has added');

        return this._routes;
    };

    /**
     * Remove a route
     * @param {string} Route name
     * @return {any} route list
     */
    public remove(route:string) {
        if (!this._supported) return;

        if(this._routes[route]) {
            delete this._routes[route];
            console.info('ROUTAR: ' + route + '\' has removed');
        }

        return this._routes;
    };

    /**
     * clear all registered routes
     */
    public clear() {
        if (!this._supported) return;
        this._routes = {};
        console.info('ROUTAR: routes have cleared');
    };

    /**
     * useful to navigate in javascript to a route without a page reload
     * @returns {any}  Previous request object
     */
    public getPreviousRequest() {
        if (!this._supported) return;
        return this._lastExecutedRoute;
    };


    /**
     * get singleton instance
     * @returns {Routar}  instance's Routar
     */
    public static getInstance(): Routar {
        return Routar._instance;
    }

}

interface IUri{
    url:string;
    path:string;
    path_without_query:string;
    query_string:string;
    query: any,
    route_params: any
}