/**
 *
 * Preloader file (only support image -> other files type)
 *
 * (c) lg2fabrique 2016
 *
 *
    var preloader = new Preloader([
         'img/image1.svg',
         'img/image2.png',
         'img/image3.jpg'
    ]);
    preloader.addEventListener(Preloader.COMPLETED, function(e){
        console.log('complete ');
    });
    preloader.addEventListener(Preloader.STARTED, function(e){
        console.log('start file: ' + e.data);
    });
    preloader.addEventListener(Preloader.LOADED, function(e){
        console.log('loaded file: ' + e.data);
    });
    preloader.addEventListener(Preloader.ERROR, function(e){
        console.log('error file: ' + e.data);
    });
    preloader.start();
 */

import AbstractDispatcher from '../abstract/AbstractDispatcher';

export default class Preloader extends AbstractDispatcher {

    public static FILETYPE = {
        IMAGE: 'image',
        UNKNOWN: 'unknown'
    };

    public static COMPLETED: string = 'completed';
    public static STARTED: string   = 'started';
    public static LOADED: string    = 'loaded';
    public static ERROR: string     = 'error';

    private _filesList:  string[] = [];
    private _index:number = 0;
    private _isComplete: boolean = false;

    /**
     * constructor
     * @param {Array} List of files path
     */
    constructor(files: Array<string>) {
        super();
        this._filesList = files;
    }

    /**
     * TODO support other file format
     * Get file type
     * @param filesrc
     * @returns {String} File type
     */
    private getFiletype(filesrc:string): string{
        if(filesrc.match(/\.(jpg|jpeg|png|gif|svg)$/)) return Preloader.FILETYPE.IMAGE;
        else return Preloader.FILETYPE.UNKNOWN;
    }

    /**
     * Loading complete handler
     */
    private completed(){
        this._isComplete = true;
        this.dispatch({type:Preloader.COMPLETED});
    }

    /**
     * File complete handler
     * @param {any} File data
     */
    private loaded(file:any){
        this.dispatch({
            type: Preloader.LOADED,
            path: this._filesList[this._index],
            file: file
        });
        this.loadNext();
    }

    /**
     * Error handler
     * @param {Object} event object
     */
    private error(e:any){
        this.dispatch(e);
        if(console && console.warn) console.warn('Preloader error -> ' + e.path, e);
        this.loadNext();
    }

    /**
     * Load file
     * @param {String} File path
     */
    private load(filesrc:string){
        var file;
        var type = this.getFiletype(filesrc);

        this.dispatch({type:Preloader.STARTED, path:filesrc});

        if(type == Preloader.FILETYPE.IMAGE){
            file = new Image();
            file.src = filesrc;

            file.onload = function(){
                this.loaded(file);
            }.bind(this);

            file.onerror = function(e){
                this.error({
                    type:Preloader.ERROR,
                    path: filesrc,
                    error:{message:'File not found', type:2}
                });
            }.bind(this);

        }else if(type == Preloader.FILETYPE.UNKNOWN){
            this.error({
                type:Preloader.ERROR,
                path:filesrc,
                error:{message:'File is not supported.', type:1}
            });
        }
    }

    /**
     * Load next file from the list
     */
    private loadNext(){
        this._index++;
        if(this._index >= this._filesList.length) this.completed();
        else this.load(this._filesList[this._index]);
    }


    /**
     * Start loadinf files
     */
    public start(){
        if(this._filesList.length == 0){
            if(console && console.warn) console.warn('no files to load');
            return;
        }

        if(!this._isComplete) this.load(this._filesList[0]);
        else if(console && console.log) console.log('loading is completed');
    };

}