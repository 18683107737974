/**
 * Caching manager
 *
 * (c) lg2fabrique 2016

 //set value
 Cache.set('win', $(window));

 //get value
 Cache.get('win');

 //remove key
 Cache.remove('win');

 //clear cache
 Cache.clear();
 Cache.clear('user'); // clear all value than contains 'user' like 'user_info

 //force set value
 Cache.set('win', $(window), true);

 //return default value if the key is undefined
 Cache.get('doc', $(document));
 */

export default class Cache {    

    /**
     * Set value to cache
     * @param {String} Key
     * @param {any} Value
     * @param {booledan} override stored value
     * */
    public static set(key:string, value:any, force:boolean = false, persistent:boolean = false) {
        if (key === undefined) {
            throw new Error('The key is not defined.');
        } else if(value === undefined) {
            throw new Error('The value is not defined.');
        } else if(Cache.data[key] === undefined || force === true) {
            Cache.data[key] = value;

            if (persistent && Cache.isStorageAvailable()) {
                var cache: any = window.localStorage.getItem('___cache');

                if (cache && cache != '') {
                    cache = JSON.parse(cache);
                } else {
                    cache = {};
                } 
                cache[key] = value;
                window.localStorage.setItem('___cache', JSON.stringify(cache));
            }
        } else {
            throw new Error('The key "' + key + '" is defined. Use "force" parameter to replace it.');
        }
    };


    /**
     * Get value from cache
     * @param {String} Key
     * @param {any} default value
     * @return {*} Key's value
     */
    public static get(key :string, defaultValue :any = undefined) {
        if (key === undefined) {
            throw new Error('The key is not defined.');
        } else if (Cache.data[key] !== undefined) {
            return Cache.data[key];
        } else {
            if (defaultValue !== undefined) {
                Cache.set(key, defaultValue);
                return defaultValue;
            } else {
                return false;
            }
        }
    };


    /**
     * Remove value from cache
     * @param {String} Key
     */
    public static remove(key :string) {
        if (key === undefined) {
            throw new Error('The key is not defined.');
        } else {
            Cache.data[key] = null;
            delete Cache.data[key];

            if (Cache.isStorageAvailable()) {
                var cache: any = window.localStorage.getItem('___cache');
                if (cache && cache != '') {
                    cache = JSON.parse(cache);
                    cache[key] = null;
                    delete cache[key];
                    window.localStorage.setItem('___cache', JSON.stringify(cache));
                }
            }
            console.info('CACHE: DEL -> ', key);
        }
    };


    /**
     * Clear all values from cache
     * @param {String} contains specific key value
     */
    public static clear(contains?:string) {
        
        for(var i in Cache.data){
            if(contains && i.indexOf(contains) != -1) {
                Cache.remove(i);
            } else if(!contains) {
                Cache.remove(i);
            }
        }

        if(contains) console.info('CACHE: CLEANED VALUES CONTAINS -> ' + contains);
        else console.info('CACHE: CLEANED');
    };

    
    /**
     * Check for localStorage availability based on testing it (safari private browsing proof)
     * @returns {boolean} - localStorage availability
     */
    public static isStorageAvailable(): boolean {
        try {
            let test = '__test__';
            window.localStorage.setItem(test, test);
            window.localStorage.removeItem(test);
            return true;
        } catch(e) {
            return false;
        }
    };

    static persistent : any = Cache.isStorageAvailable() && window.localStorage.getItem('___cache');
    static data : any = {};
    
}

//get ___cache localstorage
if (Cache.isStorageAvailable()) {
    var cache = Cache.persistent != '' ? JSON.parse(Cache.persistent) : {};
    for (let i in cache) {
        Cache.data[i] = cache[i];
    }
}