/**
 * ObjectUtils
 * (c) lg2fabrique 2016
 *
 */

export default class ObjectUtils {
    /**
     * Extend object with another
     * @param {any} Object to extends
     * @param {any} Object
        var obj = ObjectUtils({test1:'test1'}, {test2:'test2'});
     */
    public static extends(obj1:any, obj2:any){
        for (var i in obj2) {
            obj1[i] = obj2[i];
        }
        return obj1;
    };

    /**
     * Clone a object with another
     * @param {any} Object to clone
     */
    public static clone(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }
}
