/**
 * LinkedIn
 * (c) lg2fabrique 2016
 *
 */

import DocumentUtils from '../utils/DocumentUtils';
import UrlUtils from '../utils/UrlUtils';

export default class LinkedIn {

    private static _instance: LinkedIn;

    /**
     * constructor
     */
    constructor() {
        //singleton validation
        if(LinkedIn._instance) throw new Error('Error: Use LinkedIn.getInstance() instead of new.');
        LinkedIn._instance = this;
    }

    /**
     * Open window to the LinkedIn Sharer page
     * @param {Object} Options for the share (https://developer.linkedin.com/documents/share-linkedin)
     * @param {Boolean} Open popup or _blank
     * @param {Number} Popup width
     * @param {Number} Popup height
     *   LinkedIn.sharer({url:'http://www.lg2.com'});
     */
    public static sharer(options:ILinkedIn, popup:boolean = true, width:number = 600, height:number = 400) {
        if(options.url){
            options.url = UrlUtils.query(options.url, {
                utm_source: 'linkedin',
                utm_medium: 'social'
            });
        }else throw new Error('Url is not defined');

        //open window
        var link  = UrlUtils.query('http://www.linkedin.com/shareArticle', options);
        if(popup === true) DocumentUtils.openPopup(link, width, height);
        else window.open(link, '_blank');
    };


    /**
     * get singleton instance
     * @returns {LinkedIn}  instance's LinkedIn
     */
    public static getInstance(): LinkedIn {
        return LinkedIn._instance;
    }
}

export interface ILinkedIn{
    url: string;
    title: string;
    summary: string;
}