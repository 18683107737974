import { deprecated } from '../decorators/Deprecated';
/**
 * StringUtils
 * (c) lg2fabrique 2016
 *
 */

export default class StringUtils {
    /**
     * function createGUID - Create unique id
     * @return {string}
     */
    public static createGUID(): string {
        let time: number = new Date().getTime();

        function s4() {
            return Math.floor((1 + Math.random() * time) * 0x10000).toString(16).substring(1);
        }

        return s4().substr(0, 10);
    };


    /**
     * function toSlug - Convert a string to a slug(url)
     * @param {string} str - String to convert
     * @return {string}
     */
    public static toSlug(str: string): string {
        str = str.toLowerCase();
        str = StringUtils.normalize(str);

        return str.toString()
                .replace(/\s+/g, '-')           // Replace spaces with -
                .replace(/&/g, '-')             // Replace & with '-'
                .replace(/[^\w\-]+/g, '-')      // Replace all non-word with -
                .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                .replace(/^-+/, '')             // Trim - from start of text
                .replace(/-+$/, '')             // Trim - from end of text
    };


    /**
     * function toPlural - Convert a string to a plurial string
     * @param {string} str - String to convert
     * @param {number} count - Count value
     * @return {string}
     */
    public static toPlural(str: string, count: any): string {
        var index = count > 1 ? 1 : 0;
        count = String(count);

        str = str.replace(/\[count\]/, count);
        str = str.replace(/{(.[^}]*)}/g, function (wholematch, match) {
            var values = match.split('|');
            return values[index] || '';
        });

        return str;
    };

    /**
     * function convertAccent - Convert accent in a string to its non accent caracter
     * @param {string} str - String to convert
     * @return {string}
     */
    @deprecated('convertAccent is deprecated -> please use StringUtils.normalize()')
    public static convertAccent(str: string): string {
        return StringUtils.normalize(str);
    };

    /**
     * function normalize - Remove all language accents
     * @param {string} str - String to convert
     * @return {string}
     */
    public static normalize(str: string): string {
        const letterMap = {
            'a': 'ãàáäâå',  'A': 'ÃÀÁÄÂÅ',
            'c': 'ç',       'C': 'Ç',
            'e': 'ẽèéëê',   'E': 'ẼÈÉËÊ',
            'g': 'ǵ',       'G': 'Ǵ',
            'h': 'ḧ',       'H': 'Ḧ',
            'i': 'ìíïî',    'I': 'ÌÍÏÎ',
            'm': 'ḿ',       'M': 'Ḿ',
            'n': 'ñńǹ',     'N': 'ÑŃǸ',
            'o': 'øðõòóöô', 'O': 'ØÐÕÒÓÖÔ',
            'p': 'ṕþ',      'P': 'ṔÞ',
            'r': 'ŕ',       'R': 'Ŕ',
            's': 'śß',      'S': 'Ś',
            'u': 'ùúûǘ',    'U': 'ÙÚÛǗ',
            'w': 'ẃ',       'W': 'Ẃ',
            'y': 'ýÿ',      'Y': 'ÝŸ',
            'x': 'ẍ',       'X': 'Ẍ',
            'z': 'ź',       'Z': 'Ź',
            'ae': 'æ',      'AE': 'Æ',
            'oe': 'œ',      'OE': 'Œ'
        }

        for (let letter in letterMap) {
            let group = letterMap[letter];
            group = group.length > 1 ? '[' + group + ']' : group;
            str = str.replace(new RegExp(group, 'g'), letter);
        }

        return str;
    };

    /**
     * function contains - Determines whether the specified string contains text.
     * @param {string} str -String
     * @param {string} needle -String to search
     * @return {boolean}
     *   StringUtils.contains('Il y a 1 cheval', 'cheval')
     */
    public static contains(str: string, needle: string): boolean {
        return (needle === undefined) ? false : str.indexOf(needle) !== -1;
    };


    /**
     * function hasText - Determines whether the specified string contains text.
     * @param {string} str - String
     * @return {boolean}
     */
    public static hasText(str: string): boolean {
        return !!str.length;
    };

    /**
     * function stripTags - Remove's all < and > tags
     * @param {string} str - String
     * @return {string}
     */
    public static stripTags(str: string): string {
        return str.replace(/<\/?[^>]+>/igm, '');
    };

    /**
     * function trim - Removes whitespace from the front and the end of the specified
     * @param {string} str - String
     * @return {string}
     */
    public static trim(str: string): string {
        return str.replace(/^\s+|\s+$/g, '');
    };

    /**
     * function wordCount - Determine the number of words in a string.
     * @param {string} str - String
     * @return {number}
     */
    public static wordCount(str: string): number {
        return str.match(/\b\w+\b/g).length;
    };

    /**
     * function charCount - Determine the number of caracters in a string.
     * @param {string} String
     * @return {number}
     */
    public static charCount(str: string): number {
        return str.length;
    };

    /**
     * function capitalizeFirst - Capitalise first letter
     * @param {string} str - String to manipulate
     * @return {string}
     */
    public static capitalizeFirst(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    /**
     * function Truncate Truncate a string according to a length
     * @param {string} str - String to convert
     * @param {number} count - Count value
     * @param {boolean} [useWordBoundary=false] - Truncate to next word
     * @return {string}
     */
    public static truncate(str: string, count: number, useWordBoundary: boolean = false): string {
        let toLong = str.length > count,
            s = toLong ? str.substr(0, count - 1) : str;

        s = useWordBoundary && toLong ? s.substr(0, s.lastIndexOf(' ')) : s;
        return toLong ? s + '&hellip;' : s;
    };
}
