import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import Context from '../vendors/frontools/src/core/Context';
import CookieUtils from '../vendors/frontools/src/utils/CookieUtils';

declare const TweenMax;
declare const Expo;

export class SplashScreen {

    private _cookieName : string = 'bcfLogoAnimation';
    private _cookieExpirationDays: number = 1; // 24 hours

    private _logoButton : HTMLElement;
    private _tagline : HTMLElement;
    private _mainNavLinks : NodeListOf<HTMLElement>;
    
    constructor(private _element: HTMLElement) {

        this._logoButton = this._element.querySelector('[data-nav-logo]');
        this._mainNavLinks = this._element.querySelectorAll('[data-main-nav-ul] > li');

        const cookie = CookieUtils.get(this._cookieName);        

        if (!cookie) {
            this._animate();    
        } else {
            this._hide();
        }
    }

    private _hide() {
        this._element.classList.remove('splash-screen-is-visible');
    }

    private _animate() {
        this._element.classList.add('splash-screen-is-visible');

        this._tagline = document.createElement('div');
        this._tagline.classList.add('logo__tagline');
        const template = taglineTemplate[Context.getInstance().language];

        if (!template) {
            this._onAnimateComplete();
            return;
        }
        
        this._tagline.innerHTML = template;
        TweenMax.set(this._tagline.querySelector('svg'), { overflow: 'visible'});   
        TweenMax.set(this._tagline.querySelectorAll('svg > *'), { opacity: 0});   

        const paths = this._logoButton.querySelectorAll('path');
        TweenMax.set(paths, { opacity: 0}); 

        this._logoButton.appendChild(this._tagline);
        // bcf logo
        TweenMax.set(this._logoButton, { visibility: 'visible'});
        TweenMax.staggerTo(paths, 0.3, {delay: 0.6, opacity: 1}, 0.08,
            () => {
                
            }
        );

        // tagline
        TweenMax.staggerFromTo(
            this._tagline.querySelectorAll('svg > *'), 
            0.4, 
            {opacity: 0, x: 5,y:-5}, 
            {delay: .8, repeatDelay: 0.8, x:0,y:0, opacity: 1,yoyo: true, repeat: 1, ease: Expo.easeInOut}, 
            -0.05, 
            this._onAnimateComplete);
    }

    @autobind
    private _onAnimateComplete() {  
        if (this._tagline.innerHTML) {
            this._logoButton.removeChild(this._tagline);
        }

        // we set a cookie for 1 day (24 hours)
        CookieUtils.set(this._cookieName, true, this._cookieExpirationDays);
        
        TweenMax.staggerFromTo(this._mainNavLinks, 0.25, {opacity: 0, x:-20}, {opacity: 1, x:0, clearProps: 'all'}, 0.1);
        this._element.classList.remove('splash-screen-is-visible');
    }

}

const taglineTemplate = { 
    fr: `
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 153.2 43.5" style="enable-background:new 0 0 153.2 43.5;" xml:space="preserve">
            <path d="M151.2,33.8c-1.5-1-3.2-1.3-5-1.5c-1.1-0.1-3.8-0.2-4.4-1.4c-0.6-1.3,1.5-1.7,2.2-1.8c2.1-0.2,4.4,0.4,5.8,2
                c1-0.9,1.9-1.8,2.9-2.7c-1.8-2.5-5.3-3.7-8.3-3.5c-3.3,0.2-6.8,2-6.8,5.7c0,3.3,3.1,4.9,6,5.3c1.5,0.2,3.2,0.2,4.6,0.8
                c1.1,0.5,1.1,1.6,0,2.2c-1.8,1-5.8,0.8-7.3-1.7l-3.5,2.2c1.7,2.7,4.8,4.1,7.9,4.2c2.7,0.1,5.7-0.8,7.1-3.3
                C153.7,37.9,153.3,35.3,151.2,33.8z"/>
            <polygon points="121.2,43.3 134.8,43.3 134.8,39 125.5,39 125.5,36.4 132.5,36.4 132.5,32 125.5,32 125.5,29.4 134.8,29.4 
                134.8,25.1 121.2,25.1 "/>
            <path d="M116.7,34c0.7-0.7,1.5-1.9,1.5-3.6c0-3-2.4-5.3-5.5-5.3h-9.1v18.2h4.2v-7.2h2.2l4.1,7.2h4.6l-4.3-7.8
                C115.8,34.9,116.7,34,116.7,34z M112.7,31.9h-4.9v-2.5h4.9c0.7,0,1.3,0.6,1.3,1.3C113.9,31.3,113.4,31.9,112.7,31.9z"/>
            <rect x="95.5" y="25.1" width="4.3" height="18.2"/>
            <path d="M81,25.1l-7.9,18.2h4.7l2.2-5h6.4l2.2,5h4.6l-7.6-18.2H81z M81.7,34.5l1.5-4.2l1.5,4.2H81.7z"/>
            <polygon points="65.2,29.3 74,29.3 74,25.1 60.9,25.1 60.9,43.3 65.2,43.3 65.2,37 71.8,37 71.8,32.7 65.2,32.7 "/>
            <polygon points="45.7,43.3 49.9,43.3 49.9,37 56.5,37 56.5,32.7 49.9,32.7 49.9,29.3 58.8,29.3 58.8,25.1 45.7,25.1 "/>
            <path d="M30.9,25.1L23,43.3h4.7l2.2-5h6.4l2.2,5h4.6l-7.6-18.2H30.9z M31.7,34.5l1.5-4.2l1.5,4.2H31.7z"/>
            <polygon points="19.2,28.2 20.3,28.2 20.7,30.1 22.2,30.1 22.2,28.2 22.2,27.7 22.2,25.1 19.2,25.1 "/>
            <path d="M8.6,25.1H1.1v18.2h7.5c5,0,9-4,9-9S13.5,25.1,8.6,25.1z M8.6,39.1H5.3v-9.8h3.3c2.7,0,4.8,2.3,4.8,5
                C13.4,37,11.2,39.1,8.6,39.1z"/>
            <path d="M128.2,15.1c1.2-2.3,0.8-4.9-1.3-6.4c-1.5-1-3.2-1.3-5-1.5c-1.1-0.1-3.8-0.2-4.4-1.4c-0.6-1.3,1.5-1.7,2.2-1.8
                c2.1-0.2,4.4,0.4,5.8,2c1-0.9,1.9-1.8,2.9-2.7c-1.8-2.5-5.3-3.7-8.3-3.5c-3.3,0.2-6.8,2-6.8,5.7c0,3.3,3.1,4.9,6,5.3
                c1.5,0.2,3.2,0.2,4.6,0.8c1.1,0.5,1.1,1.6,0,2.2c-1.8,1-5.8,0.8-7.3-1.7l-3.5,2.2c1.7,2.7,4.8,4.1,7.9,4.2
                C123.9,18.5,126.9,17.7,128.2,15.1z"/>
            <polygon points="104.8,18.2 104.8,3.9 111.3,3.9 111.3,0 94.2,0 94.2,3.9 100.6,3.9 100.6,18.2 "/>
            <path d="M83,0l-7.9,18.2h4.7l2.2-5h6.4l2.2,5h4.6L87.5,0C87.5,0,83,0,83,0z M83.7,9.3l1.5-4.2l1.5,4.2H83.7z"/>
            <path d="M73.8,15.7l0.7-0.7l-3-3l-0.7,0.7c-1,1-2.3,1.5-3.6,1.5c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1
                c1.4,0,2.7,0.5,3.6,1.5l0.7,0.7l3-3l-0.7-0.7c-1.8-1.8-4.1-2.8-6.6-2.8c-5.2,0-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4
                C69.7,18.5,72,17.5,73.8,15.7z"/>
            <path d="M45.9,18.4c5.3,0,9.7-4.2,9.7-9.3s-4.3-9.3-9.7-9.3c-5.3,0-9.7,4.2-9.7,9.3S40.5,18.4,45.9,18.4z M45.9,3.9
                c3,0,5.4,2.3,5.4,5.2s-2.4,5.2-5.4,5.2s-5.4-2.3-5.4-5.2S42.9,3.9,45.9,3.9z"/>
            <polygon points="26.7,12.5 21.7,0 17,0 24.4,18.2 29,18.2 36.4,0 31.7,0 "/>
            <path d="M6.9,13.1h6.4l2.2,5h4.6L12.4,0H7.9L0,18.2h4.7L6.9,13.1z M10,5.2l1.5,4.2h-3L10,5.2z"/>
        </svg>
    `,
    en: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 153.2 43.5" style="enable-background:new 0 0 153.2 43.5;" xml:space="preserve">
       <polygon points="55.4,43.2 59.6,25.2 55.3,25.2 52.6,36.7 49.8,25.2 44.1,25.2 41.2,36.7 38.5,25.2 34.2,25.2 38.5,43.2 
           43.9,43.2 46.9,31 50,43.2 		"/>
       <path d="M30.7,43.2h4.6l-7.7-18h-4.5l-7.7,18H20l2.2-5h6.3L30.7,43.2z M26.8,34.4h-2.9l1.5-4.1L26.8,34.4z"/>
       <polygon points="0,25.2 0,43.2 13.4,43.2 13.4,38.9 4.3,38.9 4.3,25.2 		"/>

       <path d="M131.8,12.8c0-3.2-2.5-4.8-5.9-5.3c-1.5-0.2-3.1-0.2-4.5-0.8c-1.1-0.5-1.2-1.5-0.2-2.1c1.8-1,5.7-0.7,7.3,1.7l3.5-2.2
           c-1.7-2.7-4.8-4.1-7.9-4.1c-2.7-0.1-5.7,0.8-7,3.3c-0.4,0.8-0.7,1.6-0.7,2.3c0,3.3,3,4.9,5.9,5.3c1.5,0.2,3.2,0.2,4.6,0.8
           c1.1,0.5,1.1,1.6,0,2.2c-1.8,1-5.7,0.8-7.2-1.7l-3.5,2.2c1.7,2.7,4.8,4.1,7.9,4.1c2.7,0.1,5.7-0.8,7-3.3
           C131.6,14.5,131.8,13.6,131.8,12.8z"/>
       <path d="M114.1,12.8c0-3.2-2.5-4.8-5.9-5.3c-1.5-0.2-3.1-0.2-4.5-0.8c-1.1-0.5-1.2-1.5-0.2-2.1c1.8-1,5.7-0.7,7.3,1.7l3.5-2.2
           c-1.7-2.7-4.8-4.1-7.9-4.1c-2.7-0.1-5.7,0.8-7,3.3c-0.4,0.8-0.7,1.6-0.7,2.3c0,3.3,3,4.9,5.9,5.3c1.5,0.2,3.2,0.2,4.6,0.8
           c1.1,0.5,1.1,1.6,0,2.2c-1.8,1-5.7,0.8-7.2-1.7l-3.5,2.2c1.7,2.7,4.8,4.1,7.9,4.1c2.7,0.1,5.7-0.8,7-3.3
           C113.9,14.5,114.1,13.6,114.1,12.8z"/>
       <polygon points="82.9,18.3 96.3,18.3 96.3,14 87.2,14 87.2,11.4 94,11.4 94,7.2 87.2,7.2 87.2,4.6 96.3,4.6 96.3,0.3 82.9,0.3 "/>
        <polygon points="75.1,11.4 65.8,0.3 61.5,0.3 61.5,18.3 65.8,18.3 65.8,7 75.1,18.3 79.4,18.3 79.4,0.3 75.1,0.3 			"/>
       <rect x="53.7" y="0.3" width="4.3" height="18"/>
       <path d="M50.9,12.8c0-3.2-2.5-4.8-5.9-5.3c-1.5-0.2-3.1-0.2-4.5-0.8c-1.1-0.5-1.2-1.5-0.2-2.1c1.8-1,5.7-0.7,7.3,1.7l3.5-2.2
       c-1.7-2.7-4.8-4.1-7.9-4.1c-2.7-0.1-5.7,0.8-7,3.3c-0.4,0.8-0.7,1.6-0.7,2.3c0,3.3,3,4.9,5.9,5.3c1.5,0.2,3.2,0.2,4.6,0.8
       c1.1,0.5,1.1,1.6,0,2.2c-1.8,1-5.7,0.8-7.2-1.7l-3.5,2.2c1.7,2.7,4.8,4.1,7.9,4.1c2.7,0.1,5.7-0.8,7-3.3
       C50.6,14.5,50.9,13.6,50.9,12.8z"/>
       <path d="M17.5,11.1c0,4.4,3.2,7.5,7.7,7.5c4.6,0,7.7-3.1,7.7-7.5V0.3h-4v10.5c0,1.8-1,3.7-3.7,3.7c-2.7,0-3.7-1.9-3.7-3.7V0.3
           h-4V11.1z"/>
       <path d="M9.1,0.3H0v18h9.1c3.1,0,5.5-2.4,5.5-5.4c0-1.8-0.8-2.9-1.5-3.7c0.7-0.7,1.5-1.9,1.5-3.7C14.6,2.6,12.2,0.3,9.1,0.3z
               M9.1,14H4.2v-2.6h4.9c0.7,0,1.3,0.6,1.3,1.3C10.4,13.4,9.8,14,9.1,14z M9.1,7.2H4.2V4.6h4.9c0.7,0,1.3,0.6,1.3,1.3
           C10.4,6.6,9.8,7.2,9.1,7.2z"/>
       </svg>`
}
