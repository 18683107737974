import { ImageSlide } from "./ImageSlide";
import { Carousel } from './Carousel';
import { Nav } from './Nav';
import { Footer } from './Footer';
import { Toggle } from './Toggle';
import { Tooltip } from './Tooltip';
import { Cta } from './Cta';
import { Shortlist } from './Shortlist';
import { Video } from './Video';    
import { Background } from './Background';
import { ToolBar } from './ToolBar';
import { Overlay } from './Overlay';
import { Search } from './Search';
import { Employee } from './Employee';
import { Gdpr } from "./Gdpr";
import { Form } from './Form';
import { OverlayIframe } from "./OverlayIframe";

export const Modules = {
    "nav" : Nav,
    "carousel" : Carousel,
    "image-slide" : ImageSlide,
    "footer" : Footer,
    "cta" : Cta,
    "shortlist" : Shortlist,
    "video" : Video,
    "background" : Background,
    "overlay" : Overlay,
    "overlay-iframe" : OverlayIframe,
    "tool-bar" : ToolBar,
    "search" : Search,
    "employee": Employee,
    "gdpr": Gdpr,
    "form": Form,
    "toggle": Toggle,
    "tooltip": Tooltip,
}
