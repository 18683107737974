/**
 * VanillaUtils
 * (c) lg2fabrique 2016
 *
 */

export default class VanillaUtils {

     /**
     * Helper to add an event listener that works in IE8, same as jQuery $.on()
     * @param {DOMElement} Element to bind event
     * @param {String} Event type
     * @param {Funtion} Callback function
     *    VanillaUtils.addEvent(input, 'change', updateUpload);
    */
    public static addEvent(el: HTMLElement, type: string, handler: EventListener) {
        if ((el as any).attachEvent) {
            (el as any).attachEvent('on' + type, handler)
        } else {
            el.addEventListener(type, handler);
        }
    };

    /**
     * Helper to remove an event listener that works in IE8, same as jQuery $.off()
     * @param {DOMElement} Element to bind event
     * @param {String} Event type
     * @param {Funtion} Callback function
     *    VanillaUtils.removeEvent(input, 'change', updateUpload);
    */
    public static removeEvent(el: HTMLElement, type: string, handler: EventListener) {
        if ((el as any).detachEvent) {
            (el as any).detachEvent('on' + type, handler)
        } else {
            el.removeEventListener(type, handler);
        }
    };

    /**
     * Helper to check if an element has a class that works in IE8-9, same as jQuery $.hasClass()
     * @param {HTMLElement} Element to check
     * @param {String} Class name
     *    VanillaUtils.hasClass(input, 'active');
    */
    public static hasClass(el: HTMLElement, className: string) {
        return el.classList ?
            el.classList.contains(className) :
            new RegExp('\\b'+ className+'\\b').test(el.className);
    };

    /**
     * Helper to add a class to a DOM element that works in IE8-9, same as jQuery $.addClass()
     * @param {HTMLElement} Element to add class
     * @param {String} Class name
     *    VanillaUtils.addClass(input, 'active');
    */
    public static addClass(el: HTMLElement, className: string) {
        if (el.classList) {
            el.classList.add(className);
        } else if (!VanillaUtils.hasClass(el, className)) {
            el.className += ' ' + className;
        }
    };

    /**
     * Helper to remvoe a class to a DOM element that works in IE8-9, same as jQuery $.removeClass()
     * @param {HTMLElement} Element to remove class
     * @param {String} Class name
     *    VanillaUtils.removeClass(input, 'active');
    */
    public static removeClass(el: HTMLElement, className: string) {
        if (el.classList) {
            el.classList.remove(className);
        } else {
          el.className = el.className.replace(new RegExp('\\b'+ className+'\\b', 'g'), '');
        }
    };

    /**
     * Get index of element in relation with its siblings
     * @param {HTMLElement} el - Element for which an index is required
     * @return {Number} Index value
     *    VanillaUtils.getSiblingIndex(document.querySelector('.item2');
    */
    public static getSiblingIndex(el: HTMLElement) {
        return Array.prototype.indexOf.call(el.parentNode.childNodes, el);
    };

    /**
     * Get element offset relative to document position
     * @param {HTMLElement} el - Element for which an index is required
     * @return {Object} {top, left} - Offset properties
     *    VanillaUtils.getOffsetRect(document.querySelector('.item2');
    */
    public static getOffsetRect(el: HTMLElement) {
        let box = el.getBoundingClientRect(),
            body = document.body,
            docEl = document.documentElement,
        
            scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop,
            scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft,
        
            clientTop = docEl.clientTop || body.clientTop || 0,
            clientLeft = docEl.clientLeft || body.clientLeft || 0,
        
            top  = box.top +  scrollTop - clientTop,
            left = box.left + scrollLeft - clientLeft;
        
        return { top: Math.round(top), left: Math.round(left) }
    }

}
