
/**
 * ColotUtils
 * (c) lg2fabrique 2017
 *
 */

export default class ColorUtils {

    /**
     * rgb2hex - Convert rgba string to hex value
     * @param {Number} min Minimum number
     * @param {Number} max Maximum number
     * @param {Boolean} [rounded=true] Makes sure the result will be an integer
     *
     * @return {Number}
     *   ColotUtils.random(2, 10);
     */
    public static rgb2hex(color) {
        let rgb = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i );
        return (rgb && rgb.length === 4) ? '0x'+
            ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
    }


    /**
     * hex2RGB - Convert a hex to rgb
     * @param {string} min Minimum number
     *
     * @return {Number}
     *   ColotUtils.random(2, 10);
     */
    public static hex2rgb(hex) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }


    public static getBrightness(rgb: Object) {
        return (rgb['r'] * 299 + rgb['g'] * 587 + rgb['b'] * 114) / 1000;
    }

    public static isDark(rgb: Object) {
        return ColorUtils.getBrightness(rgb) < 128;
    }

    public static isLight(rgb: Object) {
        return !ColorUtils.isDark(rgb);
    }



}