/**
 * Light Template Engine
 * (c) lg2fabrique 2016
 *
    var template = new Template(document.getElementById('template'), {
        titre:'Le titre',
        paragraphe:'Le paragraphe',
        url:'http://www.lg2.com'
    });
    document.body.appendChild(template.htmlAsDOM);
    document.body.innerHTML = template.htmlAsString;
 */

import StringUtils from '../utils/StringUtils';

export default class Template {

    public static settings = {
        evaluate    : /<%([\s\S]+?)%>/g,        //<% value %>
        interpolate : /<%=([\s\S]+?)%>/g,       //<%= value %>
        escape      : /<%-([\s\S]+?)%>/g        //<%- value %>
    };

    private _html: string = '';

    /**
     * constructor
     * @param {HTMLElement} Html (document.getElementById('template')
     * @param {Object} Data properties
     */
    constructor(template:any, data:Object) {

        //get html
        this._html = template.innerHTML ? template.innerHTML : template;

        //template add and escape values
        this._html = this._html.replace(Template.settings.escape, function(match, token) {
            token = StringUtils.trim(token);
            return encodeURI(data[token] === undefined ? '' : data[token]);
        }.bind(this));

        //template add validate values
        this._html = this._html.replace(Template.settings.interpolate, function(match, token) {
            token = StringUtils.trim(token);
            return data[token] === undefined ? '' : data[token];
        }.bind(this));

        //template add straight values
        this._html = this._html.replace(Template.settings.evaluate, function(match, token) {
            token = StringUtils.trim(token);
            return data[token];
        }.bind(this));

    }

    /**
     * Get template as String
     * @return {String}
     */
    public get htmlAsString(): string {
        return this._html;
    }

    /**
     * Get template as DOM element
     * @return {HTMLElement}
     */
    public get htmlAsDOM(): HTMLElement {
        var div = document.createElement('div');
        div.innerHTML = this._html;
        return div;
    }

}