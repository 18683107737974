/**
 * DocumentUtils
 * (c) lg2fabrique 2016
 *
 */

export default class DocumentUtils {

    /**
     * openPopup - Open a window popup
     * @param {String} link - Url to open
     * @param {Number} width - Window width
     * @param {Number} height - Window height
       *    DocumentUtils.openPopup('http://www.lg2.com', 500, 500);
    */
    public static openPopup(link: string, width: number = 500, height: number = 500) {
        var left = (screen.width - width) / 2,
            top = (screen.height - height) / 2,
            params = {
                width: width, height: height, top: top, left: left,
                toolbar: 'no', location: 'no', directories: 'no', status: 'no',
                menubar: 'no', resizable: 'no', copyhistory: 'no', scrollbars: 'yes'
            },
            arr = [];

            for (var key in params)  {
                if (params.hasOwnProperty(key)) {
                    arr.push(key +'='+ params[key]);
                }
            }
            let target = window.open(link, '', arr.join());

        //old ie don't have .moveTo
        try{ target.moveTo(left, top);}
        catch(e){}

        return target;
    };

    /**
     * Returns a function, that, as long as it continues to be invoked, will not be triggered.
     * @param {Function} callback - Callback function
     * @param {Number} delay - Throttle delay in milliseconds
     * @param {Boolean} callOnInit - Execute also the callback on init
        *
            var resize = DocumentUtils.debounce(function() {
	           // All the taxing stuff you do
            }, 250);
            window.addEventListener('resize', resize);
    */
    public static debounce(callback: Function, delay: number, callOnInit: boolean = false) {
        var timeout;

        if (callOnInit) callback.apply(null, arguments);

        return function() {
            var context = this,
                args = arguments,
                later = function() {
                    timeout = null;
                    callback.apply(context, args);
                };

            clearTimeout(timeout);
            timeout = setTimeout(later, delay);
        };

    };

}
