declare const dataLayer;

export class Tracker {

    private static _isVerbose: boolean = false;
    public static SOCIAL_EVENT: string = 'socialInteraction';
    public static VIRTUAL_PAGEVIEW: string = 'virtualPageview';
    public static GENERIC_EVENT: string = 'genericGAEvent';

    constructor() {}

    /**
     * virtualPageView - Sends a virtualPageView to GTM
     *
     * @static
     * @param {GAEventPageview} eventData - Event to be tracked as a virtualPageview
     * @memberof Tracker
     */
    public static virtualPageView(eventData: GAEventPageview) {
        eventData.event = Tracker.VIRTUAL_PAGEVIEW;
        this.event(eventData);
    }

    /**
     * social - Sends a social event to GTM
     * This data structure is as requested by Raph Caligaro
     *
     * @static
     * @param {(GAEventSocial)} eventData - GAEvent Object
     * @memberof Tracker
     */
    public static social(eventData: GAEventSocial) {
        eventData.event = Tracker.SOCIAL_EVENT;
        
        let socialData: GAEventSocial = {
            ...eventData,
            socialNetwork: eventData.eventCategory,
            socialAction: eventData.eventAction,
            socialTarget: eventData.eventLabel
        };

        this.push(socialData);
    }

    /**
     * event - Sends an event to GTM
     *
     * @static
     * @param {(GAEvent)} eventData - GAEvent Object
     * @memberof Tracker
     */
    public static event(eventData: GAEvent | GAEventSocial | GAEventPageview) {
        // we add event if not present
        if (typeof eventData['event'] === 'undefined') {
            eventData.event = Tracker.GENERIC_EVENT;
        }

        this.push(eventData);
    }

    /**
     * parseEventString - Parse a pipe separated string into a GAEvent object
     *
     * @static
     * @param {string} eventData - string separated with pipes
     * @returns {GAEvent}
     * @memberof Tracker
     */
    public static parseEventString(eventData: string): GAEvent {
        if (!eventData.length) {
            throw new Error('No string to parse');
        }

        let splitData = eventData.split('|');

        // If missing parameters, we rejectempty string, do not track
        if (splitData.length < 2) {
            throw new Error('A minimum of 2 parts string required.');
        }

        return {
            eventCategory: splitData[0],
            eventAction: splitData[1],
            eventLabel: splitData[2] || '',
            event: Tracker.GENERIC_EVENT
        };
    }

    /**
     * push - pushes the data to the dataLayer
     *
     * @static
     * @param {GA} trackingData
     * @memberof Tracker
     */
    private static push(trackingData) {
        dataLayer.push(trackingData);

        if (Tracker._isVerbose) {
            console.log('Tracker => ', trackingData);
        }
    }
}

interface GAEvent {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: number;
    event?: string;
}

interface GAEventPageview {
    uri: string;
    event?: string;
}

interface GAEventSocial extends GAEvent {
    socialNetwork: string;
    socialAction: string;
    socialTarget: string;
    event?: string;
}
