/**
 * Decorator - Add delay to a function call
 * @param {number} Delay in milliseconds
 */

export function timeout(delay:number = 500) {

    return function(target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) {

        var original = descriptor && descriptor.value;
        if (typeof original !== 'function' || original == undefined) return;
        
        descriptor.value = function() { 
            var args = arguments;
            setTimeout(function() {
                original.apply(this, args);    
            }.bind(this), delay);
        }

        return descriptor;
    }
    
}