/**
 * Disqus
 * (c) lg2fabrique 2016
 *
 var disqus = new Disqus({
    shortname:'disqus_NAME',
    identifier:'disqus_THREAD_ID',
    title:'disqus_THREAD_TITLE',
    url:'disqus_THREAD_URL',
    category_id:'disqus_THREAD_CATEGORY',
    disable_mobile:false,
    config:function() {
        this.language = 'en';
        this.callbacks.onNewComment = [function(comment) {}];
    }
});
 */

export default class Disqus {

    private static _instance: Disqus;

    /**
     * constructor
     */
    constructor(obj:IDisqus) {

        //singleton validation
        if(Disqus._instance) throw new Error('Error: Use Disqus.getInstance() instead of new.');
        Disqus._instance = this;

        //configuration
        if(obj.shortname) (window as any).disqus_shortname = obj.shortname;
        if(obj.identifier) (window as any).disqus_identifier = obj.identifier;
        if(obj.title) (window as any).disqus_title = obj.title;
        if(obj.url) (window as any).disqus_url = obj.url;
        if(obj.category_id) (window as any).disqus_category_id = obj.category_id;
        if(obj.disable_mobile) (window as any).disqus_disable_mobile = obj.disable_mobile;
        if(obj.config) (window as any).disqus_config = obj.config;

        //sdk
        var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;
        dsq.src = '//' + obj.shortname + '.disqus.com/embed.js';
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);

        //fix ios bug
        if(window.addEventListener){
            window.addEventListener('orientationchange', function(){
                if (typeof((window as any).DISQUS) !== 'undefined') {
                    (window as any).DISQUS.reset({
                        reload: true
                    });
                }
            });
        }

        console.info('DISQUS:', obj);
    }

    /**
     * get singleton instance
     * @returns {Disqus}  instance's Disqus
     */
    public static getInstance(): Disqus {
        return Disqus._instance;
    }
}

//URI Disqus
export interface IDisqus{
    shortname: string;
    identifier: string;
    title: string;
    url: string;
    category_id: string;
    disable_mobile: boolean;
    config: Function;
}
