import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import OverlayAbstract from '../Abstract/OverlayAbstract';
import Facebook from '../vendors/frontools/src/social/Facebook';
import LinkedIn from '../vendors/frontools/src/social/LinkedIn';
import Twitter from '../vendors/frontools/src/social/Twitter';

declare const TweenMax;
declare const Expo;
declare const dataLayer;

export class Nav extends OverlayAbstract {

    private _insideNav : boolean = false;
    private _subOpened : boolean = false;
    private _breadcrumbOpened : boolean = false;

    private _subNavMenus : NodeListOf<HTMLElement>; 
    private _mainNavLinks : NodeListOf<HTMLElement>;
    private _logoButton : HTMLElement;
    private _currentSub : HTMLElement;
    private _breadCrumb : HTMLElement;
    private _copySharer : HTMLElement;
    private _elScrollingElement: Element;
    private _facebookSharer : HTMLElement;
    private _twitterSharer : HTMLElement;
    private _linkedinSharer : HTMLElement;
    
    constructor(protected _element: HTMLElement) {
        super(_element);

        this._logoButton = this._element.querySelector('[data-nav-logo]');
        this._breadCrumb = this._element.querySelector('[data-nav-breadcrumb]');
        this._mainNavLinks = this._element.querySelectorAll('[data-main-nav-ul] > li');
        this._facebookSharer = this._element.querySelector('[data-nav-facebook-sharer]');
        this._twitterSharer = this._element.querySelector('[data-nav-twitter-sharer]');
        this._linkedinSharer = this._element.querySelector('[data-nav-linkedin-sharer]');
        this._copySharer = this._element.querySelector('[data-nav-copy-sharer]');
        this._subNavMenus = this._element.querySelectorAll('[data-nav-sub-menu]');

        this._element.classList.add('is-ready');

        this._elScrollingElement = document.scrollingElement || document.documentElement;
    }

    public open() {
        super.open();

        document.documentElement.classList.add('nav-is-open');
    }
    
    public close() {
        super.close();
        
        this._closeSubMenu();
        document.documentElement.classList.remove('nav-is-open');
    }

    public onGroupMobile() {
        this.destroyDesktop();

        if (this._logoButton) this._logoButton.addEventListener('click', this._onMobileLogoClick);
        for (let i = 0, l = this._subNavMenus.length; i < l; i++) {
            let button = this._subNavMenus[i].querySelector('[data-nav-sub-button]');
            if (button) button.addEventListener('click', this._onSubButtonClick);
        }
    }

    public onGroupDesktop() {
        this.destroyMobile();
        
        if (this._breadCrumb) {
            window.addEventListener('scroll', this._onScroll);
            
            this._element.addEventListener('mouseenter', () => {this._insideNav = true});
            this._element.addEventListener('mouseleave', () => {this._insideNav = false});
            
            if (this._logoButton) this._logoButton.addEventListener('click', this._onDesktopLogoClick);
            if (this._facebookSharer) this._facebookSharer.addEventListener('click', this._onFacebookSharerClick);
            if (this._linkedinSharer) this._linkedinSharer.addEventListener('click', this._onLinkedinSharerClick);
            if (this._twitterSharer) this._twitterSharer.addEventListener('click', this._onTwitterSharerClick);
            if (this._copySharer) this._copySharer.addEventListener('click', this._onCopySharerClick);
        }
    }
    
    
    @autobind
    private _onScroll(e) {
        if (this._insideNav) return;

        const scrollTop = this._elScrollingElement.scrollTop;
        
        if (scrollTop >= 10 && !this._breadcrumbOpened) {
            window.removeEventListener('scroll', this._onScroll);
            this._showBreadcrumb();
        } else if (this._breadcrumbOpened && scrollTop < 10) {
            window.removeEventListener('scroll', this._onScroll);
            this._hideBreadcrumb();
        }
    }

    @autobind
    private _showBreadcrumb() {        
        this._element.classList.add('is-animating');

        TweenMax.staggerFromTo(this._mainNavLinks, 0.1, {opacity: 1, x:0}, {opacity: 0, x:-20}, -0.1, 
            () => { 
                this._animLogoOut();
                this._breadcrumbOpened = true;
                window.addEventListener('scroll', this._onScroll);
                this._element.classList.add('breadcrumb-is-opened'); 
                this._element.classList.remove('is-animating');
                TweenMax.staggerFromTo(this._breadCrumb.querySelectorAll('button, a'), 0.4, {opacity: 0, x:-20}, { delay: 0.6,opacity: 1, x:0, clearProps: 'all'}, 0.1);
        });
    }

    private _animLogoOut() {
        TweenMax.staggerFromTo(this._logoButton.querySelectorAll('[data-nav-logo-letter]'), 0.1, {opacity: 1,x:0}, { opacity:0, x:-20,}, -0.1);
    }

    private _animLogoIn() {
        TweenMax.staggerFromTo(this._logoButton.querySelectorAll('[data-nav-logo-letter]'), 0.2, {opacity: 0,x:-20}, {delay: 0.3,  opacity:1, x:0}, 0.1);
    }

    @autobind
    private _hideBreadcrumb() {
        this._element.classList.add('is-animating');
        TweenMax.staggerFromTo(this._breadCrumb.querySelectorAll('button, a'), 0.15, {opacity: 1}, {opacity: 0, x: -20}, 0.1, 
            () => { 
                this._element.classList.remove('breadcrumb-is-opened');
                this._animLogoIn();

                TweenMax.staggerFromTo(this._mainNavLinks, 0.15, {opacity: 0, x:-20}, {delay: 0.6, opacity: 1, x:0, clearProps: 'all'}, 0.1,
                    () => {
                        this._breadcrumbOpened = false;
                        this._element.classList.remove('is-animating');
                        window.addEventListener('scroll', this._onScroll);
                    }
                );
            }
        );

    }

    public destroyMobile() {
        super.destroy();

        if (this._logoButton) this._logoButton.removeEventListener('click', this._onMobileLogoClick);

        for (let i = 0, l = this._subNavMenus.length; i < l; i++) {
            let button = this._subNavMenus[i].querySelector('[data-nav-sub-button]');
            if (button) button.removeEventListener('click', this._onSubButtonClick);
        }
    }

    public destroyDesktop() {
        super.destroy();

        TweenMax.set(this._mainNavLinks, {clearProps:'all'});
        
        if (this._breadCrumb) {
            window.removeEventListener('scroll', this._onScroll);
            if (this._breadcrumbOpened) { this._animLogoIn(); }
            TweenMax.set(this._breadCrumb.querySelectorAll('a'), {clearProps:'all'});
            this._logoButton.removeEventListener('click', this._onDesktopLogoClick);
            this._facebookSharer.removeEventListener('click', this._onFacebookSharerClick);
            this._linkedinSharer.removeEventListener('click', this._onLinkedinSharerClick);
            this._copySharer.removeEventListener('click', this._onCopySharerClick);
            this._element.classList.remove('breadcrumb-is-opened');
            this._breadcrumbOpened = false;
        } 
    }

    @autobind
    private _onMobileLogoClick(e) {
        if (this._subOpened) {
            e.preventDefault();

           this._closeSubMenu();
        }
    }

    @autobind
    private _onDesktopLogoClick(e) {
        if (this._breadcrumbOpened) {
            e.preventDefault();

            this._hideBreadcrumb();
        }
    }

    @autobind
    private _onCopySharerClick(e) {
        const el = document.createElement('textarea');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this._copySharer.classList.add('is-copied');
        setTimeout(() => {
            this._copySharer.classList.remove('is-copied');
        }, 1000);

        dataLayer.push({
            eventCategory: 'Clics medias sociaux',
            eventAction: 'URL copy',
            eventLabel: 'en-tete',
            event: 'genericGAEvent'
        });
    }

    @autobind
    private _onFacebookSharerClick(e) {
        e.preventDefault();
        Facebook.sharer(window.location.href);
        dataLayer.push({
            eventCategory: 'Clics medias sociaux',
            eventAction: 'Facebook',
            eventLabel: 'en-tete',
            event: 'genericGAEvent'
        });
    }

    @autobind
    private _onTwitterSharerClick(e) {
        e.preventDefault();
        Twitter.tweet({
            url: window.location.href
        });
        dataLayer.push({
            eventCategory: 'Clics medias sociaux',
            eventAction: 'Twitter',
            eventLabel: 'en-tete',
            event: 'genericGAEvent'
        });
    } 

    @autobind
    private _onLinkedinSharerClick(e) {
        e.preventDefault();
        LinkedIn.sharer({url: window.location.href, title: '', summary: ''});
        dataLayer.push({
            eventCategory: 'Clics medias sociaux',
            eventAction: 'LinkedIn',
            eventLabel: 'en-tete',
            event: 'genericGAEvent'
        });
    } 

    private _closeSubMenu() {
        this._removeSubClass();
        if (this._subOpened) {
            this._animLogoIn();
            setTimeout(() => {
                this._removeOpenedClass(this._currentSub);
                this._subOpened = false;
            }, 300)
        }
    }

    @autobind
    private _onSubButtonClick(e) {
        e.preventDefault();

        const parent = e.target.parentNode;
        
        if (!parent) return;

        if (this._currentSub) this._removeOpenedClass(this._currentSub);

        this._currentSub = parent;

        this._subOpened = !this._subOpened; 

        if (this._subOpened) {
            this._addOpenedClass(this._currentSub);
            this._animLogoOut();
            this._addSubClass();
        } else {
            this._removeOpenedClass(this._currentSub);
            this._removeSubClass();
        }
    }

    private _removeSubClass(element = this._element) {
        element.classList.remove('sub-is-opened');
    }

    private _addSubClass(element = this._element) {
        element.classList.add('sub-is-opened');
    }
    
}
