/**
 * Abstract class as a project base
 * (c) lg2fabrique 2018
 */

import AbstractDispatcher from '../abstract/AbstractDispatcher';
import Context from '../core/Context';
import Breakpoint from '../core/Breakpoint';
import VanillaUtils from '../utils/VanillaUtils';
import Accessibility from '../utils/Accessibility';
import EventType from '../events/EventType';
import TouchFeature from '../features/TouchFeature';

declare var dataLayer: any;

export default class AbstractMain extends AbstractDispatcher {

    public static DOM_READY       : string                  = 'domready';
    public static WIN_LOAD        : string                  = 'winload';
    public static HUMAN_TOUCHED   : string                  = 'human_touched';

    protected static _instance    : AbstractMain;
    protected _context            : Context;
    protected _breakpoint         : Breakpoint;
    protected _domready           : boolean                 = false;
    protected _winload            : boolean                 = false;

    /**
     * constructor
     * @param {Object} context values
     */
    constructor(options:Object = {}) {
        super();

        // singleton validation
        if (AbstractMain._instance) {
            throw new Error('Error: Use AbstractMain.getInstance() or Main.getInstance() instead of new.');
        }
        AbstractMain._instance = this;

        // create context
        this._context = new Context(options);

        // do feature dection stuff
        this.initFeatureDetection();

        // add tab event
        Accessibility.addTabMode();

        // create breakpoint statement
        this.initBreakpoint();

        // create base for GTM tracking
        this.initGTM();

        document.addEventListener('DOMContentLoaded', this.DOMready.bind(this));
        window.onload = this.WINload.bind(this);
    };

    /**
     * DOM ready handler
     */
    protected DOMready(e = null): void {
        this._domready = true;
        this.dispatch({ type: AbstractMain.DOM_READY });
    }

    /**
     * window load handler
     */
    protected WINload(e = null){
        this._winload = true;
        if (!this._domready) {
            this.DOMready(null);
        }
        this.dispatch({ type: AbstractMain.WIN_LOAD });
    }


    /**
     * initializer of Breakpoint statement
     */
    protected initBreakpoint(): void {
        this._breakpoint = new Breakpoint({
            debug: false,
            breakpoints: [
                Breakpoint.MOBILES = 'mobiles',
                Breakpoint.MOBILE = 'mobile',
                Breakpoint.TABLETP = 'tabletp',
                Breakpoint.TABLET = 'tablet',
                Breakpoint.DESKTOP = 'desktop',
                Breakpoint.LARGE = 'large',
                Breakpoint.HD = 'hd'
            ],
            groups: {
                'group_mobile': [
                    Breakpoint.MOBILES,
                    Breakpoint.MOBILE,
                    Breakpoint.TABLETP
                ],
                'group_desktop': [
                    Breakpoint.TABLET,
                    Breakpoint.DESKTOP,
                    Breakpoint.LARGE,
                    Breakpoint.HD
                ],
            }
        });
    }

    protected initFeatureDetection(): void {
        let html = document.documentElement;

        // touch detection
        TouchFeature.instance.addListener(EventType.INTERACTION_CHANGE, e => {
            html.classList.remove(e.isTouch ? 'no-touchevents' : 'touchevents');
            html.classList.add(e.isTouch ? 'touchevents' : 'no-touchevents');
        });
    }

    /**
     * initializer of GTM data-tracking items
     */
    protected initGTM() {
        const trackingCtas: any = document.querySelectorAll('[data-tracking]');

        for (let i = 0, l = trackingCtas.length; i < l; i++) {
            trackingCtas[i].addEventListener('click', function(e) {  
                const data = trackingCtas[i].getAttribute('data-tracking').split('|');
                dataLayer.push({
                    eventCategory: data[0],
                    eventAction: data[1], 
                    eventLabel: data[2],
                    event: 'genericGAEvent'
                });
            });
        }
    }

    /**
     * Override - Add event listener to object
     * @param {string} type - Event's type
     * @param {Function} listener - Function
     */
    public addListener(type: string, listener: Function): void {
        super.addListener(type, listener);

        if (type == AbstractMain.DOM_READY && this._domready) {
            this.DOMready(); 
        } else if (type == AbstractMain.WIN_LOAD && this._winload) {
            this.WINload();
        }
    }

    /**
     * get context instance
     * @returns {Context}  instance's Context
     */
    public get context(): Context {
        return this._context;
    }

    /**
     * get singleton instance
     * @returns {Main}  instance's Main
     */
    public static getInstance(): AbstractMain {
        return AbstractMain._instance;
    }

}